<script>
// import axios from 'axios'
// import qs from 'qs'

import { ref } from 'vue'

/** Customer 와 Admin은 페이지 구성을 공유함 */
import TempExportForm from "@/components/customer/TempExportForm.vue"

export default {
  name: "Parcel-registration",
  components: {
    TempExportForm,
  },
  data() {
    return {
      navCurrent : 'parcel-registration',
    }
  },
  methods: {
  },
}
</script>

<template>
  <main id="site-container">

    <div class="section section-bg bg-site">
      <div class="container doc-page">
        <div class="row items-center">
          <div class="col">
            <h2 class="page-title text-center text-white">
              픽업요청<br />AUS -> KOR
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container doc-page q-pb-xl">
      <div class="row q-mb-lg">
        <div class="col">
          <h4>고객님의 배송상품의 요청을 등록하세요.</h4>
        </div>
      </div>

      <div class="row q-mb-lg">
        <div class="col">
          <TempExportForm />
        </div>
      </div>
    </div>
  </main>

  <!--
  <ModalParcelForm
    :showModal="showModalNow"
    v-bind:mode="mode"
    v-bind:idx="idx"
    @close-modal="closeModal"
    @add-item="pushParcel"
    @modify-item="modifyParcelInfo">
  </ModalParcelForm>
  -->
</template>