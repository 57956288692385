<script>

export default {
  name: "Modal-freight-form",
  props: {
    showModal : Boolean,
  },
  emits:[
    "close-modal",
    "add-item",
    "modify-item"
  ],
  data() {
    return {
      method      : "",
      freightNo   : "",
      nation      : "",
      note        : "",
      departure   : "",
      arrival     : "",
      status      : '0'
    }
  },
  methods : {
    addItem(){
      const newData = {
        method      : this.method,
        freightNo   : this.freightNo,
        nation      : this.nation,
        note        : this.note,
        departure   : this.departure,
        arrival     : this.arrival,
        status      : this.status,
      }
      this.$emit("add-item", newData)
      this.$emit("close-modal")
    },
  },
  mounted() {
    this.$log.info("[ Modal / 운송편추가 ]")
    this.$getFreightList()
    this.$getCityList()
    this.$getCodeList("NATION")
  },
}
</script>

<template>
  <teleport to="body">

    <q-dialog 
      v-model="showModal">

      <q-layout 
        container
        class="bg-white">
        
        <q-header class="bg-primary">
          <q-toolbar>
            <q-toolbar-title>운송편 추가</q-toolbar-title>
            <q-btn flat v-close-popup round icon="close" />
          </q-toolbar>
        </q-header>

        <q-footer class="bg-white text-black">
          <q-toolbar>
            <q-btn
              label="추가"
              class="full-width"
              color="primary"
              @click="addItem">
            </q-btn>
          </q-toolbar>
        </q-footer>

        <q-page-container>
          <q-page
            padding>
            <div class="row q-gutter-md q-mb-sm">
              <div class="col">
                <q-select
                  v-model="method"
                  label="운송수단"
                  :options="this.$store.getters.getFreightMethodList"
                  behavior="menu"
                  emit-value
                  map-options />
              </div>
              <div class="col">
                <q-input 
                  v-model="freightNo"
                  :maxlength="6"
                  label="운송편번호" />    
              </div>
              <div class="col">
                <q-select 
                  v-model="nation" 
                  :options="this.$store.getters.getNationList"
                  behavior="menu"
                  label="국가"
                  emit-value
                  map-options />
              </div>
            </div>
            <div class="row q-gutter-md q-mb-sm">
              <div class="col">
                <q-select 
                  v-model="departure" 
                  :options="this.$store.getters.getCityListNew"
                  behavior="menu"
                  label="출발지"
                  emit-value
                  map-options />
              </div>
              <div class="col">
                <q-select 
                  v-model="arrival" 
                  :options="this.$store.getters.getCityListNew"
                  behavior="menu"
                  label="도착지"
                  emit-value
                  map-options />
              </div>
            </div>
            <div class="row q-gutter-md q-mb-sm">
              <div class="col">
                <q-input 
                  v-model="note"
                  type="textarea"
                  :maxlength="1000"
                  label="설명" />    
              </div>
            </div>
            <div class="row q-gutter-md q-mb-sm">
              <div class="col">
                <q-toggle
                  v-model="status"
                  label="상태"
                  :true-value="'0'"
                  :false-value="'1'"
                  checked-icon="check"
                  color="green"
                  unchecked-icon="clear"/>
              </div>
            </div>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>

  </teleport>
</template>