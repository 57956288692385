// import Vue from 'vue'
import { createApp } from 'vue'

import App from './App.vue'
import logger from '@/plugin/logger.ts'

import common from '@/assets/js/common'

import store from '@/store/index'

import router from '@/router'

// GA
import VueGtag from 'vue-gtag'

import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

import Qcalendar from '@quasar/quasar-ui-qcalendar'
import '@quasar/quasar-ui-qcalendar/dist/index.css'


createApp(App)
.use(logger)
.use(common)
.use(router)
.use(store)
.use(VueGtag, {
  appName: 'Aone logistics',
  pageTrackerScreenviewEnabled: true,
  config: { 
    id: process.env.VUE_APP_GTAG
  }
}, router)
.use(Quasar, quasarUserOptions)
.use(Qcalendar)
.mount('#app')