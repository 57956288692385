<script>
// import axios from 'axios'
// import qs from 'qs'
import Title from "@/components/admin/common/TitleArea.vue"
import CourierList from "@/components/admin/CourierList.vue"

import CourierRegForm from "@/components/admin/modal/CourierRegiFormUnit.vue"

export default {
  name: "Admin-courier-management",
  meta: {
    title: "어드민-택배사관리"
  },
  components: {
    Title,
    CourierList,
    CourierRegForm,
  },
  data() {
    return {
      description   : {
        title : '택배사관리'
      },
      showModalNow : false,
      mode : 0,
    }
  },
  /*
  setup(){
  },
  data() {
    return {
    }
  },
  */

  methods: {
    /**
     * 택배사 추가 모달 열기
     */
    addCourierForm(){
      this.showModalNow = !this.showModalNow
      // Modal mode for add parcel
      this.mode = 0
      this.courierCode = null
    },
    /**
     * 택배사 등록 모달 닫기
     */
    closeModal(){
      this.showModalNow = false
    },

    /**
     * 택배사 등록하기
     * @param {*} payload 
     */
    async regiCourier(payload){
      let config = {
        method : "post",
        url : "/api/courier/addCourier",
        data : payload
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug("/admin/addCourier 실행됨", res)
          this.loadCourierList()
        }).catch(res => {
          this.$log.debug("/admin/addCourier 실패", res)
        })
    },
  },
  
  mounted() {
    this.$log.info("[ Page / 택배사관리 ]")
  },
}
</script>


<template>
  
  <q-page class="q-pa-lg">
    <div class="row">
      <div class="col">
        <Title
          :desc="description">

          <q-btn 
            label="택배사 추가"
            class="btn"
            size="md"
            color="primary"
            @click="addCourierForm" />

        </Title>  
      </div>
    </div>

    <div class="row q-mt-lg">
      <div class="col">
        <div class="comp-box">
          <CourierList />
        </div>
      </div>
    </div>
  </q-page>
  
  <CourierRegForm
    :showModal="showModalNow"
    v-bind:mode="mode"
    v-bind:courierCode="courierCode"
    @close-modal="closeModal"
    @add-item="regiCourier"
  />

</template>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
