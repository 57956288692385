/**
 * store / code.js
 * 
 * vue js code 목록들
 * 
 */

import log from '@/plugin/logger.ts'

const state = {
  nationCodeList  : [],
  cityCodeList    : [],
  regionCodeList  : [],
}

const getters = {
  getNationCodeList : (state) => {
    let nationList = state.nationCodeList.map(item => {
      return { label : item.codeLabel, value : item.value }
    })
    
    return nationList
  },

  getCityCodeList : (state) => {
    let cityList = state.cityCodeList.map(item => {
      return { label : item.codeLabel, value : item.value }
    })
    
    return cityList
  },

  getRegionCodeList : (state) => {
    let regionList = state.regionCodeList.map(item => {
      return { label : item.codeLabel, value : item.value }
    })
    
    return regionList
  }
}

const mutations = {
  mutateNationList(state, value){
    // log.debug("loadCourierList excuted = > ", value)
    state.nationCodeList = value
  },
  mutateCityList(state, value){
    // log.debug("loadCourierList excuted = > ", value)
    state.cityCodeList = value
  },
  mutateRegionList(state, value){
    // log.debug("loadCourierList excuted = > ", value)
    state.regionCodeList = value
  },
}

const actions = {
  // 국가코드 입력
  actNationList({commit}, payload){
    // log.debug("Spinner > actions > SHOW_SPINNER  => ")
    commit('mutateNationList', payload)
  },

  actCityList({commit}, payload){
    // log.debug("Spinner > actions > SHOW_SPINNER  => ")
    commit('mutateCityList', payload)
  },

  actRegionList({commit}, payload){
    // log.debug("Spinner > actions > SHOW_SPINNER  => ")
    commit('mutateRegionList', payload)
  },
}


// state, getters, mutations, actions, modules
export default {

  state: {
    ...state
  },
  getters,
  mutations,
  actions

}
