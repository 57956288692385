<script>
/**
 * Type : Component
 * 임시묶음 목록
 * 신규묶음배송의 대상으로 추가된 목록으로 미저장 상태이며 저장시 확정된다.
 * 
 */

export default {
  name: "Parcel-regi-form",
  components: {

  },
  computed:{
    parcels(){
      return this.$store.getters.getTempBulkList
    }
  },
  data() {
    return {

    }
  },
  
  emits: [
    "delete-from-list",
  ],
  
  methods:{
    // 등록할 parcel 목록에서 지우기
    removeParcel(id){
      this.$log.debug('[ removal ]' + id)
      this.$store.dispatch('removeFromBulkList', id)
    },
  },
  /*
  mounted(){
    this.parcels = this.$store.state.newParcelList
  }
  */
}
</script>

<template>
  
  <div 
    class="col-6 q-px-sm q-mb-sm" 
    v-for="(parcel, i) in parcels" :key="i">
    <q-card>
      <q-card-section>
        <div class="text-h6">상품 ID : {{ parcel.id }}</div>
        <div class="text-subtitle2">운송장번호 : {{ parcel.courierNum }}</div>
        <div class="text-subtitle2">배송사 : {{ parcel.courierName }}</div>
      </q-card-section>
      <q-card-section>
        {{ parcel.memo }}
      </q-card-section>
      <q-separator />
      <q-card-actions>
        <q-btn
          flat
          label="삭제"
          @click="removeParcel(parcel.id)">
        </q-btn>
      </q-card-actions>
    </q-card>
  </div>

</template>

<style scoped lang="scss">
.text-h6 {
  word-break: break-all;
  word-wrap: normal;
}
</style>