<script>

import ParcelInfoForm from "@/components/common/modal/ParcelInfoForm.vue"
import { ref } from 'vue'

export default {
  name: "Parcel-list",
  components: {
    ParcelInfoForm,
  },
  watch: {
    "$store.getters.getParcelList"(val) {
      this.parcelList = val
    },
  },
  data() {
    return {
      columns : [
        { name: 'courierName', label: '택배사', align: 'center', field: 'courierName', sortable: true },
        { name: 'courierNum', label: '송장번호', align: 'center', field: 'courierNum' },
        { name: 'pickUpLocationName', label: '픽업지', align: 'center', field: 'pickUpLocationName', sortable: true },
        { name: 'parcelLocationName', label: '위치', align: 'center', field: 'parcelLocationName', sortable: true },
        { name: 'regDate', label: '등록일', align: 'center', field: 'regDate' },
        { name: 'detail', label: '상세', align: 'center', field: 'detail' },
      ],
      modifyModalShow : false,
      selectedParcel  : ref([]),
      userType        : 'cus',
      pagination      : ref({
        rowsPerPage: 10
      }),
      parcelList      : this.$store.getters.getParcelList,
    }
  },
  methods:{
    // 배송상품 상세보기 by Admin
    modalParcelDetail(id) {
      // this.$log.debug("[ Axios / 배송상품 상세버튼 클릭 ]", id)
      this.modifyModalShow  = !this.modifyModalShow 
      let t = this.parcelList.filter(item => {
        // this.$log.debug("[ Axios / 배송상품 상세버튼 클릭 ] item.id = ", item.id)
        if(item.id == id){ return item }
      })
      this.selectedParcel = t
    },
    // 모달 닫기
    closeModal(){
      this.modifyModalShow = false
    },
  }
}
</script>

<template>

  <q-table
    class="my-sticky-virtscroll-table"
    virtual-scroll
    v-model:pagination="pagination"
    :rows-per-page-options="[0]"
    :virtual-scroll-sticky-size-start="48"
    row-key="index"
    :rows="parcelList"
    :columns="columns">
    
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="courierName" :props="props">
          {{ props.row.courierName }}
        </q-td>
        <q-td key="courierNum" :props="props">
          {{ props.row.courierNum }}
        </q-td>
        <q-td key="pickUpLocationName" :props="props">
          {{ props.row.pickUpLocationName }}
        </q-td>
        <q-td key="parcelLocationName" :props="props">
          {{ (props.row.parcelLocationName !== 'TBU')? props.row.parcelLocationName : '-'}}
        </q-td>
        <q-td key="regDate" :props="props">
          {{ props.row.regDate }}
        </q-td>
        <q-td key="detail" :props="props">
          <q-btn
            color="secondary"
            size="sm"
            label="상세"
            @click="modalParcelDetail(props.row.id)">
            </q-btn>
        </q-td>
      </q-tr>
    </template>
  
  </q-table>
  
  <ParcelInfoForm
    v-if="selectedParcel.length > 0"
    :showModal="modifyModalShow"
    :selected-parcel="selectedParcel"
    :user="userType"
    @close-modal="closeModal"
  />
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
