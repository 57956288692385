<script>
/**
 * Type : Business componenet
 * 배송 상품 엑셀 업로드
 * 배송 상품을 엑셀 양식으로 다 건 업로드 할 경우 생성한다.
 * 
 */

import {ref} from 'vue'
// import axios from 'axios'
// import qs from 'qs'


// TODO : admin 에서도 customer modal 로 대체됨
import ParcelDestination from "@/components/common/DestinationRegiForm.vue"
import ParcelUnit from "@/components/common/ParcelRegiForm.vue"
import ModalParcelForm from "@/components/common/modal/ParcelRegiFormUnit.vue"


export default {
  name: "Parcel-registration",
  components: {
    ParcelDestination,
    ParcelUnit,
    ModalParcelForm,
  },
  watch:{
    isBulk:{
      handler(newVal) {
        this.isBulk = newVal
      }
    }
  },
  data() {
    return {
      showModalNow : false,
      url : process.env.VUE_APP_API_URL,
      mode : 0,
      idx : 0,
      isBulk : false,
      destiInfo : {
        city          : "SYD",
        freightMethod : "1",
        receiver      : "",
        mobileCountry : "+61",
        mobileNum     : "",
        email         : "",
        pickupLocation: "",
      },
      file: ref(null)
    }
  },
  methods: {
    /**
     * Title        : 현재 모달 닫기
     * Status       : Actived
     * Description  : 현재 컴포넌트 닫기
     */
    closeModal(){
      this.showModalNow = false
    },
    
    /**
     * Title        : 임시 새 화물 목록에 추가
     * Status       : Actived
     * Description  : 등록요청된 화물을 새 화물 임시 목록에 넣는다
     */
    pushParcel(res){
      this.$store.dispatch('fetchNewParcel', res)
    },

    /**
     * Title        : 새 화물 모달 호출
     * Status       : Actived
     * Description  : 새 상품 모달을 호출한다.
     */
    addParcelForm(){
      this.showModalNow = !this.showModalNow
      // Modal mode for add parcel
      this.mode = 0
      this.idx = null

      let count = this.$store.getters.getNewList.length
      
      if((count > 0 && count < 2) && !this.isBulk){
        const msg = "2개 이상의 상품은 묶음배송이 가능합니다.\n묶음배송을 원하신다면 묶음 배송를 켜 주세요."
        alert(msg)
      }
    },
    modalCallModifyParcelInfo(idx){
      this.$log.debug("Received parcel id = " + idx)

      this.showModalNow = !this.showModalNow
      this.mode = 1
      this.idx = idx
    },
    removeParcelForm(res){
      this.$store.dispatch('removeNewParcel', res)
    },
    modifyParcelInfo(res){
      this.$log.debug("수정되어야 할 내용", res)

      this.$store.dispatch('modifyParcelInfo', res)
    },
    setDestiInfo(res){
      let data = res
      this.$log.debug(res)
      this.destiInfo = data
    },
    // Parcel registration
    async regParcel(){
      let parcelInfo = this.$store.getters.getNewList

      // 배송 갯수 체크
      let qty = parcelInfo.length

      const isBulkYN = ((this.isBulk && qty > 1 ) ? "Y" : "N" )

      let bulkSeq = "N"

      this.$log.debug("묶음배송 여부 = " + this.isBulk + " / 벌크등록해야할 갯수 = " + qty)

      // 묶음배송이 신청되었다면 묶음배송 번호 얻기
      if(isBulkYN === "Y"){
        let configBulk = {
          method : "get",
          url : "/api/parcel/getBlukSeq",
          name : "[ Axios / 묶음배송번호 취득]"
        }

        bulkSeq = await this.$http(configBulk)
          .then(res => {
            let tempRtn = res.data[0].seq
            this.$log.debug("/parcel/getBlukSeq 실행됨", tempRtn)
            return res.data[0].seq
          }).catch(res => {
            this.$log.debug("/parcel/getBlukSeq 실패", res)
          })
        this.$log.debug("bulkSeq = " + bulkSeq)
      }

      // 수량만큼의 배송상품을 등록시킨다.
      for(let i = 0;i < qty; i++){
        let payload = {
          // TODO : 고객번호도 수정할 수 있도록 해야함.
          "customerNum"         : 999999999,                  // 고객번호
          "bulkShipping"        : isBulkYN,                  // 묶음배송여부
          "bulkSeq"             : bulkSeq,                        // 묶음배송번호

          // 배송상품정보
          "courierCode"         : parcelInfo[i].courierCode,      // 배송업체코드
          "courierNum"          : parcelInfo[i].number,        // 송장번호
          "memo"                : parcelInfo[i].note,                // 메모

          // 도착지 정보
          "destiCity"           : this.destiInfo.city,                    // 도착지 도시
          "pickUpLocation"      : this.destiInfo.pickupLocation,          // 픽업지 위치
          "freightMethod"       : this.destiInfo.freightMethod,           // 운송방법
          "mobile"              : this.destiInfo.mobileCountry + this.destiInfo.mobileNum,                  // 전화번호
          "email"               : this.destiInfo.email,                   // Email
          "receiver"            : this.destiInfo.receiver,                // receiver
          "deliveryAddress"     : this.destiInfo.deliveryAddress,                  // 받는사람

          // 화물의 상태 및 스펙
          "parcelStatus"        : "TBU",                // 배송상태
          "parcelLocation"      : "KOR00001",           // 배송현재 위치
          "parcelCode"          : "TBU",                // 상품종류코드
          "weight"              : 0,
          "length"              : 0,
          "height"              : 0,
          "width"               : 0,
        }

        let config = {
          method : "post",
          url : "/api/parcel/parcelReg",
          data : payload
        }

        this.$http(config)
          .then(res => {
            this.$log.debug(i + " 번째 /parcel/parcelReg 실행됨", res)
          }).catch(res => {
            this.$log.debug("/parcel/parcelReg 실패", res)
          })
      }

      alert(qty + "개의 상품등록이 완료되었습니다.")
    }
  },
}
</script>

<template>
  <q-page class="q-pa-lg">

    <div class="row q-mb-lg">
      <div class="col">
        <div class="comp-box">
          <h5 class="q-my-sm">
            배송상품 엑셀 업로드
          </h5>
        </div>
      </div>
    </div>

    <div class="row q-mb-lg">
      <div class="col">
        <div class="comp-box">
          <p class="q-my-sm">
            1. 아래 영역에 엑셀파일을 Drag & Drop을 합니다.<br>
            2. 구름 아이콘을 클릭하면 업로드를 시작합니다.<br>
          </p>
        </div>
      </div>
    </div>

    <div class="row q-mb-md">
      <div class="col">
        <q-uploader
          :url='url + "/api/parcel/importExcel"'
          fieldName="importExcel"
          label="엑셀파일을 업로드해 주세요."
          square
          flat
          bordered
          style="width: 100%; max-width: 600px;"
        />
      </div>
    </div>

    <!--

    <div class="row">
      <div class="col">        
        <div class="comp-box q-mb-sm">
          <div class="row q-mb-sm">
            <div class="col">
              <q-toggle
                v-model="isBulk"
                color="green"
                :label="( isBulk ? '묶음배송 켜짐' : '묶음배송 꺼짐' )" />
            </div>
          </div>

          <div id="newParcelForm" class="row items-start q-gutter-md">  

            <ParcelUnit
              @delete-from-list="removeParcelForm"
              @modal-modify="modalCallModifyParcelInfo"
              />

            <div class="col-sm-6 col-md-4 col-lg-3 q-pt-md">
              <q-card
                @click="addParcelForm">
                <q-card-section class="text-center">
                  <div class="mt-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                  </div>
                  <div class="text-subtitle2">상품목록 추가</div>
                </q-card-section>
              </q-card>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="row q-mb-sm">
      <div class="col-12">

        <div class="comp-box">
          <ParcelDestination
            @destination-info="setDestiInfo"
          />

          <q-btn
            label="Parcel 등록"
            color="primary"
            size="lg"
            @click="regParcel"></q-btn>
        </div>

      </div>
    </div>
    -->
    
  </q-page>

  <ModalParcelForm
    :showModal="showModalNow"
    v-bind:mode="mode"
    v-bind:idx="idx"
    @close-modal="closeModal"
    @add-item="pushParcel"
    @modify-item="modifyParcelInfo"
  />

</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
