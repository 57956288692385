<script>
/**
 * Type         : Page component
 * Title        : 화물등록 컴포넌트 (한국->호주)
 * Description  : 신규화물등록을 위한 페이지
 * 
 */

import { ref } from 'vue'

/** Customer 와 Admin은 페이지 구성을 공유함 */
import ParcelDestination from "@/components/common/DestinationRegiForm.vue"
import ParcelUnit from "@/components/common/ParcelRegiForm.vue"
import ModalParcelForm from "@/components/common/modal/ParcelRegiFormUnit.vue"
// import TempExportForm from "@/components/customer/TempExportForm.vue"

export default {
  name: "Page-parcel-registration-KOR-to-AUS",
  components: {
    ParcelDestination,
    ParcelUnit,
    ModalParcelForm,
  },
  watch:{
    // 묶음배송
    isBulk:{
      handler(newVal) {
        let qty = this.$store.getters.getNewList.length

        if(qty < 2 && newVal){

          alert("2개 이상의 상품부터 묶음배송이 가능합니다.")
          this.isBulk = false
          return false

        } else {

          this.isBulk = newVal

        }
      }
    }
  },
  data() {
    return {
      showModalNow  : false,
      mode          : 0,
      idx           : 0,
      isBulk        : false,
      tab           : ref('korToAus'),
      navCurrent    : 'parcel-registration',
      destiInfo : {
        city            : "SYD",
        freightMethod   : "1",
        receiver        : "",
        mobileCountry   : "+61",
        mobileNum       : "",
        email           : "",
        pickupLocation  : "",
      }
    }
  },
  methods: {
    /**
     * Title        : 모달닫기
     * Status       : Actived
     * Description  : 화물 검색을 위해 분류되어 있는 카테고리 탭
     */
    closeModal(){
      this.showModalNow = false
    },

    // Add to store/list new parcel information
    /**
     * Title        : 임시목록추가
     * Status       : Actived
     * Description  : 화물 등록 임시목록에 추가(store)
     */
    pushParcel(res){
      this.$store.dispatch('fetchNewParcel', res)
    },

    /**
     * Title        : 신규 화물 모달 추가
     * Status       : Actived
     * Description  : 신규 화물를 임시목록에 추가하기 위한 정보입력 모달 호출
     */
    addParcelForm(){
      this.showModalNow = !this.showModalNow
      // Modal mode for add parcel
      this.mode = 0
      this.idx = null

      let count = this.$store.getters.getNewList.length

      if(count !== undefined && (count > 0 && count < 2)){
        const msg = "2개 이상의 상품은 묶음배송이 가능합니다.\n묶음배송을 원하신다면 묶음 배송를 켜 주세요."
        alert(msg)
      }
    },

    /**
     * Title        : 화물 정보 수정 모달
     * Status       : Actived
     * Description  : 임시목록에 추가된 개별 화물의 정보를 수정하기 위한 정보입력 모달 호출
     * @param {*} idx : 임시목록에서 개별화물을 추정할 수 있는 index 번호
     */
    modalCallModifyParcelInfo(idx){
      this.$log.debug("[ Parcel Registration / modalCallModifyParcelInfo ] 수정을 위한 모달 Index = " + idx)

      this.showModalNow = !this.showModalNow
      this.mode = 1
      this.idx = idx
    },

    /**
     * 등록할 배송상품 목록에서 선택된 상품을 삭제한다.
     * @param {*} res 
     */
    removeParcelForm(res){
      let count = this.$store.getters.getNewList.length
      // this.$log.debug("현재 작성된 배송 상품 목록 수 = " + count)
      if(count === 2){
        this.isBulk = false
        // this.$refs.isBulk.checked = false
      }

      this.$store.dispatch('removeNewParcel', res)
    },

    /**
     * Title        : 화물 정보 수정 실행
     * Status       : Actived
     * Description  : 임시목록 화물 정보 수정 실행
     * @param {*} idx : 임시목록에서 개별화물을 추정할 수 있는 index 번호
     */
    modifyParcelInfo(res){
      this.$store.dispatch('modifyParcelInfo', res)
    },

    /**
     * Title        : 도착지 정보
     * Status       : Actived
     * Description  : 자식 컴포넌트로 부터 받아온 도착지 정보 셋팅
     * @param {*} res : 자식 컴포넌트로 부터 받아온 도착지 정보
     */
    setDestiInfo(res){
      let data = res
      this.$log.debug(res)
      
      this.destiInfo = data
    },


    /**
     * Title        : 도착지정보 init
     * Status       : Actived
     * Description  : 도착지 정보를 initialize
     */
     initDestInfo(){
      this.destiInfo = {
        city            : "SYD",
        freightMethod   : "1",
        receiver        : "",
        mobileCountry   : "+61",
        mobileNum       : "",
        email           : "",
        pickupLocation  : "",
      }
    },
    

    // Parcel 등록실행
    /**
     * 프로세스 설명
     * 1. 등록할 상품목록 검증
     * 2. 도착지정보 검증
     * 3. 회원/비회원 체크
     * 4. 벌크배송 확인 및 시퀀스 프로세스
     * 
     */
    async regParcel(){
      // 1. 등록할 상품목록 검증
      // 상품목록에 올라있는 배송상품
      let parcelInfo = this.$store.getters.getNewList
      // 배송 갯수 체크
      let qty = parcelInfo.length
      // 등록 실패한 배송 갯수
      let failQty = 0

      if(qty === 0){
        // 등록된 상품의 갯수가 없음
        alert("상품을 등록해주세요.")
      }

      // 2. 도착지정보 검증
      const result = this.$checkEmptyVModelList(this.destiInfo)
      
      if(result.length > 0){
        alert("모든 항목을 입력해주세요.")
        return false
      }

      // 3. 회원/비회원 체크
      const customer = this.$store.getters.getLocalSession
      let customerId
      if(customer.id === undefined){
        // 로그인을 하지 않은 고객의 비회원 코드
        customerId = "999999999"
      } else {
        customerId = customer.id
      }
      // this.$log.debug(customerId)


      // 4. 벌크배송 확인 및 시퀀스 프로세스
      // 벌크여부
      const isBulkYN = (this.isBulk) ? "Y" : "N"
      // 벌크 시퀀스 변수 선언
      let bulkSeq
      this.$log.debug("묶음배송 여부 = " + this.isBulk + " / 벌크등록해야할 갯수 = " + qty)
      // 묶음배송이 신청되었다면 묶음배송 번호 얻기
      if(isBulkYN === "Y"){
        let configBulk = {
          method : "get",
          url : "/api/parcel/getBlukSeq",
          name : "[ Axios / ParcelRegistration / 묶음배송번호 취득 ]"
        }

        bulkSeq = await this.$http(configBulk)
          .then(res => {
            let tempRtn = res.data[0].seq
            this.$log.debug("[ Axios / ParcelRegistration / 묶음배송번호 취득 ] 실행됨", tempRtn)
            return res.data[0].seq
          }).catch(res => {
            this.$log.debug("[ Axios / ParcelRegistration / 묶음배송번호 취득 ] 실패", res)
          })
        this.$log.debug("bulkSeq = " + bulkSeq)
      }

      // 수량만큼의 배송상품을 등록시킨다.
      for(let i = 0;i < qty; i++){
        let payload = {
          // 고객 번호
          "customerNum"         : customerId,                   // 고객번호

          // 묶음배송정보
          "bulkShipping"        : isBulkYN,                     // 묶음배송여부
          "bulkSeq"             : bulkSeq,                      // 묶음배송번호

          // 배송상품정보
          "courierCode"         : parcelInfo[i].courierCode,    // 배송업체코드
          "courierNum"          : parcelInfo[i].number,         // 송장번호
          "memo"                : parcelInfo[i].note,           // 메모
          "eatable"             : parcelInfo[i].eatable,        // 음식물 여부

          // 도착지 정보
          "destiCity"           : this.destiInfo.city,          // 도착지 도시
          "pickUpLocation"      : this.destiInfo.pickupLocation,    // 도착지 위치
          "freightMethod"       : this.destiInfo.freightMethod,     // 운송방법
          "mobile"              : this.destiInfo.mobileCountry + this.destiInfo.mobileNum,     // 전화번호
          "email"               : this.destiInfo.email,                     // Email
          "receiver"            : this.destiInfo.receiver,                  // 받는사람
          "deliveryAddress"     : this.destiInfo.deliveryAddress,           // 받는사람

          // 화물의 상태 및 스펙
          "parcelStatus"        : "TBU",              // 배송상태
          "parcelLocation"      : "KOR00001",         // 배송현재 위치
          "parcelCode"          : "TBU",              // 상품종류코드
          "weight"              : 0,
          "length"              : 0,
          "height"              : 0,
          "width"               : 0,
        }

        let config = {
          method  : "post",
          url     : "/api/parcel/reg",
          data    : payload,
          name    : "[ Axios / ParcelRegistration / " + i + "번 배송상품등록 ]"
        }

        // 실제 등록 실행
        this.$http(config)
          .then(res => {
            this.$log.debug(i + " 번째 등록 실행됨", res)
            if(res.status !== 200){
              failQty++
            }

            if((qty - 1) === i){
              if(failQty === 0){
                this.$store.dispatch('initNewParcel')
                this.isBulk = false
                alert("상품등록이 완료되었습니다")
              } else {
                alert(failQty + "개의 상품 등록에 실패했습니다.")
              }
            }

          }).catch(res => {
            this.$log.debug("/parcel/reg 실패", res)
          })
      }

      // this.initDestInfo()
    }
  },
}
</script>

<template>
  <main id="site-container">

    <div class="section section-bg bg-site">
      <div class="container doc-page">
        <div class="row items-center">
          <div class="col">
            <h2 class="page-title text-center text-white">
              배송신청<br />KOR -> AUS
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container doc-page q-pb-xl">
      <div class="row q-mb-lg">
        <div class="col">
          <h4>고객님의 배송상품을 등록하세요.</h4>
        </div>
      </div>

      <div id="newParcelForm">
        <div class="row items-start q-mb-sm">
          <div class="col">
            <q-toggle
              v-model="isBulk"
              color="green"
              :label="( isBulk ? '묶음배송 켜짐' : '묶음배송 꺼짐' )"
              />
          </div>
        </div>

        <div class="row" style="gap: 20px;">
          <ParcelUnit
            @delete-from-list="removeParcelForm"
            @modal-modify="modalCallModifyParcelInfo"
            />
  
          <!-- 상품목록 추가 버튼 -->
          <div class="parcel-unit">
            <q-card
              class="parce-unit-inner"
              @click="addParcelForm">
              <q-card-section class="text-center">
                <div class="mt-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg>
                </div>
                <div class="text-subtitle2">상품목록 추가</div>
              </q-card-section>
            </q-card>
          </div>
        </div>

      </div>

      <hr />

      <div class="column items-center q-mt-xl">
        <div class="col">

          <ParcelDestination
            class="destination-box"
            @destination-info="setDestiInfo" />

          <q-btn
            label="배송신청하기"
            color="primary"
            size="xl"
            class="full-width"
            @click="regParcel" />

        </div>
      </div>
      
    </div>
  </main>

  <ModalParcelForm
    :showModal="showModalNow"
    v-bind:mode="mode"
    v-bind:idx="idx"
    @close-modal="closeModal"
    @add-item="pushParcel"
    @modify-item="modifyParcelInfo">
  </ModalParcelForm>

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.parcel-unit {
  min-width : 300px;
  max-width : 400px;

  .parce-unit-inner {
    display: flex;
    align-items: center;
    justify-content: center;

    height  : 100%;
  }
}

</style>