<script>
/**
 * Type : Layout Component
 * Title Area
 * 페이지 제목영역
 */

export default {
  name: "Bulk-parcel-bulider-form",
  props: {
    desc : {
      title : {
        type : String,
        default : '관리'
      }
    }
  },
  components:{
  },
  watch:{
  },
  emits:[
  ],
  data() {
    return {
      
    }
  },
  methods : {
    
  },
  mounted(){

  }
}
</script>


<template>

<div class="comp-box">
  <div class="row">
    <div class="col">
      <h5 class="q-my-sm">
        {{ desc.title }}
      </h5>
    </div>
    <div 
      style="text-align: right;" 
      class="col self-center">

      <slot></slot>

    </div>
  </div>
</div>  

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>