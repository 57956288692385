import log from '@/plugin/logger.ts'

const state = {
  spinner : false,
  spinnerStack : 0
}

const getters = {
  spinnerState : (state) => {
    // log.debug("Spinner > getters > spinnerState  => ")
    return state.spinner
  } 
}

const actions = {
  showSpinner({commit}){
    // log.debug("Spinner > actions > SHOW_SPINNER  => ")
    commit('showSpinner')
  },
  killSpinner({commit}){
    // log.debug("Spinner > actions > KILL_SPINNER  => ")
    commit('killSpinner')
  },
}

const mutations = {
  
  showSpinner(state){
    // log.debug("Spinner > mutations > UTILITIES.SHOW_SPINNER  => ")
    state.spinnerStack++
    state.spinner = true
  },
  killSpinner(state){
    // log.debug("Spinner > mutations > UTILITIES.KILL_SPINNER  => ")
    state.spinnerStack--
    if(state.spinnerStack === 0)
      { state.spinner = false }
  },

}

// state, getters, mutations, actions, modules
export default {

  state: {
    ...state
  },
  getters,
  mutations,
  actions

}
