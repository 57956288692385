<template>

  <div class="row q-mb-lg">
    <div class="col">
     
      <q-tabs
        v-model="tab"
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        narrow-indicator
        align="left">
        <q-tab
          name="product-info" 
          @click="tabToggle(1)"
          label="배송상품정보로 검색" />
        <q-tab 
          name="person-info" 
          @click="tabToggle(2)"
          label="받는사람정보로 검색" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="product-info">
          <div class="row">
            <div class="col-4">
              <q-select
                v-model="this.lookUpData.courierCode"
                label="배송업체"
                :options="this.$store.getters.getCourierList"
                emit-value
                map-options
                behavior="menu"
              />
            </div>
            <div class="col-8 q-pl-sm">
              <q-input 
                v-model="this.lookUpData.courierNum" 
                label="송장번호"
                placeholder="배송물품의 송장번호를 적어주세요."/>    
            </div>
          </div>
        </q-tab-panel>

        <q-tab-panel name="person-info">
          <div class="row">
            <div class="col-3">
              <q-select
                v-model="this.lookUpData.mobileProvider"
                label="국가번호"
                :options="['+61', '+82']"
                behavior="menu"
                />
            </div>
            <div class="col-9 q-pl-sm">
              <q-input 
                v-model="this.lookUpData.mobileNum" 
                label="전화번호"
                placeholder="전화번호를 입력해주세요"/>    
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-input 
                v-model="this.lookUpData.email" 
                label="이메일"
                placeholder="이메일을 입력해주세요"/>   
            </div>
          </div>
        </q-tab-panel>
      </q-tab-panels>

    </div>
  </div>

  <q-btn
    color="primary"
    class="full-width"
    label="배송상품 검색하기"
    @click="lookUpParcel">
  </q-btn>

</template>

<script>
// import axios from 'axios'

export default {
  name: "Parcel-lookup-form-by-parcel",
  emits:["load-parcel-list"],
  data() {
    return {
      // courierList    : this.$store.getters.getCourierList,
      lookUpData     : {
        tabCode        : 1,
        mobileProvider : "+61",
        mobileNum      : "",
        email          : "",
        courierCode    : {
          label : "",
          value : ""
        },
        courierNum     : "",
      },
      tab            : "product-info"
    }
  },
  methods:{
    tabToggle(res){
      this.$log.debug("[ Axios / tabToggle ] res = ", res)
      this.lookUpData.tabCode = res
    },
    lookUpParcel(){
      let ld = this.lookUpData
      this.$log.debug("[ Axios / lookUpParcel ] ld = ", ld)

      if(ld.tabCode == 1){
        if(ld.courierCode.value === "" || ld.courierNum === ""){
          alert("택배사/송장번호를 확인해주세요.")
          return false
        }
      } else if(ld.tabCode == 2){
        if(ld.mobileProvider === "" || ld.mobileNum === "" || ld.email === ""){
          alert("통신사/모바일번호/이메일을 확인해주세요.")
          return false
        }
      }
      this.$emit('load-parcel-list', ld)
    }
  },
  mounted() {
    this.$getCourierList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
