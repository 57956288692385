<script>
/**
 * Type : Layout Component
 * Scheduler
 * 
 */

import { 
  QCalendarMonth,
  parseTimestamp,
  addToDate,
  parseDate,
  isBetweenDates } from '@quasar/quasar-ui-qcalendar'
 
import Modal from "@/components/admin/modal/ScheduleUnit.vue"

// The function below is used to set up our demo data
// const CURRENT_DAY = new Date()

//d getCurrentDay(new Date(item.eventDate).getDate()),

const reRGBA = /^\s*rgb(a)?\s*\((\s*(\d+)\s*,\s*?){2}(\d+)\s*,?\s*([01]?\.?\d*?)?\s*\)\s*$/

function textToRgb (color) {
  if (typeof color !== 'string') {
    throw new TypeError('Expected a string')
  }

  const m = reRGBA.exec(color)
  if (m) {
    const rgb = {
      r: Math.min(255, parseInt(m[2], 10)),
      g: Math.min(255, parseInt(m[3], 10)),
      b: Math.min(255, parseInt(m[4], 10))
    }
    if (m[1]) {
      rgb.a = Math.min(1, parseFloat(m[5]))
    }
    return rgb
  }
  return hexToRgb(color)
}

function hexToRgb (hex) {
  if (typeof hex !== 'string') {
    throw new TypeError('Expected a string')
  }

  hex = hex.replace(/^#/, '')

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  else if (hex.length === 4) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3]
  }

  const num = parseInt(hex, 16)

  return hex.length > 6
    ? { r: num >> 24 & 255, g: num >> 16 & 255, b: num >> 8 & 255, a: Math.round((num & 255) / 2.55) }
    : { r: num >> 16, g: num >> 8 & 255, b: num & 255 }
}

function luminosity (color) {
  if (typeof color !== 'string' && (!color || color.r === undefined)) {
    throw new TypeError('Expected a string or a {r, g, b} object as color')
  }

  const
    rgb = typeof color === 'string' ? textToRgb(color) : color,
    r = rgb.r / 255,
    g = rgb.g / 255,
    b = rgb.b / 255,
    R = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4),
    G = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4),
    B = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4)
  return 0.2126 * R + 0.7152 * G + 0.0722 * B
}

export default {
  name: "Scheduler",
  props: {
    
  },
  components: {
    Modal,
    QCalendarMonth
  },
  watch:{
  },
  emits:[
  ],
  computed: {
    eventsMap () {
      const map = {}
      this.$log.debug("[ Computed / eventsMap ] Start ")

      if (this.events.length > 0) {
        this.events.forEach(event => {
          (map[ event.date ] = (map[ event.date ] || [])).push(event)
          if (event.days !== undefined) {
            let timestamp = parseTimestamp(event.date)
            let days = event.days
            // add a new event for each day
            // skip 1st one which would have been done above
            do {
              timestamp = addToDate(timestamp, { day: 1 })
              if (!map[ timestamp.date ]) {
                map[ timestamp.date ] = []
              }
              map[ timestamp.date ].push(event)
              // already accounted for 1st day
            } while (--days > 1)
          }
        })
      }

      this.$log.debug("[ Computed / eventsMap ]", map)
      return map
    }
  },
  /** v-model data */
  data(){
    return {
      modalVisibility : false,
      modalMode : 'add',
      calDate   : new Date(),
      currYear  : '',
      currMonth : '',
      selectedDate : '',
      selectedSchedule : {},
      events    : [
        /*
        {
          title: 'Girlfriend',
          details: 'Meet GF for dinner at Swanky Restaurant',
          date: getCurrentDay(15),
          time: '19:00',
          duration: 180,
          bgcolor: 'teal',
          icon: 'fas fa-utensils'
        },
        */
      ]
    }
  },
  methods : {

    /**
     * 일정조회
     */
    async loadSchedule(){

      // 1일 ~ 말일 설정
      let firstDay = new Date(this.calDate.getFullYear(), this.calDate.getMonth(), 2)
      let lastDay = new Date(this.calDate.getFullYear(), this.calDate.getMonth() + 1, 1)

      let payload = {
        startDate : firstDay,
        endDate   : lastDay
      }

      let config = {
        method  : "post",
        url     : "/api/schedule/loadSchedule",
        data    : payload,
        name    : "[ Axios / 스케쥴 조회 ]"
      }

      await this.$http(config)
        .then((res) => {
          this.$log.debug("스케쥴 조회했습니다", res.data)

          // 조회된 스케쥴을 달력에 설정한다
          this.setEvents(res.data)
        })
        .catch((err) => {
          this.$log.debug(err)
          // alert('스케쥴 조회에 실패하였습니다.')
      })
    },

    /**
     * 조회된 스케쥴을 달력에 설정
     */
    async setEvents(res){
      this.$log.debug("[ Methods / setEvents ] Start ", res)

      // 조회된 일정
      let temp = res.map(item => {

        let result = {
          id      : item.id,
          title   : item.title,
          details : item.details,
          date    : this.getCurrentDay(new Date(item.eventDate).getDate()),
          eventType : item.eventType,
          // time: '19:00',
          // duration: 180,
          days    : item.days,
          bgcolor : 'teal',
          icon    : (item.eventType == 1 ? 'directions_boat' : 'flight' )
        }
        return result
      })

      this.events = temp
    },

    /** 
     * 스케쥴 추가 모달 호출
     */
     async addSchedule(){
      this.modalMode = 'add'
      this.modalVisibility = true
    },

    /** 
     * 스케쥴 추가 시 
     * 스케쥴 재조회
     */
    async reloadSchedule(){
      this.$log.debug("[ 스케쥴 재조회 ] Start ")
      await this.loadSchedule()
    },

    /**
     * 1개월 단위 기존 달력 변경
     * @param {*} date 
     * @param {*} months 
     */
    addMonths(date, months) {
      date.setMonth(date.getMonth() + months)
      return date
    },

    /**
     * 다음 달
     */
    async calendarNext () {
      this.addMonths(this.calDate, +1)
      this.$refs.calendar.next()
      await this.loadSchedule()
    },

    /**
     * 이전 달
     */
    async calendarPrev () {
      this.addMonths(this.calDate, -1)
      this.$refs.calendar.prev()
      await this.loadSchedule()
    },

    isCssColor (color) {
      return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/)
    },

    badgeClasses (event, type) {
      const cssColor = this.isCssColor(event.bgcolor)
      const isHeader = type === 'header'
      return {
        [`text-white bg-${event.bgcolor}`]: !cssColor,
        'full-width': !isHeader && (!event.side || event.side === 'full'),
        'left-side': !isHeader && event.side === 'left',
        'right-side': !isHeader && event.side === 'right'
      }
    },

    badgeStyles (event, type, timeStartPos, timeDurationHeight) {
      const s = {}
      if (this.isCssColor(event.bgcolor)) {
        s['background-color'] = event.bgcolor
        s.color = luminosity(event.bgcolor) > 0.5 ? 'black' : 'white'
      }
      if (timeStartPos) {
        s.top = timeStartPos(event.time) + 'px'
      }
      if (timeDurationHeight) {
        s.height = timeDurationHeight(event.duration) + 'px'
      }
      s['align-items'] = 'flex-start'
      return s
    },

    /**
     * 조회된 이벤트를 지도에 뿌릴 수 있는 형식으로 만든다
     * @param {*} dt 
     */
    getEvents (dt) {
      // this.$log.debug("[ Methods / getEvents ] Start ")

      const currentDate = parseTimestamp(dt)
      const events = []
      for (let i = 0; i < this.events.length; ++i) {
        let added = false
        if (this.events[i].date === dt) {
          if (this.events[i].time) {
            if (events.length > 0) {
              // check for overlapping times
              const startTime = parseTimestamp(this.events[i].date + ' ' + this.events[i].time)
              const endTime = addToDate(startTime, { minute: this.events[i].duration })
              for (let j = 0; j < events.length; ++j) {
                if (events[j].time) {
                  const startTime2 = parseTimestamp(events[j].date + ' ' + events[j].time)
                  const endTime2 = addToDate(startTime2, { minute: events[j].duration })
                  if (isBetweenDates(startTime, startTime2, endTime2) || isBetweenDates(endTime, startTime2, endTime2)) {
                    events[j].side = 'left'
                    this.events[i].side = 'right'
                    events.push(this.events[i])
                    added = true
                    break
                  }
                }
              }
            }
          }
          if (!added) {
            this.events[i].side = undefined
            events.push(this.events[i])
          }
        }
        else if (this.events[i].days) {
          // check for overlapping dates
          const startDate = parseTimestamp(this.events[i].date)
          const endDate = addToDate(startDate, { day: this.events[i].days })
          if (isBetweenDates(currentDate, startDate, endDate)) {
            events.push(this.events[i])
            added = true
          }
        }
      }
      return events
    },

    /**
     * Title        : 등록된 스케쥴 선택
     * Status       : Actived
     * Description  : 등록된 스케쥴을 선택하여 수정 모달을 띄운다
     */
    selectSchedule(event){
      this.$log.debug("[ 등록된 스케쥴 조회 ] Start ", event)
      
      // 선택한 스케쥴 정보
      this.selectedSchedule = event
      this.modalMode = 'modify'
      this.modalVisibility = true
    },

    /**
     * Title        : 오늘 날짜 조회
     * Status       : Actived
     * Description  : -
     */
    getCurrentDay (day) {
      const newDay = new Date(this.calDate)
      newDay.setDate(day)
      const tm = parseDate(newDay)
      return tm.date
    }
  },
  
  mounted(){
    this.loadSchedule()
  }
}
</script>


<template>
  <div class="row q-mb-md">
    <div class="col">
      <q-btn 
        label="이전 달"
        icon="arrow_back_ios"
        class="btn q-ml-sm"
        size="md"
        color="primary"
        @click="calendarPrev" />

      <q-btn 
        label="다음 달"
        icon="arrow_forward_ios"
        class="btn q-ml-sm"
        size="md"
        color="primary"
        @click="calendarNext" />
    </div>
    <div class="col text-right">
      <q-btn 
        label="일정추가"
        icon="add"
        class="btn"
        size="md"
        color="primary"
        @click="addSchedule" />
    </div>
  </div>

  <div class="row q-mb-md">
    <div class="col text-center">
      <div class="curr-date-display">
        <span class="year">{{ calDate.getFullYear() }}</span>.<span class="month">{{ calDate.getMonth() + 1 }}</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <QCalendarMonth
        ref="calendar"
        v-model="selectedDate"
        view="month"
        locale="en-us"
        :day-height="100">
        
        <template #day="{ scope: { timestamp } }">
          <template v-for="(event, index) in getEvents(timestamp.date)">
            <q-badge
              style="width: 100%; cursor: pointer; height: 18px; max-height: 18px"
              :class="badgeClasses(event, 'day')"
              :style="badgeStyles(event, 'day')"
              @click="selectSchedule(event)"
            >
              <q-icon v-if="event.icon" 
                :name="event.icon" 
                class="q-mr-xs">
              </q-icon>
              <span class="ellipsis">
                {{ event.title }}
              </span>
              <q-tooltip>{{ event.details }}</q-tooltip>
            </q-badge>
          </template>
        </template>
      </QCalendarMonth>
    </div>
  </div>

  <Modal
    :show-modal="modalVisibility"
    :selected="selectedSchedule"
    :mode="modalMode"
    @reload-schedule="reloadSchedule"
    @close-modal="modalVisibility = !modalVisibility">
  </Modal>

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.curr-date-display {
  font-size: 1.75rem;
}
</style>