<script>
import CustomerModal from "@/components/admin/modal/CustomerModal.vue"

export default {
  name: "Customer-list",
  components:{
    CustomerModal,
  },
  data() {
    return {
      customerList: [],
      showModalNow : false,
      mode : 0,
      selectedCustomer : [],
      columns : [
        { name: 'id', label: '고객번호', align: 'center', field: 'id' },
        { name: 'authLevel', label: '고객등급', align: 'center', field: 'authLevel' },
        { name: 'email', label: '이메일', align: 'center', field: 'email' },
        { name: 'name', label: '이름', align: 'center', field: 'name' },
        { name: 'mobile', label: '모바일', align: 'center', field: 'mobile' },
        { name: 'country', label: '국가', align: 'center', field: 'country' },
        { name: 'regDate', label: '등록일', align: 'center', field: 'regDate' },
        { name: 'detail', label: '기능선택', align: 'center', field: 'detail' },
      ],
    }
  },
  methods:{
    // TODO : 고객정보 등록으로 변경 필요
    // Modal show for more parcel
    // addParcelLocationForm(){
    //   this.$log.debug("픽업지 추가 클릭")
    //   this.showModalNow = !this.showModalNow
    //   // Modal mode for add parcel
    //   this.mode = 0
    // },

    // 모달 닫기
    closeModal(){
      this.showModalNow = false
    },

    // 고객정보 수정버튼 클릭
    openModifyCustomer(id){

      this.$log.debug("선택된 고객의 id", id)
      
      let tempList = this.customerList
      let selected = tempList.filter(item => {
        if(item.id === id)
          return item
      })
      this.selectedCustomer = selected[0]
      this.$log.debug("[ Admin / CustomerList / openModifyCustomer ]", this.selectedCustomer)
      // Modal mode for add parcel
      this.mode = 1
      this.showModalNow = !this.showModalNow
    },


    // 고객정보 수정버튼 클릭
    async modifyCustomer(param){

      let config = {
        method    : "post",
        url     : "/api/member/modifyCustomer",
        data    : param,
        name    : "[ Axios / 고객정보수정실행 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug("/admin/modifyCustomer 실행됨", res)
          this.loadCustomerList()
        }).catch(res => {
          this.$log.debug("/admin/modifyCustomer 실패", res)
        })

    },

    // 삭제
    singleDelete (customerId) {
      this.$log.debug("개별삭제 실행")
      this.$log.debug("선택된 고객 Id", customerId)

      let payload = {
        "id" : customerId
      }

      this.$log.debug("payload = ", payload)

      this.delete(payload)
    },

    // 삭제 실행
    async delete (payload) {

      let config = {
        method : "post",
        url : "/api/member/delCustomer",
        data : payload
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("삭제했습니다.")

          this.loadCustomerList()

          alert("삭제했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })
    },

    // 고객목록조회하기
    async loadCustomerList() {

      let result = []

      let payload = {
        paging : 0
      }

      let config = {
        method  : "post",
        url     : "/api/member/getCustomerList",
        data    : payload,
        name    : "[ Axios / 고객목록조회 ]"
      }

      result = await this.$http(config)
        .then(res => {
          let tempRtn = res.data
          return tempRtn
        }).catch(res => {
          this.$log.debug("/member/getCustomerList 실패", res)
        })

      this.customerList = result
    },
  },
  mounted() {
    this.$log.debug("Parcel Location list mounted")
    this.loadCustomerList()
  },
}
</script>

<template>

  <q-table
    virtual-scroll
    :rows-per-page-options="[0]"
    :virtual-scroll-sticky-size-start="48"
    :rows="customerList"
    :columns="columns"
    row-key="id">

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="id" :props="props">
          {{ props.row.id }}
        </q-td>
        <q-td key="authLevel" :props="props">
          {{ props.row.authLevelName }}
        </q-td>
        <q-td key="email" :props="props">
          {{ props.row.email }}
        </q-td>
        <q-td key="name" :props="props">
          {{ props.row.lName + ' ' + props.row.fName }}
        </q-td>
        <q-td key="mobile" :props="props">
          {{ props.row.mobile }}
        </q-td>
        <q-td key="country" :props="props">
          {{ props.row.country }}
        </q-td>
        <q-td key="regDate" :props="props">
          {{ props.row.regDate }}
        </q-td>
        <q-td key="detail" :props="props">
          <q-btn-dropdown 
            color="primary" 
            label="기능선택"
            size="sm">
            <q-list>
              <q-item
                clickable v-close-popup
                @click="openModifyCustomer(props.row.id)">
                <q-item-section>
                  <q-item-label>수정</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                clickable v-close-popup
                @click="singleDelete(props.row.id)">
                <q-item-section>
                  <q-item-label>삭제</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </q-td>
      </q-tr>
    </template>

  </q-table>

  <CustomerModal
    :showModal="showModalNow"
    :mode="mode"
    :selected-customer="selectedCustomer"
    @close-modal="closeModal"
    @modify-customer="modifyCustomer"
  />

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
