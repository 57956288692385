<template>
  <NavBar />
  <main class="d-flex flex-nowrap">

    <div class="container pt-5">
      <div class="row">
        <div class="col">
          <h1>
            운송정보
          </h1>
          <span>이곳은 운송정보를 표기하는 곳입니다.</span>
        </div>
      </div>
      <div class="row">
        <div id="parcel-form" class="col mt-3">

          이곳은 운송정보를 표기하는 곳입니다.

        </div>
      </div>
    </div>
  </main>
</template>

<script>
import NavBar from "@/components/customer/NavBar.vue"


export default {
  name: "Shipping-infomation",
  components: {
    NavBar,
  },
  data() {
    return {

    }
  },
  methods: {

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
