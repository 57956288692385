import log from '@/plugin/logger.ts'

const state = {  
  courierList: [],                  // 택배사 목록
  parcelLocationList: [],           // 배송상품 위치정보 목록
  pickupLocationList: [],           // 픽업장소 목록
  cityList: [],                     // 도시 목록
  lookUpFilter: {
    byParcel       : true,
    byCustomer     : false,
    mobileProvider : "",
    mobileNum      : "",
    email          : "",
    courierCode    : "K001",
    courierNum     : ""
  },
  freightMethod : [],
  countryCode: [                    // 모바일 국가코드
    { codeLabel: '+82', value: '+82' },
    { codeLabel: '+61', value: '+61' },
  ],
  memberLevel : []                  // 멤버등급
}

const getters = {
  /** 
   * 배송상품목록 가져오기
   * 
   */

  getFilterSetting(state) {
    // log.debug("getFilterSetting => ", state.lookUpFilter)
    return state.lookUpFilter
  },

  /** 배송업체 목록관리 */
  getCourierList(state) {
    // log.debug("getCourierList => ", state.courierList)
    let courierList = state.courierList.map(item => {
      return { label : item.courierName, value : item.courierCode }
    })
    
    return courierList
  },
  

  /**
   * 
   * 배송상품 위치
   * 
   */
  getParcelLocationList(state) {
    // log.debug("getCourierList => ", state.courierList)
    return state.parcelLocationList
  },
  getParcelLocationListImport(state) {
    let importList = state.parcelLocationList.filter(item => {
      if(item.class == 'IM_PC_LO'){ return item }
    })
    return importList
  },
  getParcelLocationListExport(state) {
    let exportList = state.parcelLocationList.filter(item => {
      if(item.class == 'EX_RQ_ST'){ return item }
    })
    // log.debug("exportList = ", exportList)
    return exportList
  },
  getParcelLocationListExpulo(state) {
    let exportPickupList = []
    state.parcelLocationList.filter(item => {
      if(item.class == 'EX_PU_LO'){ 
        exportPickupList.push({ label : item.name, value : item.code })
      }
    })
    log.debug("exportPickupList = ", exportPickupList)
    return exportPickupList
  },
  getParcelLocationListNew(state) {
    // log.debug("getCourierList => ", state.courierList)
    let parcelLocationList = state.parcelLocationList.map(item => {
      return { label : item.name, value : item.code }
    })
    return parcelLocationList
  },

  /**
   * 
   * 픽업장소 목록
   * 
   */
  getPickupLocationList(state) {
    let tempList = state.pickupLocationList.map(item => {
      return { label : item.name, value : item.code }
    })
    return tempList
  },
  getPickupLocationListNew(state) {
    let pickupLocationList = state.pickupLocationList.map(item => {
      return { label : item.name, value : item.code }
    })
    return pickupLocationList
  },

  /**
   * 
   * 기타 목록들
   * 
   */
  /** 운송수단 */
  getFreightMethodList(state) {
    let tempList = state.freightMethod.map(item => {
      return { label : item.codeLabel, value : item.value }
    })
    return tempList
  },
  /** 도시 */
  getCityList(state) {
    return state.cityList
  },
  /** 도시 label, value */
  getCityListNew(state) {
    let tempList = state.cityList.map(item => {
      return { label : item.codeLabel, value : item.value }
    })
    return tempList
  },
  /** 모바일 국가 코드 */
  getCountryCodeList(state) {
    let tempList = state.countryCode.map(item => {
      return { label : item.codeLabel, value : item.value }
    })
    return tempList
  },
  /** 멤버권한목록 */
  getMemberAuthLevelList(state) {
    let tempList = []
    state.memberLevel.map(item => {
      if(item.value != "99")
        tempList.push({ label : item.codeLabel, value : item.value })
    })
    return tempList
  }
}

/**
 * 
 * 변형 - mutation
 * 
 */
const mutations = {

  loadCourierList(state, value){
    // log.debug("loadCourierList excuted = > ", value)
    state.courierList = value
  },

  // 검색관련 설정
  setFilter(state, value){
    // log.debug("setFilter executed => ", value)
    state.lookUpFilter = value
  },

  loadParcelLocationList(state, value){
    // log.debug("loadCourierList excuted = > ", value)
    state.parcelLocationList = value
  },

  loadPickupLocationList(state, value){
    // log.debug("loadCourierList excuted = > ", value)
    state.pickupLocationList = value
  },

  loadFreightList(state, value){
    state.freightMethod = value
  },

  loadCityList(state, value){
    state.cityList = value
  },

  loadMemberAuthLevelList(state, value){
    state.memberLevel = value
  }
}


const actions = {
  
  // 택배사 목록 조회
  loadCourier: ({ commit }, payload) => {
    // log.debug(state)
    // log.debug(payload)
    commit('loadCourierList', payload)
  },

  // 검색관련 설정
  setFilter: ({ commit }, payload) => {
    commit('setFilter', payload)
  },

  // 배송상품 위치
  loadParcelLocation: ({ commit }, payload) => {
    // log.debug(state)
    // log.debug(payload)
    commit('loadParcelLocationList', payload)
  },

  // 픽업지 위치
  loadPickupLocation: ({ commit }, payload) => {
    // log.debug(state)
    // log.debug(payload)
    commit('loadPickupLocationList', payload)
  },

  fetchCityList: ({ commit }, payload) => {
    commit('loadCityList', payload)
  },

  fetchFreightList: ({ commit }, payload) => {
    commit('loadFreightList', payload)
  },

  fetchMemberAuthLevelList: ({ commit }, payload) => {
    commit('loadMemberAuthLevelList', payload)
  }
}


// state, getters, mutations, actions, modules
export default {

  state: {
    ...state
  },
  getters,
  mutations,
  actions

}
