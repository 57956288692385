<script>
/**
 * Type         : Page component
 * Title        : 코드 관리 페이지 컴포넌트
 * Description  : 사이트 내 사용되는 코드를 관리한다
 * 
 */

import Title from "@/components/admin/common/TitleArea.vue"
import CodeList from "@/components/admin/CodeList.vue"
import CodeRegForm from "@/components/admin/modal/CodeRegiFormUnit.vue"

export default {
  name: "AdminCodeManagement",
  meta: {
    title: "어드민-코드관리"
  },
  components: {
    Title,
    CodeList,
    CodeRegForm,
  },
  data() {
    return {
      codeList      : [],
      showModalNow  : false,
      description   : {
        title       : '코드관리'
      },
    }
  },
  /*
  setup(){
  }
  */

  methods: {
    /**
     * 코드 추가 모달 열기
     */
    regiCodeForm(){
      this.showModalNow = !this.showModalNow
      // Modal mode for add parcel
      this.mode = 0
      // this.code = null
    },
    /**
     * 코드 등록 모달 닫기
     */
    closeModal(){
      this.showModalNow = false
    },

    /**
     * 
     * Title        : 코드 등록하기
     * Status       : Actived
     * Description  : 솔루션 코드를 등록한다.
     * @param {*} payload 
     */
    async regiCode(payload){

      this.$log.debug("등록할 코드 = ", payload)

      let config = {
        method  : "post",
        url     : "/api/code/regiCode",
        data    : payload,
        name    : "[ Axios / 신규코드추가 ]"
      }

      await this.$http(config)
        .then(res => {
          this.loadCodeList()
          this.showModalNow = false
        })
    },

    /**
     * Title        : 코드 목록 조회
     * Status       : Actived
     * Description  : 솔루션 코드 목록을 조회한다.
     */
    async loadCodeList() {

      let result = []

      let config = {
        method  : "post",
        url     : "/api/code/getCodeList",
        name    : "[ Axios / 코드목록조회 ]"
      }

      result = await this.$http(config)
        .then(res => {
          let tempRtn = res.data
          this.$log.debug("[ Axios / 코드목록조회 ] 실행됨", tempRtn)
          return tempRtn
        }).catch(res => {
          this.$log.debug("[ Axios / 코드목록조회 ] 실패", res)
        })

      this.codeList = result
    },
  },
  
  mounted() {
    this.$log.info("[ Page / 코드관리 ]")
    this.loadCodeList()
  },
}
</script>


<template>
  
  <q-page class="q-pa-lg">
    <div class="row">
      <div class="col">
        <Title
          :desc="description">

          <q-btn 
            label="코드 추가"
            class="btn"
            size="md"
            color="primary"
            @click="regiCodeForm" />

        </Title>  
      </div>
    </div>

    <div class="row q-mt-lg">
      <div class="col">
        <div class="comp-box">
          <CodeList 
            :code-list="codeList"
            @load-list="loadCodeList"/>
        </div>
      </div>
    </div>
  </q-page>
  

  <teleport to="body">

    <q-dialog 
      v-model="showModalNow">

      <CodeRegForm
        @add-item="regiCode"
      />

    </q-dialog>

  </teleport>

</template>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
