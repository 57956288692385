<script>
import NoticeList from "@/components/admin/NoticeList.vue"

export default {
  name: "SignUp",
  meta: {
    title: "공지사항"
  },
  components: {
    NoticeList,
  },
  data() {
    return {
      noticeList :[],
      authLevel : 0,
      navCurrent : 'notice'
    }
  },
  methods: {
    /**
     * 공지사항목록조회
     */
    async loadNoticeList() {
      let payload = {
        "paging" : 0,
        "type"   : "customer"
      }

      this.$log.debug("payload = ", payload)

      let config = {
        method  : "post",
        url     : "/api/board/getNoticeList",
        data    : payload,
        name    : "[ Axios / 공지사항목록조회 ]"
      }

      await this.$http(config)
        .then(res => {
          this.noticeList = res.data
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('공지글 불러오기 실패!')
      })
    },

    /**
     * 공지사항 추가로 불러오기
     * @param {*} res 
     */
    async loadMoreList(res) {
      this.$log.debug("공지글 더 불러오기 시작 page = ", res)

      let payload = {
        "paging" : res
      }

      this.$log.debug("payload = ", payload)

      let config = {
        method : "post",
        url : "/api/board/getNoticeList",
        data : payload,
        name    : "[ Axios / 공지사항목록추가조회 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug(res.data)

          if(res.data.length === 0 || res.data.length === undefined ){
            alert('더이상 공지사항이 없습니다.')
          } else {
            this.$log.debug("불러오기 성공")

            let newNoticeList = res.data

            newNoticeList.map(item => {
              this.noticeList.push(item)
            })
          }
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })
    },
  },
  mounted() {
    this.$log.debug("Notice list mounted")
    this.loadNoticeList()
  },
}
</script>

<template>
  <q-page id="site-container">
    <div class="section section-notice-01">
      <div class="container doc-page">
        <div class="row items-center">
          <div class="col">
            <h2 class="page-title text-center text-white">
              공지사항
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container q-pa-lg">
      <div class="row justify-center">
        <div class="col-12 col-sm-10 col-md-8">
          <NoticeList
            :notice-list="noticeList"
            :auth-level="authLevel"
            @load-more-list="loadMoreList"
          />
        </div>
      </div>
    </div>
  </q-page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
