<template>

  <!-- <NavBar /> -->

  <q-page 
    id="site-container">
    
    <!-- 메인영역 -->
    <Main />

    <!-- 서비스 영역 -->
    <Services />

    <!-- 스케쥴표 영역 -->
    <Schedule />
    
    <!-- Calculator -->
    <Calculator />

  </q-page>
  <!-- <Footer></Footer> -->
</template>

<script>
import Main from "@/components/customer/home/Section01.vue"
import Services from "@/components/customer/home/Services.vue"
import Schedule from "@/components/customer/home/Schedule.vue"
import Calculator from "@/components/customer/home/Calculator.vue"

export default {
  name: "HomePage",
  components: {
    Main,
    Services,
    Schedule,
    Calculator
  },
  data(){
    return {
      noticeList :[],
      authLevel : 0,
      isHome : true,
    }
  },
  methods:{
    moveTo(destination){
      this.$router.push(destination)
    },
    async loadNoticeList() {
      let payload = {
        "paging" : 0
      }

      this.$log.debug("payload = ", payload)

      let config = {
        method  : "post",
        url     : "/api/admin/getNoticeList",
        data    : payload,
        name    : "[ Axios / 공지사항조회 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug(res.data)  
          this.noticeList = res.data
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('공지글 불러오기 실패!')
      })
    },
  },
  mounted() {
    // this.loadNoticeList()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
