<script>

export default {
	name: "Modal-customer",
	props: {
		showModal: Boolean,
	},
	watch: {
	},
	emits: [
		"close-modal-search",
		"change-customer"
	],
	data() {
		return {
			byName      : true,
			byMobile    : false,
			byEmail     : false,

			lName       : "",
			fName       : "",
			mobile      : "",
			email       : "",

			customerList : [],
			tab 				: "by-name",
		}
	},
	methods: {
		tabToggle(res){
			switch (res) {
				case 1 :

					this.byName       = true
					this.byMobile     = false
					this.byEmail      = false
					break

				case 2 :

					this.byName       = false
					this.byMobile     = true
					this.byEmail      = false
					break

				case 3 :

					this.byName       = false
					this.byMobile     = false
					this.byEmail      = true
					break
			} 

		},

		/**
     * Title        : 고객검색
     * Status       : Actived
     * Description  : 입력된 고객 정보롤 고객정보 조회 실행
     */
		async searchCustomer(){
			this.$log.debug("")

			let payload

			// 이름으로 검색
			if(this.byName) {
				if(this.fName == "" || this.lName == ""){
					alert("성, 이름 모두 입력해주세요")
					return false
				}

				payload = {
					"cat"   : 0,
					"fName" : this.fName,
					"lName" : this.lName,
				}

				// 모바일 번호로 검색
			} else if(this.byMobile) {
				if(this.mobile == ""){
					alert("모바일번호를 입력해주세요")
					return false
				}

				payload = {
					"cat"   : 1,
					"mobile" : this.mobile,
				}

				// 이메일로 검색
			} else if(this.byEmail){
				if(this.email == ""){
					alert("이메일을 입력해 주세요")
					return false
				}

				payload = {
					"cat"   : 2,
					"email" : this.email,
				}
			}

			let config = {
				method 	: "post",
				url 		: "/api/member/searchCustomer",
				data 		: payload,
				name 		: "[ Axios / searchCustomer / 고객검색 ]" 
			}

			await this.$http(config)
				.then(res => {
					let tempRtn = res.data
					this.$log.debug("/admin/searchCustomer 실행됨", tempRtn)
					if(tempRtn !== undefined){
						this.customerList = tempRtn
					} else {
						alert("검색된 고객이 없습니다.")
					}
				}).catch(res => {
					this.$log.debug("/admin/searchCustomer 실패", res)
				})

		},

		changeCustomer(id, name){
			if(confirm('선택한 고객으로 변경하시겠습니까?')){
				let param = {
					"id" : id,
					"name" : name
				}
				this.$emit('change-customer', param)
				this.closeModal()
			}
		},

		// 모달닫기 고객검색
		closeModal() {
			this.$emit('close-modal-search')
		},
	}
}
</script>

<template>
	<teleport to="body">

		<q-dialog
			v-model="showModal"
			persistent>
			<q-card style="width: 700px; max-width: 80vw;">
        <q-card-section>
					<div class="row justify-between">
						<div class="text-h6">
							고객조회
						</div>
						<div>
							<q-btn
								@click="closeModal()" 
								flat v-close-popup round dense icon="close" />
						</div>
					</div>
					<div></div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row">
						<div class="col">
							<q-tabs
								v-model="tab"
								dense
								class="text-grey"
								active-color="primary"
								indicator-color="primary"
								narrow-indicator
								align="left">

								<q-tab
									name="by-name" 
									@click="tabToggle(1)"
									label="고객명" />
								<q-tab 
									name="by-mobile" 
									@click="tabToggle(2)"
									label="모바일번호" />
								<q-tab 
									name="by-email" 
									@click="tabToggle(3)"
									label="이메일" />
									
							</q-tabs>

							<q-separator />

							<q-tab-panels v-model="tab" animated>
								<q-tab-panel name="by-name">
									<div class="row">
										<div class="col-3">
											<q-input 
												v-model="lName" 
												label="성" />
										</div>
										<div class="col-6 q-ml-sm">
											<q-input 
												v-model="fName" 
												label="이름" />
										</div>
									</div>
								</q-tab-panel>

								<q-tab-panel name="by-mobile">
									<div class="row">
										<div class="col-4">
											<q-input 
												v-model="mobile" 
												label="전화번호" />
										</div>
									</div>
								</q-tab-panel>

								<q-tab-panel name="by-email">
									<div class="row">
										<div class="col-4">
											<q-input 
												v-model="email" 
												label="이메일" />
										</div>
									</div>

								</q-tab-panel>
							</q-tab-panels>
						</div>
					</div>

					<div class="row">
						<div class="col justify-center">
							<q-btn
								color="primary"
								class="full-width"
								label="검색"
								@click="searchCustomer" />
						</div>
					</div>
					<div 
						class="row mt-3">
						<div 
							class="col"
							v-for="(item, index) in customerList"
							v-bind:key="index"
							@click="changeCustomer(item.id, item.lName + item.fName)">
							<div class="customer-list">
								<label class="label">고객명 : </label>
								<div>{{ item.lName + item.fName }}</div>
								<label class="label">모바일 : </label>
								<div>{{ item.mobile }}</div>
								<label class="label">email : </label>
								<div>{{ item.email }}</div>
							</div>
						</div>
					</div>  
        </q-card-section>
      </q-card>
		</q-dialog>
	</teleport>
</template>
