<template>
  <LoginBox />
</template>

<script>
import LoginBox from '@/components/customer/LoginBox.vue'

export default {
  name: "LoginPage",
  components: {
    LoginBox,
  },
  setup(){

  },
  data() {
    return {
      navCurrent : 'login',
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
