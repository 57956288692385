<script>
import { ref } from "vue"

import Title from "@/components/admin/common/TitleArea.vue"

import ParcelLookupForm from "@/components/admin/ParcelLookupForm.vue"
import ParcelList from "@/components/admin/BulkParcelList.vue"
import ParcelBulkRegi from "@/components/common/modal/ParcelBulkRegi.vue"


export default {
  name: "Admin-parcel-infomation",
  meta: {
    title: "어드민 - 묶음배송 상품조회"
  },
  components: {
    Title,
    ParcelLookupForm,
    ParcelList,
    ParcelBulkRegi,
  },
  data() {
    return {
      description   : {
        title : '묶음배송관리'
      },
      mobileCountry : "",
      mobileNum     : "",
      customerEmail : "",
      courierCode   : "",
      courierNum    : "",
      modifyBulkSeq : null,
      mode          : 'reg',
      slide         : ref('list'),
    }
  },
  methods: {
    async loadParcelList(currPage) {
      let payload = {
        "mobile"      : this.mobileCountry + this.mobileNum,
        "email"       : this.customerEmail,
        "courierCode" : this.courierCode,
        "courierNum"  : this.courierNum,
        "paging"      : currPage
      }

      let config = {
        method  : "post",
        url     : "/api/bulkParcel/bulkParcelList",
        data    : payload,
        name    : "[ Axios / 묶음배송상품조회 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug("[ Axios / 묶음배송상품조회 ] = ", res.data)
          
          // Response data 를 data 에 넣는다.
          if(currPage == 0){
            this.$store.dispatch('loadBulkParcel', res.data)
          } else if(currPage > 0){
            this.$store.dispatch('loadMoreBulkParcel', res.data)
          }

          // if(res.data.length === 0) alert("조회된 상품이 없습니다.")
        })
        .catch((err) => {
          this.$log.debug("[ Axios / 묶음배송상품조회 / Error ] ", err)
      })
    },

    modifyBulk(seq){
      this.slide = 'modify'
      this.mode = 'modify'
      this.modifyBulkSeq = seq
    },

    newBulk(){
      this.slide = 'modify'
      this.mode = 'reg'
    },

    // 리스트로 돌아가기
    backToList(){
      this.bulkableParcelList = []
      this.$store.dispatch('clearTempBulkList')
      this.slide = 'list'
    },

    completeBulk(){
      this.bulkableParcelList = []
      this.$store.dispatch('clearTempBulkList')
      this.slide = 'list'
      this.loadParcelList(0)
    }
  },
  mounted() {
    this.$log.debug("[ ParcelBulkManage / 묶음배송관리 ]")
    this.loadParcelList(0)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
  <q-page class="q-pa-sm">
    <q-carousel
      v-model="slide"
      transition-prev="slide-right"
      transition-next="slide-left"
      animated
      control-color="primary"
      class="carousel-style"
      >

      <!-- 
        Slide 1 
        List carousel
      -->
      <q-carousel-slide name="list">
        <Title
          :desc="description">

          <q-btn 
            label="신규묶음"
            class="btn"
            size="md"
            color="primary"
            @click="newBulk" />

        </Title>
        
    
        <div class="row q-mt-lg">
          <div class="col">
            <div class="comp-box">
              <ParcelList
                @reload-bulk-list="loadParcelList(0)"
                @open-edit-modal="modifyBulk"
              />
            </div>
          </div>
        </div>
      </q-carousel-slide>

      <!-- 
        Slide 2
        Modified Area 
      -->
      <q-carousel-slide name="modify">
        <!-- 묶음배송으로 만들 목록 -->
        <q-page>
          <ParcelBulkRegi
            :form-mode="mode"
            :modi-seq="modifyBulkSeq"
            @complete-bulk="completeBulk"
            @back-to-list="backToList" 
            />
        </q-page>

      </q-carousel-slide>
    </q-carousel>

  </q-page>

</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.carousel-style {
  background: #f0f0f0;
  height:calc(100vh - 50px);
}
</style>
