<script>
/**
 * Type         : Biz component
 * Title        : 화물검색 컴포넌트
 * Description  : 화물 검색을 위한 컴포넌트
 * 
 */

// import axios from 'axios'
import { computed, ref } from 'vue'
import ModalCustomerSearch from "@/components/admin/modal/CustomerSearch.vue"

export default {
  name: "Biz-parcel-lookup-form-by-parcel",
  emits:[
    'lookup-opt-sync',
    'lookup-parcel'
  ],
  // 택배사 목록 변경 감지
  watch: {
    "$store.getters.getCourierList"(val) {
      this.courierList = val
    },
  },
  computed:{
    parcelLocationList(){
      return this.$store.getters.getParcelLocationListImport
    },
    shippingList(){
      return this.$store.getters.getFreightMethodList
    },
  },
  components: {
    ModalCustomerSearch,
  },
  data() {
    return {
      courierList       : this.$store.getters.getCourierList,
      byParcel          : true,
      byReceiver        : false,
      byMember          : false,

      // 수취인정보로 조회
      mobileCountry     : "",
      mobileNum         : "",
      customerEmail     : "",
      receiver          : "",

      // 배송상품정보로 조회
      courierCode       : "",
      courierNum        : "",

      // 회원정보로 조회
      customerName      : "",
      customerId        : "",

      filteredLocation  : [],
      shippingMethod    : [],

      datepicker  : ref({
        from  : "",
        to    : "" 
      }),
      proxyDate   : ref({ 
        from  : "",
        to    : ""
      }),


      modifyModalShow   : false,
      tab               : "product-info",
    }
  },

  methods:{

    /**
     * Title        : 선택된 일자 저장
     * Status       : Actived
     * Description  : 선택된 기간 v-model에 셋팅
     */
    updateProxy () {
      this.$log.debug("[ 검색기간 설정 / updateProxy ] 선택일자 업데이트. 캘린더 버튼 클릭 시 실행 ")
      this.proxyDate = this.datepicker
      this.lookupOptSync()
    },

    /**
     * Title        : 선택된 일자 저장
     * Status       : Actived
     * Description  : 선택된 기간 v-model에 셋팅
     */
    save () {
      this.$log.debug("[ 검색기간 설정 / save ] 선택된 일자를 검색조건 v-model에 저장 시 실행 ")
      this.datepicker = this.proxyDate
      this.lookupOptSync()
    },

    /**
     * Title        : 검색 카테고리 탭 토글
     * Status       : Actived
     * Description  : 화물 검색을 위해 분류되어 있는 카테고리 탭
     */
    tabToggle(res){
      if(res === 1){
        this.byParcel       = true
        this.byReceiver     = false
        this.byMember       = false

        this.courierCode    = ""
        this.courierNum     = ""

        this.mobileCountry  = ""
        this.mobileNum      = ""
        this.customerEmail  = ""
        this.receiver       = ""

        this.customerName   = ""
        this.customerId     = ""

      } else if(res === 2){
        this.byParcel       = false
        this.byReceiver     = true
        this.byMember       = false

        this.courierCode    = ""
        this.courierNum     = ""

        this.customerName   = ""
        this.customerId     = ""

      } else if(res === 3){
        this.byParcel       = false
        this.byReceiver     = false
        this.byMember       = true

        this.mobileCountry  = ""
        this.mobileNum      = ""
        this.customerEmail  = ""
        this.receiver       = ""

        this.courierCode    = ""
        this.courierNum     = ""
      }
    },

    /**
     * Title        : 검색조건 동기화
     * Status       : Actived
     * Description  : 화물 검색을 위한 조건을 parents에 전달한다.
     */
    lookupOptSync(){
      this.$log.debug("[ Parcel lookup form / lookupOptSync ]")

      let opt = {
        courierCode       : this.courierCode,
        courierNum        : this.courierNum,

        mobileCountry     : this.mobileCountry,
        mobileNum         : this.mobileNum,
        customerEmail     : this.customerEmail,
        receiver          : this.receiver,

        customerId        : this.customerId,
        filteredLocation  : this.filteredLocation,
        shippingMethod    : this.shippingMethod,

        proxyDate         : this.proxyDate
      }

      this.$emit('lookup-opt-sync', opt)
    },

    lookupParcel(){
      this.$emit('lookup-parcel')
    },
    
    /**
     * Title        : 검색조건 - 고객변경
     * Status       : Actived
     * Description  : 검색조건의 고객을 변경한다.
     */
    changeCustomer(param){
      this.$log.debug("고객변경", param)

      this.customerName   = param.name
      this.customerId     = param.id
    },


    /**
     * Title        : 고객찾기 모달 실행
     * Status       : Actived
     * Description  : -
     */
    customerSearch(){
      this.modifyModalShow = true
    },

    /**
     * Title        : 모달닫기
     * Status       : Actived
     * Description  : -
     */
    closeModalSearch(){
      this.modifyModalShow = false
      this.lookupOptSync()
    },

    /**
     * Title        : 캘린더 default 설정
     * Status       : Actived
     * Description  : 오늘 날짜 획득
     */
     getToday(){
      let today = new Date().toDateString()

      let newToday = new Date(today)

      let year  = newToday.getFullYear()    // 년
      let month = newToday.getMonth() + 1   // 월
      let day   = newToday.getDate()        // 일
      
      let result = year + "/" + month + "/" + day

      this.$log.debug("[ Parcel lookup form / getToday ] = ", result)

      return result
    },

    /**
     * Title        : 캘린더 default 설정
     * Status       : Actived
     * Description  : 일주일전 날짜 획득
     */
    getAWeekAgo(){
      let today = new Date().toDateString()

      let newToday = new Date(today)

      let year  = newToday.getFullYear()    // 년
      let month = newToday.getMonth() + 1   // 월
      let day   = newToday.getDate()        // 일

      let aWeekAgo = new Date(new Date().setDate(day - 7))

      let newAWeekAgo = new Date(aWeekAgo)

      year  = newAWeekAgo.getFullYear() // 년
      month = newAWeekAgo.getMonth() + 1  // 월
      day   = newAWeekAgo.getDate()      // 일

      let result = year + "/" + month + "/" + day

      this.$log.debug("[ Parcel lookup form / getAWeekAgo ] = ", result)

      return result
    },

    /**
     * Title        : 캘린더 default 설정
     * Status       : Actived
     * Description  : 캘린더의 일자를 기본설정으로 돌린다.
     */
    async initCalendar(){
      this.$log.debug("[ Parcel lookup form / initCalendar ]")

      const aWeekAgo  = this.getAWeekAgo()
      const today     = this.getToday()
      
      this.datepicker  = { 
        from  : aWeekAgo,
        to    : today
      }

      this.proxyDate   = { 
        from  : aWeekAgo,
        to    : today
      }
    },

    /**
     * Title        : 최초설정
     * Status       : Actived
     * Description  : 검색 조건 값을 default 한다.
     */
    async init(){
      this.$log.debug("[ Parcel lookup form / init ]")

      await this.initCalendar()

      this.lookupOptSync()
      this.$emit('lookup-parcel')
    }
  },
  
  beforeMount(){
    this.$getParcelLocationList()
  },
  
  mounted(){
    this.init()
  }
}
</script>

<template>
  <div class="comp-box">
    <div class="row">
      <div class="col-12 col-md-6 q-pr-xl">
      
        <q-tabs
          v-model="tab"
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          narrow-indicator
          align="left">

          <q-tab
            name="product-info" 
            @click="tabToggle(1)"
            label="배송상품정보" />
          <q-tab 
            name="person-info" 
            @click="tabToggle(2)"
            label="수취인정보" />
          <q-tab 
            name="member-info" 
            @click="tabToggle(3)"
            label="회원정보" />
            
        </q-tabs>

        <q-separator />

        <q-tab-panels v-model="tab" animated>

          <q-tab-panel name="product-info">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-4 q-pr-sm">
                <q-select
                  v-model="courierCode"
                  @update:model-value="lookupOptSync"
                  label="배송업체"
                  :options="courierList"
                  emit-value
                  map-options
                  behavior="menu"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-8">
                <q-input 
                  v-model="courierNum" 
                  @change="lookupOptSync"
                  label="송장번호"
                  placeholder="배송물품의 송장번호를 적어주세요."/>    
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="person-info">
            <div class="row">
              <div class="col-xs-12 col-sm-6 q-pr-sm">
                <q-input 
                  v-model="receiver" 
                  @change="lookupOptSync"
                  label="수취인명"
                  placeholder="수취인명을 입력해주세요" />
              </div>
              <div class="col-xs-12 col-sm-6">
                <q-input 
                  v-model="customerEmail" 
                  @change="lookupOptSync"
                  @blur="this.$validEmail(customerEmail)"
                  label="이메일"
                  placeholder="이메일을 입력해주세요" />   
              </div>
              <div class="col-xs-12 col-sm-4 q-pr-sm">
                <q-select
                  v-model="mobileCountry"
                  @change="lookupOptSync"
                  label="국가번호"
                  :options="['+61', '+82']"
                  emit-value
                  map-options
                  behavior="menu"
                  />
              </div>
              <div class="col-xs-12 col-sm-8">
                <q-input 
                  v-model="mobileNum"
                  @change="lookupOptSync"
                  @blur="this.$validMobile(mobileNum)"
                  label="전화번호"
                  placeholder="'-'을 제외한 전화번호를 입력해주세요" />    
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="member-info">
            <div class="row">
              <div class="col">
                <q-input
                  v-model="customerName" 
                  @change="lookupOptSync" 
                  label="회원명">
    
                  <template v-slot:append>
                    <q-btn
                      @click="customerSearch"
                      round dense flat icon="search" />
                  </template>
                </q-input>
              </div>
            </div>

          </q-tab-panel>
        </q-tab-panels>
      </div>


      <!-- 

        검색 조건 오른쪽

      -->
      <div class="col-12 col-md-6">
        <div class="range-picker">
          <q-btn icon="event" round color="primary">
            <q-popup-proxy @before-show="updateProxy" cover transition-show="scale" transition-hide="scale">
              <q-date v-model="proxyDate" range>
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn label="Cancel" color="primary" flat v-close-popup />
                  <q-btn label="OK" color="primary" flat @click="save" v-close-popup />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-btn>

          <span class="from">{{ datepicker.from }}</span> ~ <span class="to">{{ datepicker.to }}</span>
        </div>

        <!-- 운송수단 체크박스 -->
        <q-checkbox
          v-for="(item, index) in shippingList" :key="index"
          v-model="shippingMethod"
          @update:modelValue="lookupOptSync"
          :val="item.value"
          :label="item.label" 
          color="teal" />

        <!-- 화물위치 체크박스 -->
        <q-checkbox
          v-for="(item, index) in parcelLocationList" :key="index"
          v-model="filteredLocation"
          @update:modelValue="lookupOptSync"
          :val="item.code"
          :label="item.name" 
          color="teal" />
      </div>
    </div>
  </div>
  

  <ModalCustomerSearch
    :showModal="modifyModalShow"
    @change-customer="changeCustomer"
    @close-modal-search="closeModalSearch" />

</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.range-picker {
  font-size: 20px;
  font-weight: 400;

  margin-bottom: 10px;

  .from {
    margin-left: 15px;
  }
}

</style>
