<template>
  <q-header 
    elevated
    class="bg-white">
    <q-toolbar
      class="doc-page">

      <!-- LOGO -->
      <q-avatar
        size="100px"
        @click="this.$router.push('/')">
        <img 
          id="site-logo" 
          :src="require(`@/assets/imgs/common/logo.png`)">
      </q-avatar>

      <q-space />

      <!-- MENU AREA -->
      <!-- SERVICE -->
      <q-btn-dropdown
        v-show="!mobile"
        stretch flat
        color="white"
        text-color="black"
        label="서비스">
        
        <q-list>
          <q-item 
            clickable
            @click="this.$router.push('service-flight')">
            <q-item-section>항공</q-item-section>
          </q-item>
          <q-item 
            clickable
            @click="this.$router.push('service-ocean')">
            <q-item-section>해상</q-item-section>
          </q-item>
          <q-item 
            clickable
            @click="this.$router.push('service')">
            <q-item-section>비지니스</q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

      <!-- NOTICE -->
      <q-btn
        v-show="!mobile"
        stretch flat
        clickable
        color="white"
        text-color="black"
        label="공지사항"
        @click="this.$router.push('notice')">
      </q-btn>

      <!-- PARCEL INQUERY -->
      <q-btn
        v-show="!mobile" 
        stretch flat
        clickable
        color="white"
        text-color="black"
        label="배송조회"
        @click="this.$router.push('parcel-info')">
      </q-btn>

      <!-- PARCEL REGISTRATION -->
      <q-btn-dropdown
        v-show="!mobile"
        stretch flat
        color="white"
        text-color="black"
        label="배송신청">
        
        <q-list>
          <q-item 
            clickable
            @click="this.$router.push('parcel-registration')">
            <q-item-section>한국 -> 호주</q-item-section>
          </q-item>
          <!--// 이후 재 오픈
            <q-item 
            clickable
            @click="this.$router.push('parcel-reg-aus-to-kor')">
            <q-item-section>호주 -> 한국</q-item-section>
          </q-item>
          -->
        </q-list>
      </q-btn-dropdown>

      <!-- USER AVATAR AREA -->
      <q-btn-dropdown
        v-show="!mobile"
        stretch flat
        color="white"
        size="20px"
        text-color="grey-8">

        <template v-slot:label>
          <div class="row items-center no-wrap">
            <q-icon left name="account_circle" />
            <div 
              v-show="isSignedIn"
              class="text-subtitle2">
              {{session.lName + session.fName}} 님
              <br>안녕하세요
            </div>
          </div>
        </template>

        <div
          v-show="!mobile" 
          class="row no-wrap q-pa-md">
          
          <div class="column items-center">

            <q-list>
              <q-item 
                v-show="!isSignedIn"
                clickable
                @click="this.$router.push('login')">
                <q-item-section>로그인</q-item-section>
              </q-item>

              <q-item 
                v-show="isSignedIn"
                clickable
                @click="logout">
                <q-item-section>로그아웃</q-item-section>
              </q-item>
              
              <q-item 
                v-show="!isSignedIn"
                clickable
                @click="this.$router.push('signUp')">
                <q-item-section>회원가입</q-item-section>
              </q-item>

              <q-item 
                v-show="!isSignedIn"
                clickable
                @click="this.$router.push('admin')">
                <q-item-section>관리자 로그인</q-item-section>
              </q-item>
            </q-list>

            
          </div>
        </div>
      </q-btn-dropdown>

      
      <!-- AVATAR FOR MOBILE 
      <q-btn
        v-show="mobile"
        stretch flat
        clickable
        color="white"
        text-color="black"
        icon="account_circle"
        @click="this.$router.push('notice')">
      </q-btn>
      -->

      <!-- HAMBURGER MENU FOR MOBILE -->
      <q-btn-dropdown
        v-show="mobile"
        stretch flat
        dropdown-icon="none"
        color="white"
        size="0"
        text-color="grey-8">      

        <template v-slot:label>
          <div class="row items-center no-wrap">
            <div
              v-show="!isSignedIn">
              <q-icon 
                size="30px" 
                v-show="!isSignedIn" 
                center 
                name="account_circle" />
            </div>
            <div
              v-show="isSignedIn"
              class="text-left text-body2">
              {{session.lName + session.fName}} 님
              <br>안녕하세요
            </div>
          </div>
        </template>

        <div 
          v-show="mobile" 
          class="row no-wrap q-pa-md">
          <div class="column items-center">
            <q-list>
              <q-item 
                v-show="!isSignedIn"
                clickable
                @click="this.$router.push('login')">
                <q-item-section>로그인</q-item-section>
              </q-item>
              <q-item 
                v-show="isSignedIn"
                clickable
                @click="logout">
                <q-item-section>로그아웃</q-item-section>
              </q-item>
              <q-item 
                v-show="!isSignedIn"
                clickable
                @click="this.$router.push('signUp')">
                <q-item-section>회원가입</q-item-section>
              </q-item>
              <q-item 
                v-show="!isSignedIn"
                clickable
                @click="this.$router.push('admin')">
                <q-item-section>관리자 로그인</q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
        
        <q-separator />
        
        <div class="row no-wrap q-pa-md">
          <div class="column items-center">
            <q-list>
              <!-- SERVICE -->
              <q-item 
                clickable
                @click="this.$router.push('service')">
                <q-item-section>서비스</q-item-section>
              </q-item>

              <!-- NOTICE -->
              <q-item 
                clickable
                @click="this.$router.push('notice')">
                <q-item-section>공지사항</q-item-section>
              </q-item>
              
              <!-- PARCEL INQUERY -->
              <q-item 
                clickable
                @click="this.$router.push('parcel-info')">
                <q-item-section>배송조회</q-item-section>
              </q-item>
    
              <!-- PARCEL REGISTRATION -->
              <q-item 
                clickable
                @click="this.$router.push('parcel-registration')">
                <q-item-section>배송신청 (한국 -> 호주)</q-item-section>
              </q-item>
              <!--// 이후 재 오픈
              <q-item 
                clickable
                @click="this.$router.push('parcel-reg-aus-to-kor')">
                <q-item-section>배송신청 (호주 -> 한국)</q-item-section>
              </q-item>
              -->
            </q-list>
          </div>
        </div>        
      </q-btn-dropdown>
    
    </q-toolbar>

    <q-resize-observer @resize="onResize" />
  </q-header>
  
</template>

<script>
export default {
  name: "NavgationBar",
  props: {
    current : String
  },
  setup(){
    document.title = "Aone Logistics"
  },
  data(){
    return {
      session : this.$store.getters.getLocalSession,
      isSignedIn : this.$store.getters.getSessionStatus,
      mobile : false
    }
  },
  watch:{
    isSignedIn:{
      handler(val){
        this.$log.debug("watch this.session = ", val)

        if(val){
          this.$log.debug("watch 로그인 상태")
        } else {
          this.$log.debug("watch 로그인 상태 아님")
        }
      }
    },
    "$store.getters.getSessionStatus"(val){
      this.$log.debug("watch getSessionStatus = ", val)
        this.session = this.$store.getters.getLocalSession
        this.isSignedIn = this.$store.getters.getSessionStatus
    }
  },
  methods:{
    /*
    // 세션을 체크하는 기능
    checkSession(){
      
      const sess = JSON.parse(sessionStorage.getItem('vuexStore'))
      this.$log.debug("checkSession sess = ", sess)
      let sessData;
      if(sess !== undefined && sess !== null){
        sessData = sess.user.localSession.email
        this.$log.debug("checkSession sessData = ", sessData)

        if(sessData === undefined || sessData === null){
          this.$log.debug("로그인 상태 아님. method")
          this.isSignedIn = false
        } else {
          this.$log.debug("로그인 상태임. method")
          this.isSignedIn = true
        }
      }
    },
    */
    onResize(size){
      this.mobile = (size.width < 768 ? true : false)
    },
    // 로그아웃
    logout(){
      let config = {
        method : "get",
        url : "/api/cust/signOut",
      }

      this.$log.debug("/cust/signOut 실행됨")

      this.$http(config)
        .then(res => {

          if(res.status === 200){
            this.$log.debug("/cust/signOut 성공", res)

            this.$store.dispatch('destroyCustomerInfo')
            sessionStorage.clear()

            this.$router.push('/')

          } else {
            alert(res.info.reason)
          }
        })
        .catch(res => {
          this.$log.debug("/customer/signOut 실패함 catch", res)
          alert("로그아웃 도중 오류가 발생했습니다.")
        })
    }
  },
  mounted(){
    this.$log.debug("Nav mounted!!!!!!")
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.q-avatar {
  #site-logo {
    height: auto;
  }
}
.doc-page {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

</style>
