<template>
  <teleport to="body">

    <q-dialog 
      v-model="showModal">

      <q-layout 
        container
        class="bg-white">
        
        <q-header class="bg-primary">
          <q-toolbar>
            <q-toolbar-title>택배사 추가</q-toolbar-title>
            <q-btn flat v-close-popup round dense icon="close" />
          </q-toolbar>
        </q-header>

        <q-footer class="bg-white text-black">
          <q-toolbar>
            <q-btn
              v-if="mode === 0"
              label="추가"
              class="full-width"
              color="primary"
              @click="addItem">
            </q-btn>
          </q-toolbar>
        </q-footer>

        <q-page-container>
          <q-page
            padding>
            <div class="row q-mb-sm">
              <div class="col">

                <q-input 
                  v-model="newCourierCode"
                  label="배송업체 코드" />    

              </div>
              <div class="col">

                <q-input 
                  v-model="newCourierName"
                  label="배송업체 이름" />    

              </div>
            </div>
            <div class="row">
              <div class="col">
                <q-input 
                  v-model="country"
                  label="국가코드"
                  placeholder="ex)'KOR', 'AUS'"/>
              </div>
            </div>

          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>

  </teleport>
</template>

<script>

export default {
  name: "Modal-courier-form",
  props: {
    showModal : Boolean,
    mode : Number,
    courierCode : String,
  },
  watch:{
    showModal:{
      handler(newVal) {
        // 모달을 띄우기 위한 프로그래밍
        // const body = document.querySelector("body")

        this.active = newVal
        // this.showModal ? body.classList.add("modal-open") : body.classList.remove("modal-open")
        // this.showModal ? this.showModalFunc() : this.$log.debug("???")

        // 상품 추가 모드/상품 수정 모드 여부 판단
        const mode = this.mode
        const idx = this.idx

        this.$log.debug("Created parcel id = " + idx )
        if(mode === 0){
          // 상품추가모드
          this.$log.debug("상품추가모드")
          this.company = ""
          this.number = ""
          this.note = ""
        } else if(mode === 1){
          // 상품수정모드
          this.$log.debug("상품수정모드")
          this.setDataInput(idx)
        }
      },
      immediate: true,
      deep: true,
    }
  },
  emits:[
    "close-modal",
    "add-item",
    "modify-item"
  ],
  data() {
    return {
      active: this.showModal,
      newCourierCode  : "",
      newCourierName  : "",
      country         : "",
    }
  },
  methods : {
    addItem(){
      const newData = {
        courierCode : this.newCourierCode,
        courierName : this.newCourierName,
        country     : this.country,
      }
      this.$emit("add-item", newData)
      this.$emit("close-modal")
    },
    modifyItem(){
      this.$log.debug("modifyItem executed")
      const modifyData = {
        company : this.company,
        number : this.number,
        note : this.note
      }
      this.$log.debug("modifyItem data = ", modifyData)
      this.$log.debug("modifyItem idx = ", this.idx)
      this.$emit("modify-item", {data : modifyData, idx: this.idx})
      this.$emit("close-modal")
    },
    setDataInput(idx){
      let target = this.$store.state.newParcelList[idx]
      this.$log.debug("idx = ", idx)
      this.$log.debug("target = ", target)

      this.company = target.company,
      this.number = target.number,
      this.note = target.note
    },
  }
}
</script>
