<template>
  <q-layout
    view="lHh Lpr lFf">
    <Header></Header>
    
    <q-page-container>
      <router-view />
    </q-page-container>

    <Footer></Footer>
  </q-layout>
</template>

<script>
export default {
  name: 'LayoutCustomer',
}
</script>

<script setup>
import Header from '@/components/customer/NavBar.vue'
import Footer from "@/components/customer/Footer.vue"


import { ref } from 'vue'


</script>
