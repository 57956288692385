<script>

export default {
	name: "Modal-customer",
	props: {
		showModal: Boolean,
		mode: Number,
		selectedCustomer: Object,
	},
	emits: [
		"close-modal",
		"modify-customer"
	],
	methods: {
		modifyCustomer() {
			this.$log.debug("[ Admin / Customer modal / modifyCustomer ] ", this.$props.selectedCustomer)
			this.$emit("modify-customer", this.$props.selectedCustomer)
			this.$emit("close-modal")
		},
		
		// 모달닫기
		closeModal() {
			this.$emit("close-modal")
		},
	},
  mounted(){
    this.$getMemberLevelList()
  }
}
</script>
<template>
	<teleport to="body">
		<q-dialog 
      v-model="showModal">

      <q-layout 
        container
        class="bg-white">

        <q-header class="bg-primary">
          <q-toolbar>
            <q-toolbar-title>고객정보수정</q-toolbar-title>
            <q-btn flat v-close-popup round dense icon="close" @click="closeModal" />
          </q-toolbar>
        </q-header>

        <q-footer class="bg-white text-black">
          <q-toolbar>
            <q-btn
              v-if="mode === 1"
              label="수정"
              class="full-width"
              color="primary"
              @click="modifyCustomer">
            </q-btn>
          </q-toolbar>
        </q-footer>
        <q-page-container>
          <q-page
            padding>
            <div class="row q-gutter-md q-mb-sm">
              <div class="col">
                <q-select
                  v-model="selectedCustomer.authLevel"
                  label="고객등급"
                  :options="this.$store.getters.getMemberAuthLevelList"
                  emit-value
                  map-options/>
              </div>
              <div class="col">
                <q-input 
                  v-model="selectedCustomer.id"
                  label="고객번호"
									disable/>    
              </div>
            </div>
						<div class="row q-gutter-md q-mb-sm">
              <div class="col">
                <q-input 
                  v-model="selectedCustomer.email"
                  label="이메일" 
									maxlength="100"/>
              </div>
						</div>
						<div class="row q-gutter-md q-mb-sm">
              <div class="col">
                <q-input 
                  v-model="selectedCustomer.lName"
                  label="성"
									maxlength="20"/>    
              </div>
							<div class="col">
                <q-input 
                  v-model="selectedCustomer.fName"
                  label="이름"
									maxlength="20"/>    
              </div>
            </div>
            <div class="row q-gutter-md">
              <div class="col">
                <q-input 
                  v-model="selectedCustomer.country"
                  label="국가코드"
                  placeholder="ex)'KOR', 'AUS'"/>
              </div>
							<div class="col">
                <q-input 
                  v-model="selectedCustomer.mobile"
                  label="모바일"/>
              </div>
							<div class="col">
                <q-input 
                  v-model="selectedCustomer.regDate"
									disable
                  label="가입일"/>
              </div>
            </div>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>

	</teleport>
</template>