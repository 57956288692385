<script>
  import Sidebar from '@/components/admin/Sidebar.vue'
  import ParcelRegiForm from '@/components/common/modal/ParcelRegi.vue'
  import Calculator from "@/components/customer/Calculator.vue"
  
  export default {
    name: 'LayoutAdmin',
    data(){
      return {
        drawer      : true,
        newParcel   : false,
        calculator  : false,
        version     : process.env.VUE_APP_VERSION
      }
    },
    components:{
      Sidebar,
      ParcelRegiForm,
      Calculator
    }
  }
</script>

<template>
  <q-layout view="hHh lpR fff">

    <q-header 
      elevated 
      class="bg-cyan-8">
      <q-toolbar>
        <q-btn flat round dense icon="menu" @click="drawer = !drawer" />
        
        <q-toolbar-title>
          Aone logistics - 관리자
          <q-badge 
            class="hover"
            @click="this.$router.push('/admin/update-report')" 
            color="orange">
            {{ version }}
          </q-badge>
        </q-toolbar-title>
        

        <!-- 무게 계산기 -->
        <q-btn 
          flat 
          round
          dense 
          icon="scale"
          class="rotate"
          @click="calculator = !calculator" >
        </q-btn>

        <!-- 새 배송상품 등록 -->
        <q-btn 
          flat 
          round
          dense 
          icon="add"
          class="rotate"
          @click="newParcel = !newParcel" >
        </q-btn>
      </q-toolbar>
    </q-header>
    
    <Sidebar 
      :drawer="drawer"
    />
    

    <!-- 페이지 router 영역 -->
    <q-page-container>
      <router-view />
    </q-page-container>

    <ParcelRegiForm
      :new-parcel="newParcel"
      @close-modal="newParcel= !newParcel"
    />


    <teleport to="body">

      <q-dialog 
        v-model="calculator">

        <q-card class="card-size">

          <q-toolbar>
            <q-toolbar-title>
              <div class="text-h6">부피/무게 측정기</div>
            </q-toolbar-title>
            <q-btn flat v-close-popup round dense icon="close" @click="calculator = !calculator" />
          </q-toolbar>

          <div class="q-pa-md">
            <Calculator />
          </div>

        </q-card>
        
        
      </q-dialog>

    </teleport>

  </q-layout>
</template>
<style scoped lang="scss">
.rotate {
  transition: 0.5s ease-in-out;
  transform:rotate(0deg);
}
.opened {
  transition: 0.5s ease-in-out;
  transform:rotate(405deg)
}

.hover {
  &:hover {
    cursor: pointer;
  }
}

</style>