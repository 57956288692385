<script>
/**
 * Type   : Biz component
 * Title  : 화물 운송장 정보 임시목록
 * Description : 신규 등록할 화물들의 운송장 정보(목적지 정보 제외 택배사, 메모 등)
 * 
 */

export default {
  name: "Biz-list-temp-parcels",
  components: {},
  computed: {
    parcels() {
      return this.$store.getters.getNewList
    },
  },
  data() {
    return {}
  },

  emits: [
    "delete-from-list",
    "modal-modify"
  ],

  methods: {
    /**
     * Title        : 화물지우기
     * Status       : Actived
     * Description  : 등록할 임시 화물목록에서 선택한 화물지우기
     */
    removeParcel(id) {
      this.$emit("delete-from-list", id)
    },

    /**
     * Title        : 선택 화물 수정 모달 호출
     * Status       : Actived
     * Description  : 등록할 임시 화물목록에서 선택한 화물을 수정하기 위한 모달을 띄운다.
     */
    modalModify(id) {
      this.$log.debug("Click for edit with parcel id = " + id)
      this.$emit("modal-modify", id)
    },
  },
  /*
  mounted(){
    this.parcels = this.$store.state.newParcelList
  }
  */
};
</script>

<template>
  <div class="parcel-unit" v-for="(parcel, i) in parcels" :key="i">
    <q-card class="parcel-unit-inner q-pa-sm">
      <q-list>
        <q-item>
          <q-item-section avatar>
            <q-icon color="primary" name="local_shipping" />
          </q-item-section>

          <q-item-section>
            <div class="courier-number">{{ parcel.number }}</div>
            <div class="courier-company">{{ parcel.courierName }}</div>
          </q-item-section>
        </q-item>
      </q-list>

      <q-separator inset />

      <q-card-section class="note">
        <q-icon class="material-icons-outlined" name="assignment"> </q-icon>
        {{ parcel.note }}
      </q-card-section>

      <q-card-section v-if="parcel.eatable === 0">
        <q-icon class="material-icons-outlined" name="check_circle"> </q-icon>
        음식물 포함
      </q-card-section>

      <q-separator inset />

      <q-card-actions align="around">
        <q-btn flat label="수정" color="green" @click="modalModify(i)"> </q-btn>
        <q-btn flat label="삭제" color="red" @click="removeParcel(i)"> </q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<style scoped lang="scss">
.parcel-unit {
  min-width: 300px;
  max-width: 400px;


  .parcel-unit-inner {
    display: flex;
    flex-direction: column;
  }
}

.courier-number {
  font-size: 0.875rem;
  word-break: break-all;
  word-wrap: normal;
}


.courier-company {
  font-size: 1rem;
  word-break: break-all;
  word-wrap: normal;
}

.note {
  height: 100px;
  overflow-y: auto;
}
</style>
