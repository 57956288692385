<script>
import Title from "@/components/admin/common/TitleArea.vue"
import NoticeList from "@/components/admin/NoticeList.vue"
import ModalNoticeRegi from "@/components/admin/modal/RegiNoticeForm.vue"


export default {
  name: "Admin-notice-manage",
  meta: {
    title: "어드민-공지사항관리"
  },
  components: {
    Title,
    NoticeList,
    ModalNoticeRegi,
  },
  /*
  setup(){
  },
  */
  data() {
    return {
      noticeList  : [],
      noticeModal : false,
      editorMode  : 0,
      authLevel   : 1,
      noticeData  : {
        id      : "",
        title   : "",
        writer  : "",
        isFixed : 1,
        isShown : 0
      },
      description   : {
        title : '공지사항 관리'
      },
    }
  },
  methods: {
    /**
     * 변경된 리스트의 타이틀 업데이트
     */
    updateList(editedData){
      this.$log.debug("Update notice edited title = > ", editedData)
      
      for(let i = 0; i < this.noticeList.length; i++){
        this.$log.debug("변경되어야 할 목록 id = > " + editedData.id)
        if(this.noticeList[i].id === editedData.id){
          this.$log.debug("변경되어야 할 id를 찾은 경우 = > " + this.noticeList[i].id)
          this.noticeList[i].title = editedData.title
        }
      }
    },

    /**
     * 공지사항목록조회
     * @param {*} curr : 현재 페이지 번호
     */
    async loadNoticeList(curr) {
      let payload = {
        "paging" : curr,
        "type"   : "admin"
      }

      let config = {
        method  : "post",
        url     : "/api/board/getNoticeList",
        data    : payload,
        name    : "[ Axios / 공지사항목록조회 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug(res.data)

          if(res.data.length === 0 || res.data.length === undefined ){
            alert('더이상 공지사항이 없습니다.')
          } else {
            let newNoticeList = res.data

            newNoticeList.map(item => {
              this.noticeList.push(item)
            })
          }
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('공지글 불러오기 실패!')
      })
    },

    /**
     * 공지사항 더 불러오기 - deprecated will be deleted
     * @param {*} res 
     */
    async loadMoreList(res){
      this.$log.debug("공지글 더 불러오기 시작 page = ", res)

      let payload = {
        "paging" : res
      }

      this.$log.debug("payload = ", payload)

      let config = {
        method  : "post",
        url     : "/api/board/getNoticeList",
        data    : payload,
        name    : "[ Axios / 공지사항목록추가조회 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug(res.data)

          if(res.data.length === 0 || res.data.length === undefined ){
            alert('더이상 공지사항이 없습니다.')
          } else {
            let newNoticeList = res.data

            newNoticeList.map(item => {
              this.noticeList.push(item)
            })
          }
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })
    },

    /**
     * 공지사항 추가 모달 열기
     */
    callModalAddNotice(){
      this.noticeModal = true
      this.editorMode = 0
    },

    /** 
     * 공지사항 수정
     */
    callModalEditNotice(selected){
      this.noticeModal    = true
      this.editorMode     = 1
      this.noticeData = selected

      this.$log.debug("Edit notice executed => noticeModal : " + this.noticeModal + ", editorMode : " + this.editorMode)
      this.$log.debug("noticeData => ", selected)
    },

    /**
     * 모달 닫기
     */
    closeModal(){
      this.$log.debug("Notice board Close modal")
      this.noticeModal = false
    },
  },
  mounted() {
    this.$log.info("[ Page / 공지사항관리 ]")
    this.loadNoticeList(0)
  },
}
</script>


<template>
  <q-page class="q-pa-lg">
    <div class="row">
      <div class="col">
        <Title
          :desc="description">

          <q-btn
            label="공지사항 등록하기"
            color="primary"
            @click="callModalAddNotice">
          </q-btn>

        </Title>  
      </div>
    </div>

    <div class="row q-mt-md q-mb-md">
      <div class="col">
        <div class="comp-box">
          <NoticeList
            :notice-list="noticeList"
            :auth-level="authLevel"
            @reload-parcel-list="loadNoticeList"
            @load-more-list="loadNoticeList"
            @edit-modal="callModalEditNotice"
          />
        </div>
      </div>
    </div>

  </q-page>

  <ModalNoticeRegi
    :show-modal="noticeModal"
    :editor-mode="editorMode"
    :notice-data="noticeData"
    @update-list="updateList"
    @close-modal="closeModal" />
  
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
