<script>
/**
 * Type   : Modal component
 * Title  : 배송상품 모달
 * Description : 수정에 사용하는 모달
 * 
 */

import ModalCustomerSearch from "@/components/admin/modal/CustomerSearch.vue"


export default {
  name: "ModalParcelInfoForm",

  props:{
    selectedParcel  : Array,
    user            : String
  },
  components: {
    ModalCustomerSearch,
  },
  emits:[
    "add-item",
    "reload"
  ],

  data() {
    return {
      parcelInfo      : this.selectedParcel[0],
      parcelIds       : [],
      customerModal : false,
      dimension       : {
        width           : this.selectedParcel[0].width,
        length          : this.selectedParcel[0].length,
        height          : this.selectedParcel[0].height,
      }
    }
  },
  methods : {
    
    /**
     * Title        : 묶음배송번호 불러오기
     * Status       : Actived
     * Description  : 상품과 같이 묶인 상품의 ID 조회
     */
    async getBulkList(){
      let payload = {
        bulkSeq : this.selectedParcel[0].bulkSeq
      }

      let config = {
        method  : "post",
        url     : "/api/parcel/getBulkList",
        data    : payload,
        name    : "[ Axios / ParcelInfoForm / 상품과 같이 묶인 상품의 ID 조회 ]"
      }
  
      await this.$http(config)
        .then((res) => {
          this.parcelIds = res.data
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('묶음배송 조회에 실패했습니다')
      })

    },

    /** 
     * Title        : 배송상품 수정
     * Status       : Actived
     * Description  : 조회된 배송상품의 정보를 수정한다
     */ 
    async modifyParcel() {
  
      let payload = {
        courierCode     : this.selectedParcel[0].courierCode,
        courierNum      : this.selectedParcel[0].courierNum,
        
        receiver        : this.selectedParcel[0].receiver,
        memo            : this.selectedParcel[0].memo,
        eatable         : this.selectedParcel[0].eatable,
  
        destiCity       : this.selectedParcel[0].destiCity,
        freightMethod   : this.selectedParcel[0].freightMethod,
  
        mobile          : this.selectedParcel[0].mobileCountry + this.selectedParcel[0].mobileNum,
        customerNum     : this.selectedParcel[0].customerNum,
        
        pickUpLocation  : this.selectedParcel[0].pickUpLocation,
        
        parcelLocation  : this.selectedParcel[0].parcelLocation,
        
        deliveryAddress : this.selectedParcel[0].deliveryAddress,
        
        width           : this.dimension.width,
        length          : this.dimension.length,
        height          : this.dimension.height,
        weight          : this.selectedParcel[0].weight,
        actualWeight    : this.selectedParcel[0].actualWeight,
        
        id              : this.selectedParcel[0].id,


        // bulkShipping   : this.bulkShipping,
        // bulkSeq        : this.bulkSeq
      }
  
      let config = {
        method  : "post",
        url     : "/api/parcel/modifyParcel",
        data    : payload,
        name    : "[ Axios / ParcelInfoForm / 상품정보수정 ]"
      }
  
      await this.$http(config)
        .then(() => {
          this.$log.debug("수정했습니다.")
  
          this.$emit('reload')
          this.$emit('close-modal')
          
          alert("상품정보가 수정되었습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('수정에 실패하였습니다.')
      })
    },
  
    /** 
     * Title        : 배송상품정보 모달 닫기
     * Status       : Actived
     * Description  : 현재모달을 닫는다
     */ 
    closeModalThis(){
      this.$emit('close-modal')
    },
  
    /** 
     * Title        : 고객변경
     * Status       : Suspended
     * Description  : 상품에 할당된 고객을 변경한다
     */ 
    changeCustomer(param){
      this.$log.debug("[ Parcel info form / 고객변경 ] = ", param)
  
      this.selectedParcel[0].customerName = param.name
      this.selectedParcel[0].customerNum = param.id
    },
  
    /** 
     * Title        : 등록고객 변경 모달 호출
     * Status       : Suspended
     * Description  : 고객변경모달을 호출한다
     */ 
    // 
    customerSearch(){
      this.customerModal = true
    },
  
    closeModalSearch(){
      this.customerModal = false
    },

    /** 
     * Title        : 부피무게 자동계산
     * Status       : Actived
     * Description  : width, height, length 수정 시 부피무게 자동계산
     */ 
    calculate (val) {
      // this.$log.debug("[ Parcel info form / Watch ] val = ", val)

      let data = Object.values(val)

      // this.$log.debug("[ Parcel info form / Watch ] data = ", data)

      // Dimension 을 곱한다.
      let result = data.reduce((acc, cur)=>{

        // this.$log.debug("[ Parcel info form / Watch ] acc = ", acc)
        // this.$log.debug("[ Parcel info form / Watch ] cur = ", cur)

        return acc * cur
      })
      
      // 항공과 해상의 비율
      const flight = 6000
      const ocean = 1000000
      
      this.$log.debug("flightWeight = " + (result/flight))
      this.$log.debug("oceanWeight = " + (result/ocean))

      let decimalLength = 100

      let calcualtion = function(result, ratio){
        return Math.round((result/ratio) * decimalLength) / decimalLength
      }

      // 항공/해상에 따라 부피무게가 계산이 된다
      if(this.selectedParcel[0].freightMethod == "1") {
        this.selectedParcel[0].weight = calcualtion(result, ocean)
      } else {
        this.selectedParcel[0].weight = calcualtion(result, flight)
      }

      // Store 의 데이터도 변경한다.
      this.selectedParcel[0].width  = this.dimension.width
      this.selectedParcel[0].length = this.dimension.length
      this.selectedParcel[0].height = this.dimension.height
    }
  },

  mounted(){
    // 묶음배송번호
    this.getBulkList()
    // 배송상품 위치목록
    this.$getParcelLocationList()
    // 택배사 목록
    this.$getCourierList()
    // 픽업 목록
    this.$getPickupList()
    // 목적지(도시) 
    this.$getCityList()
  },

  // TODO : 입력란 무게 자동계산 기능 추가
  watch:{
    dimension: {
      handler : function(val){
        this.$log.debug("[ ParcelInfoForm / 무게계산기능 ] = ", val)
        this.calculate(val)
      },
      deep:true
    }
  },
}

</script>


<template>

  <q-layout 
    container
    class="lg-width"
    view="lHh lpr lFf">

    <q-header 
      class="bg-primary"
      elevated>
      <q-toolbar>
        <q-toolbar-title>등록배송상품정보</q-toolbar-title>
        <q-btn flat v-close-popup round dense icon="close" @click="closeModalThis" />
      </q-toolbar>
    </q-header>

    <q-page-container>
      <q-page
        padding>

        <h5>1.고객정보</h5>
        <div class="q-pa-md">
          <div class="form-column-wrap">

            <q-input 
              v-model="this.selectedParcel[0].customerName"
              label="등록고객"
              disable />

            
            <q-btn v-if="user != 'cus'" label="고객조회" @click="customerSearch" color="primary" />

          </div>
        </div>

        <h5>2.배송상품정보</h5>
        <div
          v-show="this.selectedParcel[0].bulkShipping === 'Y'"
          class="q-pa-md">
          <div class="form-column-wrap">

            <!-- 
              묶음 배송 
            -->
            <div 
              class="row q-mb-md">
              <div class="col-12">
                <label for="bulkSeq" class="form-label">묶음배송된 상품 : {{ parcelIds.length -1 }}</label>
                <!-- <input v-model="this.selectedParcel[0].bulkSeq" type="text" class="form-control"> -->
              </div>
              <div class="col-12">

                <template
                  v-for="(item, idx) in parcelIds" :key="idx">
                  <q-chip 
                    v-if="item.id != this.selectedParcel[0].id"  
                    color="teal" 
                    text-color="white">
                    {{ item.id }}
                  </q-chip>
                </template>
              
              </div>
            </div> 
          </div>
        </div>

        <div class="q-pa-md">
          <div class="form-column-wrap">
            <q-select
              v-model="this.selectedParcel[0].courierCode"
              label="배송업체"
              :options="this.$store.getters.getCourierList"
              behavior="menu"
              emit-value
              map-options
              :disable="user === 'cus'" />

            <q-input 
              v-model="this.selectedParcel[0].courierNum"
              label="운송장번호"
              :disable="user === 'cus'" />
          </div>
        </div>

        <div class="q-pa-md">
          
          <q-input 
            v-model="this.selectedParcel[0].memo"
            bottom-slots
            ref="note"
            label="고객메모"
            type="textarea"
            placeholder="배송물품 정보를 적어주세요. (의류/전자기기/가전)"
            :rows="2"
            :disable="user === 'cus'">

          </q-input>

          <q-checkbox
            v-model="this.selectedParcel[0].eatable"
            :true-value="0"
            :false-value="1"
            label="음식물 여부">
          </q-checkbox>
          <p>의약품을 포함한 음식물이 있을 경우 음식물의 유/무를 표기해 주세요.</p>
        </div>

        <!-- 박스 크기  -->
        <div class="q-pa-md">
          <h6 class="q-mb-none q-mt-sm">box 크기</h6>
        </div>
        <div class="q-pa-md">
          <div class="form-column-wrap">
            <q-input bottom-slots v-model="dimension.width" label="가로 : ">
              <template v-slot:append>
                cm
              </template>
            </q-input>

            <q-input bottom-slots v-model="dimension.length" label="세로 : ">
              <template v-slot:append>
                cm
              </template>
            </q-input>

            <q-input bottom-slots v-model="dimension.height" label="높이 : ">
              <template v-slot:append>
                cm
              </template>
            </q-input>
          </div>
        
          <div class="form-column-wrap">
            <q-select 
              v-model="this.selectedParcel[0].freightMethod"
              :options="this.$store.getters.getFreightMethodList"
              :on-update:model-value="this.calculate(dimension)"
              behavior="menu"
              label="운송수단"
              emit-value
              map-options
              :disable="user === 'cus'" />
              
            <q-input 
              v-model="this.selectedParcel[0].weight" 
              bottom-slots 
              label="부피 무게 : "
              readonly
              :disable="user === 'cus'">
              <template v-slot:append>
                {{ this.selectedParcel[0].freightMethod == '1' ? 'CBM' : 'kg' }}
              </template>
            </q-input>
            
            <q-input 
              v-model="this.selectedParcel[0].actualWeight" 
              bottom-slots 
              label="실제 무게 : "
              :disable="user === 'cus'">
              <template v-slot:append>
                kg
              </template>
            </q-input>
          </div>
          <p>부피무게는 가로x세로x높이x(항공 또는 해상)의 값에 따라 자동으로 계산됩니다.</p>
        </div>



        <h5>3.배송상품 목적지정보</h5>
        <div class="q-pa-md">
          <div class="form-column-wrap">
            <q-input 
              v-model="this.selectedParcel[0].receiver"
              label="수신자명"
              :disable="user === 'cus'" />
            
            <q-select 
              v-model="this.selectedParcel[0].mobileCountry" 
              :options="this.$store.getters.getCountryCodeList"
              class="mobile-country"
              behavior="menu"
              label="모바일 - 국가"
              emit-value
              map-options
              :disable="user === 'cus'" />
    
            <q-input 
              v-model="this.selectedParcel[0].mobileNum"
              label="모바일 - 번호"
              :disable="user === 'cus'" />

          </div>

          <div class="form-column-wrap">

            <q-select 
              v-model="this.selectedParcel[0].destiCity" 
              :options="this.$store.getters.getCityListNew"
              behavior="menu"
              label="목적지도시"
              emit-value
              map-options
              :disable="user === 'cus'" />

            <q-select 
              v-model="this.selectedParcel[0].pickUpLocation" 
              :options="this.$store.getters.getPickupLocationListNew"
              behavior="menu"
              label="픽업지"
              emit-value
              map-options
              :disable="user === 'cus'" />

          </div>

          <div 
            v-if="this.selectedParcel[0].pickUpLocation === 'AONEPI03'" 
            class="form-column-wrap">
            
            <q-input
              v-model="this.selectedParcel[0].deliveryAddress"
              label="배송요청주소"
              filled
              type="textarea"
              :disable="user === 'cus'" />

          </div>
        </div>
        


        <h5>4.배송상품 상태정보</h5>
        <div class="q-pa-md">
          <div class="form-column-wrap">
            <q-select 
              v-model="this.selectedParcel[0].parcelLocation"
              :options="this.$store.getters.getParcelLocationListNew"
              behavior="menu"
              label="배송상품 위치"
              emit-value
              map-options
              :disable="user === 'cus'" />
          </div>
        </div>

      </q-page>
    </q-page-container>

    <q-footer 
      class="bg-white text-black">
      <q-toolbar
        class="no-gutter">
        <q-btn
          v-if="user === 'admin'"
          label="수정"
          class="btn full-width"
          size="lg"
          color="secondary"
          @click="modifyParcel">
        </q-btn>
        <q-btn
          label="확인"
          class="btn full-width"
          size="lg"
          color="primary"
          v-close-popup
          @click="closeModalThis">
        </q-btn>
      </q-toolbar>
    </q-footer>
  </q-layout>


  <ModalCustomerSearch
    :showModal="customerModal"
    @change-customer="changeCustomer"
    @close-modal-search="closeModalSearch" />

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h5 {
  margin-block-end: 0.5rem;
}

.btn {
  border-radius: 0px;
}
.no-gutter {
  padding : 0px;
}

.lg-width {
  width : 70vw;
  max-width: 1024px;
}

@media (min-width: 320px){
  .lg-width {
      width : 90vw;
      max-width: inherit;
  }
}

@media (min-width: 600px){
  .lg-width {
      width : 90vw;
      max-width: inherit;
  }
}

@media (min-width: 1024px){
  .lg-width {
      width : 70vw;
      max-width: 1024px;
  }
}

.form-column-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 30px;

  .q-field {
    flex: 1;
    flex-basis: 150px;
    // min-width: 150px;
  }

  .mobile-country {
    flex-basis: 120px;
    min-width: 110px;
    max-width: 120px;
  }
}
</style>