<script>
/**
 * Type         : Page component
 * Title        : 화물조회 페이지
 * Description  : 고객 화물조회 페이지
 * 
 */

import ParcelLookupForm from "@/components/customer/ParcelLookupForm.vue"
import ParcelList from "@/components/customer/ParcelList.vue"

export default {
  name: "Page-customer-parcel-inquiry",
  meta: {
    title: "고객-배송상품조회"
  },
  components: {
    ParcelLookupForm,
    ParcelList,
  },
  /*
  setup(){
  },
  */
  data() {
    return {
      navCurrent      : 'parcel-info',
    }
  },
  methods: {
    /**
     * Title        : 화물조회
     * Status       : Actived
     * Description  : 화물조회
     */
    async loadParcelList(res) {

      this.$log.debug("[ Axios / 고객 배송상품조회 ] res = ", res)
      
      let payload = {
        tabCode     : res.tabCode,
        mobile      : res.mobileProvider + res.mobileNum,
        email       : res.email,
        courierCode : res.courierCode,
        courierNum  : res.courierNum,
      }
      this.$log.debug("[ Axios / 고객 배송상품조회 ] payload = ", payload)

      let config = {
        method  : "post",
        url     : "/api/parcel",
        data    : payload,
        name    : "[ Axios / 고객 배송상품조회 ]"
      }

      await this.$http(config)
        .then(res => {
          const parcelList = res.data
          // Response data 를 data 에 넣는다.
          this.$store.dispatch('loadParcel', res.data)

          if(parcelList?.length === 0)
            {alert('조회된 배송상품이 없습니다.')}
        })
        .catch((err) => {
          this.$log.debug("[ Axios / 고객 배송상품조회 / 실패 ]", err)
          alert('배송 상품 조회에 실패 했습니다.')
      })
    },

    /**
     * Title        : 화물조회
     * Status       : Actived
     * Description  : 화물조회
     */
    async loginedLoadParcelList(cid) {
      // 조회 조건 검토
      let payload = {
        "id": cid,
      }

      let config = {
        method  : "post",
        url     : "/api/parcel/byCustomer",
        data    : payload,
        name    : "[ Axios / 로그인된 고객 배송조회 ]"
      }

      await this.$http(config)
        .then(res => {
          const parcelList = res.data

          // Response data 를 data 에 넣는다.          
          this.$store.dispatch('loadParcel', res.data)

          if(this.$store.getters.getSessionStatus && parcelList.length === 0)
            alert('고객님은 아직 등록하신 배송상품이 없습니다.')

        })
        .catch((err) => {
          this.$log.debug("/api/parcel/byCustomer", err)
          alert('배송 상품 조회에 실패 했습니다.')
        })
    },

    /**
     * Title        : 검증
     * Status       : Actived
     * Description  : 
     */
    validator(data) {
      let templength = data.mobile + data.email + data.courierCode.value + data.courierNum
      this.$log.debug("Validator executed templength = ", templength)

      return (templength === "")? false:true;
    },
  },
  mounted() {
    this.$log.debug("[ ParcelInfo.vue / Mounted ]")

    // vuex store 에서 로그인상태 조회 후 배송상품 조회
    const sess = JSON.parse(sessionStorage.getItem('vuexStore'))
      
    this.$log.debug("checkSession sess = ", sess)

    let customerId
    if(sess !== undefined && sess !== null){
      customerId = sess.user.localSession.id
      this.$log.debug("checkSession sessData = ", customerId)
      this.$log.debug("로그인 상태임")
      this.loginedLoadParcelList(customerId)

    }
    
  },
}
</script>

<template>

  <main id="site-container">

    <div class="section section-bg bg-site">
      <div class="container doc-page">
        <div class="row items-center">
          <div class="col">
            <h2 class="page-title text-center text-white">
              배송상품조회
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container doc-page q-pb-xl">
      <div class="row q-mb-lg">
        <div class="col">
          <h4>고객님의 배송상품을 검색해보세요.</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <div class="comp-box">
            <ParcelLookupForm 
              @load-parcel-list="loadParcelList"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container doc-page q-pb-xl"
      v-if="this.$store.getters.getParcelList.length > 0" >
      <div class="row">
        <div class="col q-mt-md">
          <div class="comp-box">
            <ParcelList />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
