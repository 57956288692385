<template>
	<div id="calculator">
		<div class="row">
				<div class="col col-sm-6 q-pr-sm q-pl-sm q-mt-md q-mb-xl">
					<div class="cal-section-title q-mb-lg">
							box 크기
					</div>
					<div class="q-mb-xs">
						<q-input bottom-slots v-model="calculator.width" label="가로 : ">
							<template v-slot:append>
								cm
							</template>
						</q-input>
					</div>
					<div class="q-mb-xs">
						<q-input bottom-slots v-model="calculator.depth" label="세로 : ">
							<template v-slot:append>
								cm
							</template>
						</q-input>
					</div>
					<div class="q-mb-xs">
						<q-input bottom-slots v-model="calculator.height" label="높이 : ">
							<template v-slot:append>
								cm
							</template>
						</q-input>
					</div>
				</div>
				<div class="col-6 col-sm-6 q-pr-sm q-pl-sm q-mt-md q-mb-xl">
						<div class="cal-section-title q-mb-lg">
							예상무게
						</div>
						<div class="q-mb-xs">
							<q-input bottom-slots v-model="flightWeight" label="항공 무게 : " readonly>
								<template v-slot:append>
									kg
								</template>
							</q-input>
						</div>

						<div class="q-mb-xs">
							<q-input bottom-slots v-model="oceanWeight" label="해상 무게 : " readonly>
								<template v-slot:append>
									CBM
								</template>
							</q-input>
						</div>
						<!--
						<div class="input-group q-mb-md">
								<span class="input-group-text">호주배송 무게 : </span>
								<input type="number" class="form-control">
								<span class="input-group-text">kg</span>
						</div>
						-->
				</div>

				
				<!--
			<div class="col-4 g-5 text-center mt-3 mb-5">
					<div class="cal-section-title mb-4">
							예상비용(AUS)
					</div>
					<div class="input-group q-mb-md">
							<span class="input-group-text">$ </span>
							<input type="number" class="form-control">
					</div>
					<div class="input-group q-mb-md">
							<span class="input-group-text">$ </span>
							<input type="number" class="form-control">
					</div>
					<div class="input-group q-mb-md">
							<span class="input-group-text">$ </span>
							<input type="number" class="form-control">
					</div>
			</div>
			-->
		</div>
		<div class="row">
			<!--
			<div class="col-12 d-flex justify-content-center q-mb-md">
					<button class="btn btn-in-main">운임 견적 받기</button>
			</div>
			-->
			<div class="col-12 d-flex justify-content-center">
				<p class="info text-center">
					** 항공부피무게 (가로*세로*높이/6000) (단위 cm)<br>
					** 해상부피측정 (가로*세로*높이) (단위 m)<br>
					항공의 경우 실제 무게 / 부피 무게 중 더 큰 쪽으로 청구됩니다.
				</p>
			</div>
		</div>
	</div>
</template>

<script>

export default {
  name: "Parcel-fee-calculator",
  components: {

  },
  watch:{
    calculator: {
			handler : function(val){

					this.$log.debug("[ Calculator / Watch ] val = ", val)
				
					let data = Object.values(val)

					this.$log.debug("[ Calculator / Watch ] data = ", data)

					let result = data.reduce((acc, cur)=>{
							return acc * cur
					})

					this.$log.debug("flightWeight = " + (result/6000))
					this.$log.debug("oceanWeight = " + (result/1000000))

					const flight = 6000
					const ocean = 1000000
					
					let decimalLength = 100

					let calcualtion = function(result, ratio){
						return Math.round((result/ratio) * decimalLength) / decimalLength
					}

					this.flightWeight = calcualtion(result, flight)
					this.oceanWeight = calcualtion(result, ocean)
			},
			deep:true
    }
  },
  computed:{
    // parcels(){
    //   return this.$store.state.newParcelList
    // }
  },
  data() {
    return {
        calculator : {
            width       : 0,
            height      : 0,
            depth       : 0,
        },
        flightWeight    : 0,
        oceanWeight     : 0
    }
  },
  emits: [
    "delete-from-list",
    "modal-modify"
  ],
  methods:{
    // 등록할 parcel 목록에서 지우기
    removeParcel(id){
      this.$emit("delete-from-list", id)
    },
    // 수정 모달 띄우기
    modalModify(id){
      this.$log.debug("Click for edit with parcel id = " + id)
      this.$emit("modal-modify", id)
    },
  },
  /*
  mounted(){
    this.parcels = this.$store.state.newParcelList
  }
  */
}
</script>
