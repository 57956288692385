<template>
  <q-page>
    <div 
      class="container doc-page"
      id="login-page">
  
      <div 
        class="row"
        style="height:100%;">
        <div class="col self-center flex-wrap">
          <form class="form-signin-wrapper">
            <div class="form-signin">
              <h5 class="q-mb-lg">로그인</h5>

              <q-input 
                v-model="email" 
                label="Email"/>
              
              <q-input
                v-model="password"
                type="password"
                class="q-mb-lg"
                @keyup.enter="signIn"
                label="Password"/>

              <q-btn
                color="secondary"
                @click="signIn">
                로그인
              </q-btn>
    
              <q-btn
                label="회원가입"
                href="#"
                @click="this.$router.push('/signUp')"
                class="q-ml-sm" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>

export default {
  name: "SignInBox",
  components: {
    
  },
  setup(){
    
  },
  data(){
    return {
      email : "",
      password : "",
    }
  },
  methods: {
    async signIn (){
      let payload = {
        "email" : this.email,      // 고객번호
        "pwd" : this.password,     // 비밀번호
      }

      let config = {
        method : "post",
        url : "/api/cust/signIn",
        data : payload
      }

      this.$log.debug("/cust/signIn 실행됨")

      await this.$http(config)
        .then(res => {
          
          if(res.status === 200){

            this.$log.debug("/cust/signIn 성공", res)

            // 로그인 성공 후 넘어온 고객의 정보를 Vue storage에 넣는다.
            this.$store.dispatch('setCustomerInfo', res.data)

            this.$router.push('/')

          } else {
            // 로그인에 실패한 경우
            alert(res.response.data)

          }
        })
        .catch(res => {
          this.$log.debug("/customer/signIn 실패함 catch", res)
          alert("알수 없는 오류가 발생했습니다.")
          return false
        })
    }
  },
  mounted(){
    if(this.$store.getters.getSessionStatus) this.$router.push('/')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
