<script>
import Title from "@/components/admin/common/TitleArea.vue"

import Scheduler from "@/components/admin/Scheduler.vue"


export default {
  name: "Admin-schedule-management",
  meta: {
    title: "어드민-픽업지관리"
  },
  components: {
    Title,
    Scheduler,
  },
  data() {
    return {
      description   : {
        title : '일정관리'
      },
    }
  },
  method: {
  }
}
</script>


<template>
  <q-page class="q-pa-lg">
    <div class="row">
      <div class="col">
        <Title
          :desc="description">

        </Title>  
      </div>
    </div>

    <div class="row q-mt-lg">
      <div class="col">
        <div class="comp-box">
          <Scheduler>
          </Scheduler>
        </div>
      </div>
    </div>
  </q-page>

</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
