<script>
/**
 * Type        : Business component
 * Title       : 배송상품 목록
 * Description : 배송상품 목록을 보여주는 컴포넌트
 * 
 */

import { ref } from 'vue'
import ModalParcelModiForm from "@/components/common/modal/ParcelInfoForm.vue"
import PdfPreviewer from '@/components/admin/modal/PdfPreview.vue'
import ExportJsonCsv from 'vue-json-csv'

export default {
  name: "ParcelList",
  components: {
    ModalParcelModiForm,
    PdfPreviewer,
    ExportJsonCsv
  },
  props: {
    isDel : String,
  },
  computed: {
    parcelList() {
      return this.$store.getters.getParcelList
    },
    parcelLocationList(){
      return this.$store.getters.getParcelLocationListImport
    },
  },
  emits: [
    "reload-parcel-list",
    "load-more-list",
  ],
  data() {
    return {
      modifyModalShow   : false,
      pdfModalShow      : false,
      paging            : 0,
      selectedList      : ref([]),  // 선택된 parcel을 저장
      columns : [
        { 
          name: 'rownum', 
          label: 'ID', 
          align: 'center', 
          field: 'id', 
          format: val => `${val}`,
          sortable: true
        },
        { name: 'freightMethod', label: '운송수단', align: 'center', field: 'freightMethod', format: val => `${(val == 1? '해상' : '항공')}` },
        { name: 'customerName', label: '회원명', align: 'center', field: 'customerName' },
        { name: 'bulkSeq', label: '묶음배송번호', align: 'center', field: 'bulkSeq' },
        { name: 'bulkCnt', label: '묶음배송갯수', align: 'center', field: 'bulkCnt' },
        { name: 'courierName', label: '택배사', align: 'center', field: 'courierName' },
        { name: 'courierNum', label: '송장번호', align: 'center', field: 'courierNum' },
        { name: 'eatable', label: '음식물여부', align: 'center', field: 'eatable', format: val => `${(val == 0? 'Y' : 'N')}` },
        { name: 'actualWeight', label: '실무게(kg)', align: 'center', field: 'actualWeight' },
        { name: 'weight', label: '부피무게(kg/CBM)', align: 'center', field: 'weight' },
        { name: 'receiver', label: '수취인', align: 'center', field: 'receiver' },
        { name: 'mobileCountry', label: '수취인 국가코드', align: 'center', field: 'mobileCountry' },
        { name: 'mobileNum', label: '수취인 전화번호', align: 'center', field: 'mobileNum' },
        { name: 'pickupLocation', label: '픽업지', align: 'center', field: 'pickUpLocationName', sortable: true },
        { name: 'parcelLocatonName', label: '위치', align: 'center', field: 'parcelLocationName', sortable: true },
        { name: 'regDate', label: '등록일', align: 'center', field: 'regDate' },

        // 삭제여부 컬럼 숨김
        // { name: 'isDel', label: '삭제여부', align: 'center', field: 'isDel' },
      ],
      
      initialPagination : {
        rowsPerPage: 30
        // rowsNumber: xx if getting data from a server
      },

      csvName: 'backup-data-' + new Date().toLocaleDateString() + '.csv',
      csvFields: [ //csv 파일에 입력시킬 필드
        'id',
        'freightMethod',
        'customerName',
        'bulkSeq',
        'bulkCnt',
        'courierName',
        'courierNum',
        'eatable',
        'actualWeight',
        'weight',
        'receiver',
        'mobileCountry',
        'mobileNum',
        'pickupLocation',
        'parcelLocatonName',
        'regDate',
      ],
      csvHeaders:{ //헤더 컬럼 필드 한글 매핑
        id                : 'ID',
        freightMethod     : '운송수단',
        customerNum       : '회원번호',
        customerName      : '회원명',
        bulkShipping      : '묶음배송여부',
        bulkSeq           : '묶음배송번호',
        bulkCnt           : '묶음배송갯수',
        courierCode       : '택배사코드',
        courierName       : '택배사',
        courierNum        : '송장번호',
        parcelStatus      : '화물상태',
        parcelLocation    : '화물위치코드',
        parcelLocationName: '화물위치명',
        parcelCode        : '화물코드',
        destiCity         : '도착지(도시)',
        pickUpLocation    : '픽업위치코드',
        pickUpLocationName: '픽업위치명',
        deliveryAddress   : '도착지주소',
        email             : '수취인 이메일',
        eatable           : '음식물여부',
        length            : '길이(cm)',
        height            : '높이(cm)',
        width             : '넓이(cm)',
        memo              : '메모',
        isDel             : '삭제여부',
        actualWeight      : '실무게(kg)',
        weight            : '부피무게(kg/CBM)',
        receiver          : '수취인',
        mobileCountry     : '수취인 국가코드',
        mobileNum         : '수취인 전화번호',
        pickupLocation    : '픽업지',
        parcelLocatonName : '위치',
        regDateOriginal   : '등록일전체',
        regDate           : '등록일',
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: '운송수단', value: 'freightMethod' },
        { text: '회원명', value: 'customerName' },
        { text: '묶음배송번호', value: 'bulkSeq' },
        { text: '묶음배송갯수', value: 'bulkCnt' },
        { text: '택배사', value: 'courierName' },
        { text: '송장번호', value: 'courierNum' },
        { text: '음식물여부', value: 'eatable' },
        { text: '실무게(kg)', value: 'actualWeight' },
        { text: '부피무게(kg/CBM)', value: 'weight' },
        { text: '수취인', value: 'receiver' },
        { text: '수취인 국가코드', value: 'mobileCountry' },
        { text: '수취인 전화번호', value: 'mobileNum' },
        { text: '픽업지', value: 'pickupLocation' },
        { text: '위치', value: 'parcelLocatonName' },
        { text: '등록일', value: 'regDate' },
      ],

      userType: 'admin'
    }
  },
  methods:{
    /**
     * Title        : 일괄삭제
     * Status       : Actived
     * Description  : 선택된 복수의 택배 삭제를 한다.
     *                삭제시 확인 얼럿을 띄우고 이후 삭제를 실행한다.
     *                선택된 length가 0일 경우. 실패
     */
    bulkDelete () {
      let idArr = this.selectedList

      // 삭제 데이터 없을 경우 
      if(idArr.legnth == 0 || idArr === undefined || idArr === "undefined"){
        alert("삭제된 데이터가 없습니다.")
      }

      if(confirm('삭제하시겠습니까?\n삭제 이후에는 삭제목록으로 관리할 수 있습니다.')){
        idArr = this.selectedList.map(item => {
          return item.id
        })
  
        this.$log.debug("[ 일괄삭제 실행 / idArr ] = ", idArr)
  
        let payload = {
          id : idArr
        }
  
        this.delete(payload, '1')
      }
    },
    
    /**
     * Title        : 일괄복구
     * Status       : Actived
     * Description  : 삭제된 배송상품 선택하여 복구
     */
    reactivate () {
      let idArr = this.selectedList.map(item => {
        return item.id
      })

      this.$log.debug("[ 일괄복구 실행 / idArr ] = ", idArr)

      let payload = {
        id : idArr
      }

      this.delete(payload, '0')
    },

    /**
     * Title        : 삭제/복구 실행 메소드
     * Status       : Actived
     * Description  : 선택된 화물 삭제/복구 실행한다.
     */
    async delete (payload, type) {

      let newPayload = Object.assign( payload, { isDel : type }); 

      let config = {
        method  : "post",
        url     : "/api/parcel/delete",
        data    : newPayload,
        name    : "[ Axios / ParcelList / 배송상품 삭제 실행 ]"
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("삭제했습니다.")

          this.$emit('reload-parcel-list')

          alert(this.isDel == '0' ? "삭제되었습니다" : "복구되었습니다")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })
    },

    /**
     * Title        : 일괄위치변경
     * Status       : Actived
     * Description  : 선택된 화물의 위치 일괄변경
     */
    async bulkChangeStatus(status){
      
      let idArr = this.selectedList.map(item => {
        return item.id
      })
      
      this.$log.debug(status)
      this.$log.debug("idArr = ", idArr)

      
      let payload = {
        id      : idArr, 
        parcelLocation : status
      }

      let config = {
        method  : "post",
        url     : "/api/parcel/modifyParcelLocation",
        data    : payload,
        name    : "[ Axios / ParcelList / 배송상품 위치 일괄 변경 ]"
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("위치정보를 변경했습니다.")
          this.selectedList = []

          this.$emit('reload-parcel-list')

          alert("위치정보를 변경했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })

    },

    // Pdf View Modal
    pdfModalControl(){
      if(this.selectedList.length === 0){
        alert("선택된 화물이 없습니다.")
        return false
      }
      this.pdfModalShow = !this.pdfModalShow
    },

    //
    closePdfPreview(){
      this.pdfModalShow = !this.pdfModalShow
    },

    reloadParcelList(){
      this.$emit('reload-parcel-list')
    },

    // 모달 닫기
    closeModal(){
      this.modifyModalShow = false
    },

    // 더 불러오기
    async lazyLoad () {
      this.paging++
      this.$log.debug("lazyLoad = " + this.paging)
      this.$emit('load-more-list', this.paging)
    },

    /**
     * Title        : 배송상품 수정
     * Status       : Actived
     * Description  : 선택된 화물의 정보를 변경하기 위한 모달 호출
     */
    modalModifyParcel() {
      // this.$log.debug(id)
      this.modifyModalShow = true
    },

  }
}
</script>


<template>

  <div class="row q-mb-sm">
    <div class="col">
      <template
        v-if="isDel == '0'">
        <!-- 버튼 - 위치일괄변경 -->
        <q-btn-dropdown 
          color="primary" 
          class="q-mr-sm q-mb-sm"
          label="위치일괄변경">
          <q-list>
            <q-item v-for="(loc, index) in parcelLocationList" v-bind:key="index"
              clickable v-close-popup
              @click="bulkChangeStatus(loc.code)">
              <q-item-section>
                <q-item-label>{{ loc.name }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <!-- 버튼 - PDF 미리보기 -->
        <q-btn
          color="red"
          label="PDF 미리보기"
          class="q-mr-sm q-mb-sm"
          @click="pdfModalControl">
        </q-btn>

        <!-- 버튼 - 수정 -->
        <q-btn
          color="secondary"
          label="수정"
          class="q-mr-sm q-mb-sm"
          @click="modalModifyParcel"
          :disable="selectedList.length != 1">
        </q-btn>



        <!--<ExportJsonCsv
          :data="parcelList" 
          :fields="csvFields" 
          :labels="csvHeaders"
          :name="csvName">-->

        <ExportJsonCsv
          style="display: inline-block"
          :data="parcelList" 
          :labels="csvHeaders"
          :name="csvName">

          <!-- 버튼 - 조회된 데이터 백업 -->
          <q-btn
            color="cyan-7"
            label="조회된 데이터 백업"
            icon="download"
            class="q-mr-sm q-mb-sm">
          </q-btn>

        </ExportJsonCsv>
      </template>

    </div>
    <div class="col text-right">
      <!-- 버튼 - 복구버튼 -->
      
      <q-btn
        v-if="isDel == '1'"
        color="orange"
        label="복구"
        class="q-ml-sm"
        @click="reactivate">
      </q-btn>


      <!-- 버튼 - 삭제버튼 -->
      <q-btn
        v-if="isDel == '0'"
        color="red"
        label="삭제"
        class="q-ml-sm"
        icon="delete"
        @click="bulkDelete">
      </q-btn>
    </div>
  </div>

  <div class="row q-mb-sm">
    <div class="col">

      <q-table
        class="my-sticky-virtscroll-table"
        virtual-scroll
        v-model:selected="selectedList"
        selection="multiple"
        :rows-per-page-options="[0]"
        :virtual-scroll-sticky-size-start="48"
        :rows="parcelList"
        :columns="columns"
        :pagination="initialPagination"
        row-key="id">

      </q-table>
    </div>
  </div>

  <div class="row q-mb-sm">
    <div class="col">
      <q-btn
        color="primary"
        size="md"
        label="더 불러오기"
        @click="lazyLoad"/>
    </div>
  </div>


  <teleport to="body">
    <q-dialog 
      v-model="modifyModalShow">

      <ModalParcelModiForm
        :selectedParcel="selectedList"
        :user="userType"
        @reload="reloadParcelList"
        />

    </q-dialog>
  </teleport>

  <PdfPreviewer
    :showModal="pdfModalShow"
    :parcelList="selectedList"
    @close-modal="closePdfPreview"/>

</template>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
