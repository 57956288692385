<template>
	<footer>
		<div class="container doc-page">
			<div class="row">
				<div class="col q-mt-xl">
					<img 
						id="site-logo__footer"
						:src="require(`@/assets/imgs/common/logo-white.png`)">
				</div>
				<div class="col">
					<h4>
						AONE LOGISTICS PTY LTD<br>
						(에이원택배)
					</h4>
					<dl>
							<dt><strong>NSW</strong></dt>
							<dd class="q-mb-md">K10 / 161 ARTHUR ST HOMEBUSH WEST NSW 2140</dd>
							<dt><strong>KOREA</strong></dt>
							<dd>서울시 강서구 양천로 400-12 더리브골드타워 1002호</dd>
					</dl>

					<h4>
							CUSTOMER SERVICE
					</h4>
					<dl>
						<dt><strong>NSW</strong></dt>
						<dd class="q-mb-md">
							MON TO FRI 09:00 – 17:00 (AEST)<br />
							PHONE : 02-8515-7070<br />
							E-MAIL : cs@aonelogistics.com.au<br />
							카카오톡 : aonesyd
						</dd>
						<dt><strong>KOREA</strong></dt>
						<dd>
							MON TO FRI 09:00 – 18:00(KST)<br />
							PHONE : 향후 추가 예정<br />
							E-MAIL : 향후 추가 예정<br />
							카카오톡 : 향후 추가 예정<br />
						</dd>
					</dl>
				</div>
				<div class="col menu">
					<h4>
							MENU
					</h4>
					<dl>
						<dt><strong>배송조회.신청</strong></dt>
						<dd>KOR→SYD | SYD→KOR</dd>
						<dt class="q-mt-md"><strong>운임정보</strong></dt>
						<dd>KOR→SYD | SYD→KOR</dd>
						<dt class="q-mt-md"><strong>서비스</strong></dt>
						<dd>항공 | 해상</dd>
						<dd>전자상거래 컨설팅</dd>
						<dd>풀필먼트 서비스</dd>
						<dd>상업화물 서비스 | 창고보관</dd>
						<dt class="q-mt-md"><strong>공지사항</strong></dt>
						<dt class="q-mt-md"><strong>회사소개</strong></dt>
					</dl>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>


export default {
	name: "FooterComponent",
	data() {
		return {
			parcelList : [
				{company : "롯데택배", parcelNumberByCompany: "12098749823", customerMemo : "고객메모가 적어지는 영역입니다.", regDate : "2022-5-11", status : "배송완료" },
				{company : "CJ", parcelNumberByCompany: "IBNFRI27389198", customerMemo : "고객메모가 적어지는 영역입니다.", regDate : "2022-7-22", status : "택배사배송중" },
				{company : "대한통운", parcelNumberByCompany: "FHE471043", customerMemo : "고객메모가 적어지는 영역입니다.", regDate : "2022-9-21", status : "한국집하장" },
				{company : "대한통운", parcelNumberByCompany: "FHE471123124", customerMemo : "고객메모가 적어지는 영역입니다.", regDate : "2022-9-20", status : "호주집하장" },
			]
		}
	},
	method:{

	},
	components: {
},

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
