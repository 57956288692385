<script>
/**
 * Type         : Modal component
 * Title        : 화물 기본정보 입력 컴포넌트
 * Description  : 화물 기본정보를 입력하기 위한 컴포넌트
 * 
 * Input
 * courierCode : 택배사 코드
 * courierName : 택배사 명
 * number      : 운송장번호
 * note        : 메모
 */

export default {
  name: "Modal-parcel-registration-form",
  props: {
    showModal : Boolean,
    mode      : Number,
    idx       : Number,
  },
  watch:{
    showModal:{
      handler(newVal) {
        // 상품 추가 모드/상품 수정 모드 여부 판단
        const mode  = this.mode
        const idx   = this.idx

        this.$log.debug("Created parcel id = " + idx )
        if(mode === 0){
          // 상품추가모드
          this.$log.debug("상품추가모드")

          this.courierCode  = ""
          this.courierName  = ""
          this.number       = ""
          this.note         = ""
          this.eatable      = 1
        } else if(mode === 1){
          // 상품수정모드
          this.$log.debug("상품수정모드")
          this.setDataInput(idx)
        }
      },
      immediate : true,
      deep      : true,
    },
  },
  emits:[
    "close-modal",
    "add-item",
    "modify-item"
  ],
  data() {
    return {
      courierCode : "",
      courierName : "",
      number      : "",
      note        : "",
      eatable     : 1,
    }
  },
  methods : {
    /**
     * Title        : 신규배송상품목록에 추가
     * Status       : Actived
     * Description  : 추가할 배송상품목록에 모달 내 작성된 상품을 추가한다.
     */
    addItem(){

      // 빈 항목이 있는지 확인
      let checkTarget = {
        courierCode : this.courierCode,
        courierName : this.courierCode !== "" ? this.setName(this.courierCode): "",
        number      : this.number,
        note        : this.note,
        eatable     : this.eatable,
      }
      this.$log.debug("checkTarget = ", checkTarget)
      let result = this.$checkEmptyVModelList(checkTarget)

      if(result.length > 0){
          alert("전체항목을 입력하세요.")
          return false
      }

      const newData = {
        courierCode : this.courierCode,
        courierName : this.setName(this.courierCode),
        number      : this.number,
        note        : this.note,
        eatable     : this.eatable,
      }
      this.$emit("add-item", newData)
      this.$emit("close-modal")
    },

    /**
     * Title        : 배송상품수정
     * Status       : Actived
     * Description  : 추가할 배송상품목록에 선택된 배송상품의 정보를 수정한다.
     */
    modifyItem(){
      this.$log.debug("modifyItem executed")

      const modifyData = {
        courierCode : this.courierCode,
        courierName : this.courierName,
        number      : this.number,
        note        : this.note,
        eatable     : this.eatable,
      }
      this.$log.debug("modifyItem data = ", modifyData)
      this.$log.debug("modifyItem idx = ", this.idx)
      this.$emit("modify-item", {data : modifyData, idx: this.idx})
      this.$emit("close-modal")
    },

    closeModal(){
      this.$emit("close-modal")
    },

    /**
     * Title        : 배송상품 기본정보 Set
     * Status       : Actived
     * Description  : 선택된 배송상품의 기본정보를 모달 input에 뿌린다
     * Param (idx)  : 선택된 배송상품의 idx
     */
    setDataInput(idx){
      let target = this.$store.getters.getNewList[idx]
      this.$log.debug("idx = ", idx)
      this.$log.debug("target = ", target)

      this.courierCode  = target.courierCode,
      this.courierName  = target.courierName,
      this.number       = target.number,
      this.note         = target.note
    },

    /**
     * Title        : 배송업체명 셋팅
     * Status       : Actived
     * Description  : 배송업체코드로 배송업체 명을 추출한다.
     * Param (code) : 배송업체코드
     * Return (courier) : 배송업체명
     */
    setName(code){
      // this.$log.debug("code = " + code)

      let courierList = this.$store.getters.getCourierList
      // this.$log.debug("courierList = ", courierList)
      
      let temp = courierList.filter(courier => {
        
        if(courier.value == code){
          return courier
        }
      })

      this.$log.debug("temp = ", temp)

      return temp[0].label
    }

  },
  mounted(){
    this.$getCourierList()
  }
}
</script>


<template>
  <teleport to="body">

    <q-dialog 
      v-model="showModal">

      <q-card
        class="modal-regi-card">
        <q-card-section>
          <div class="text-h6">
            배송상품추가
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div 
            class="row q-mb-sm"
            style="gap: 20px;">
            <div class="col">

              <q-select 
                v-model="courierCode" 
                ref="courier"
                :options="this.$store.getters.getCourierList"
                behavior="menu"
                emit-value
                map-options
                label="배송업체" />

            </div>
            <div class="col">

              <q-input 
                v-model="number"
                ref="number"
                label="운송장번호"
                placeholder="배송물품의 송장번호를 적어주세요." />    

            </div>
          </div>
          <div class="row">
            <div class="col">

              <q-input 
                v-model="note"
                ref="note"
                label="메모"
                type="textarea"
                :rows="3"
                placeholder="배송물품 정보를 적어주세요. (의류/전자기기/가전)" />

              <q-checkbox
                v-model="eatable"
                :true-value="0"
                :false-value="1"
                label="음식물 여부">
              </q-checkbox>

            </div>
          </div>
        </q-card-section>

        <q-card-actions 
          align="right"
          class="bg-white text-teal"
          style="margin-top:20px;">

          <q-btn
            label="닫기"
            flat
            v-close-popup
            @click="closeModal()">
          </q-btn>
         
          <q-btn
            v-if="mode === 1"
            label="수정"
            flat
            @click="modifyItem">
          </q-btn>
          
          <q-space />

          <q-btn
            v-if="mode === 0"
            label="추가"
            flat
            @click="addItem">
          </q-btn>

        </q-card-actions>
      </q-card>

    </q-dialog>

  </teleport>
</template>

<style scoped lang="scss">
.modal-regi-card {
  min-width:400px;
  width: 30%;
  max-width: 80vw;
}

</style>