<script>
// import axios from 'axios'

import CourierRegForm from "@/components/admin/modal/CourierRegiFormUnit.vue"

export default {
  name: "Courier-list",
  components:{
    CourierRegForm,
  },
  data() {
    return {
      courierList: [],
      showModalNow : false,
      mode : 0,
      courierCode : "0",
      initialPagination : {
        rowsPerPage: 10
        // rowsNumber: xx if getting data from a server
      },
      columns : [
        { name: 'courierCode', label: '택배사코드', align: 'center', field: 'courierCode' },
        { name: 'courierName', label: '택배사', align: 'center', field: 'courierName' },
        { name: 'country', label: '국가', align: 'center', field: 'country' },
        { name: 'regDate', label: '등록일', align: 'center', field: 'regDate' },
        { name: 'detail', label: '기능선택', align: 'center', field: 'detail' },
      ],
    }
  },
  methods:{
    // Modal show for more parcel
    addCourierForm(){
      this.showModalNow = !this.showModalNow
      // Modal mode for add parcel
      this.mode = 0
      this.courierCode = null
    },
    // 모달 닫기
    closeModal(){
      this.showModalNow = false
    },
    async regiCourier(payload){
      let config = {
        method : "post",
        url : "/api/courier/addCourier",
        data : payload
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug("/admin/addCourier 실행됨", res)
          this.loadCourierList()
        }).catch(res => {
          this.$log.debug("/admin/addCourier 실패", res)
        })
    },
    singleDelete (courierId) {
      this.$log.debug("개별삭제 실행")
      this.$log.debug("선택된 택배 Id", courierId)

      let payload = {
        "courierCode" : courierId
      }

      this.$log.debug("payload = ", payload)

      this.delete(payload)
    },
    async delete (payload) {

      let config = {
        method : "post",
        url : "/api/courier/delCourier",
        data : payload
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("삭제했습니다.")

          this.loadCourierList()

          alert("삭제했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })
    },
    async loadCourierList() {

      let result = []

      let config = {
        method : "get",
        url : "/api/courier/getCourierList",
      }

      result = await this.$http(config)
        .then(res => {
          let tempRtn = res.data
          this.$log.debug("/parcel/getCourierList 실행됨", tempRtn)
          return tempRtn
        }).catch(res => {
          this.$log.debug("/parcel/getCourierList 실패", res)
        })

      this.courierList = result
    },
  },
  mounted() {
    this.$log.debug("Courier list mounted")
    this.loadCourierList()
  },
}
</script>

<template>

  <q-table
    :rows="courierList"
    :columns="columns"
    :rows-per-page-options="[0]"
    :virtual-scroll-sticky-size-start="48"
    :pagination="initialPagination"
    row-key="name">

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="courierCode" :props="props">
          {{ props.row.courierCode }}
        </q-td>
        <q-td key="courierName" :props="props">
          {{ props.row.courierName }}
        </q-td>
        <q-td key="country" :props="props">
          {{ props.row.country }}
        </q-td>
        <q-td key="regDate" :props="props">
          {{ props.row.regDate }}
        </q-td>
        <q-td key="detail" :props="props">
          <q-btn-dropdown 
            color="primary" 
            label="기능선택"
            size="sm">
            <q-list>
              <q-item
                clickable v-close-popup
                @click="singleDelete(props.row.courierCode)">
                <q-item-section>
                  <q-item-label>삭제</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </q-td>
      </q-tr>
    </template>
  </q-table>

  <div class="row q-mt-sm">
    <div class="col">
      <q-btn
        color="primary"
        size="md"
        label="택배사 추가"
        @click="addCourierForm"/>
    </div>
  </div>

  <CourierRegForm
    :showModal="showModalNow"
    v-bind:mode="mode"
    v-bind:courierCode="courierCode"
    @close-modal="closeModal"
    @add-item="regiCourier"
  />

</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
