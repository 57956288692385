<script>
import ModalViewNotice from "@/components/common/modal/ViewNotice.vue"
// import ModalNoticeRegi from "@/components/admin/modal/RegiNoticeForm.vue"

export default {
  name: "Notice-list",
  components: {
    ModalViewNotice,
  },
  props:{
    noticeList: Array,
    authLevel: Number,
    isHome : Boolean,
  },
  data() {
    return {
      noticeId            : [],
      paging              : 0,
      viewNoticeModalShow : false,
      editNoticeModal     : false,
      selectedNotice      : {},
      mode                : 0
    }
  },
  emits: [
    "load-more-list",
    "reload-parcel-list",
    "edit-modal",
  ],
  methods:{
    // 공지사항 상세보기
    viewContents (id){
      let temp = []
      this.viewNoticeModalShow = true
      temp = this.noticeList.filter((item) => { if(id === item.id) return item } )
      this.selectedNotice = temp[0]
      this.$log.debug("viewContents = ", this.selectedNotice)
    },
    // 공지사항 수정하기
    editContents (id){
      let temp = []
      this.mode = 1
      this.editNoticeModal = true
      temp = this.noticeList.filter((item) => { if(id === item.id) return item } )
      this.selectedNotice = temp[0]
      this.$emit('edit-modal', this.selectedNotice)
      // this.$log.debug("this.selectedNotice = ", this.selectedNotice)
    },
    // 모달 닫기
    closeModal(){
      this.viewNoticeModalShow = false
    },
    // 더 불러오기
    async lazyLoad () {
      this.paging++
      this.$log.debug("lazyLoad = " + this.paging)
      this.$emit('load-more-list', this.paging)

    },
    // 공지사항 수정 by Admin
    async modifyNotice(id) {
      this.$log.debug(id)
      this.modifyModalShow = true
      this.selectedNoticeId = id
    },
    // 공지사항 수정 by Admin
    async singleDelete(id) {

      let payload = {
        "id" : id
      }

      let config = {
        method : "post",
        url : "/api/board/delNotice",
        data : payload
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("삭제했습니다.")

          this.$emit('reload-parcel-list')

          alert("삭제했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })
    }
  }
}
</script>

<template>
  <q-markup-table>
    <thead>
      <tr>
        <th style="width:40px;" v-if="authLevel === 1"></th>
        <th style="width:40px;">번호</th>
        <th>제목</th>
        <th style="width:90px;">작성자</th>
        <th style="width:120px;">작성일</th>
        <th 
          v-if="authLevel === 1"
          style="width:100px;">기능</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in noticeList"
        :key="index"
        :value="item.id">
        <!---->
        <td 
          v-if="authLevel === 1"
          class="text-center">
          <input type="checkbox" class="form-check" v-model="noticeId" :value="item.id">
        </td>
        <td class="text-center">{{item.rownum}}</td>
        <td 
          class="text-left"
          @click="viewContents(item.id)">
          <q-icon v-if="item.isFixed == 0" name="push_pin" />
          {{item.title}}</td>
        <td class="text-center">관리자</td>
        <!-- <td class="text-center">{{item.writer}}</td> -->
        <td class="text-center">{{item.regDate}}</td>

        <td 
          v-if="authLevel === 1"
          class="text-center">

          <q-btn
            color="primary"
            :key="`btn_size_sq_sm`"
            size="sm"
            label="Action">
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item clickable v-close-popup>
                  <q-item-section
                    @click="editContents(item.id)">수정</q-item-section>
                </q-item>
                
                <q-item clickable v-close-popup>
                  <q-item-section
                    @click="singleDelete(item.id)">삭제</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>

        </td>
      </tr>
    </tbody>
  </q-markup-table>
  
  <!-- // TODO : 고객 홈에서는 더보기가 보여지지 않아야 함 -->
  <div 
    v-if="!isHome"
    aria-label="Page navigation" 
    class="q-mt-md text-center">
    <q-btn 
      color="primary" 
      @click="lazyLoad"
      label="더 불러오기">
    </q-btn>
  </div>

  <ModalViewNotice
    :show-modal="viewNoticeModalShow"
    :selected-notice="selectedNotice"
    @close-modal="closeModal"
  />

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
