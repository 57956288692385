<script>
/**
 * Type : Page
 * 실험실 화면
 * 실험적이거나 베타 테스트가 필요한 기능들을 선행해보는 실험실 페이지
 * 
 */

// import { test as base } from 'playwright/test';

import axios from 'axios'
import Title from "@/components/admin/common/TitleArea.vue"


export default {
  name: "Admin-parcel-infomation",
  meta: {
    title: "어드민-실험실"
  },
  components: {
    Title,
  },
  
  data() {
    return {
      description   : {
        title : '실험실'
      },
      receiver          : "",
      mobileCountry     : "",
      mobileNum         : "",
      customerEmail     : "",
      courierCode       : "",
      courierNum        : "",
      customerId        : "",
      filteredLocation  : [],
      shippingMethod    : [],
      proxyDate         : {},
      isDel             : "0",     // 0 : 등록됨, 1 : 삭제됨
      
      session     : this.$store.getters.getLocalSession,
    }
  },
  methods: {

    
    
  },


  mounted() {

    this.$log.log("[ Lab ] mounted")
    
  },
}
</script>


<template>
  <q-page class="q-pa-lg">
    <div class="row q-mb-lg">
      <div class="col">
        <Title
          :desc="description">

        </Title>
      </div>
    </div>

  </q-page>

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
