<template>
	<main id="site-container">

		<div class="section section-service-flight-01">
			<div class="container doc-page">
				<div class="row items-center">
					<div class="col">
						<h2 class="page-title text-center text-white">
							Business 계약 물류
						</h2>
					</div>
				</div>
			</div>
		</div>

		<div class="section section-service-02">
				<div class="container doc-page pt-5 pb-5">
						<div class="row pt-4 pb-4">
								<div class="col">
										<h3>혁신적인 업계 솔루션과 최고의 서비스</h3>
										<p class="mb-5">
												AONE LOGISTICS는 가장 빠르게 성장하는 글로벌 물류 서비스 업체 중 하나입니다.<br />
												공급망 물류를 관리하는 일은 복잡한 프로세스입니다.<br />
												AONE LOGISTICS는 모든 주요 시장에서 기업들의 계약 물류 파트너 역할을 수행하고 있습니다.
										</p>
										<h3>
												공급업체부터 고객배송까지,<br />
												현재의 아이디어가 내일의 역량이 되도록
										</h3>
										<p>
												고객이 어떤 업계에 종사하든 당사의 계약 물류 솔루션은 고객의 공급망 관리 프로세스에 가치를 부여합니다.<br />
												당사는 다양한 업계에서 회수 물류부터 애프터마켓 지원까지 공급망의 모든 단계를 아우르고 있습니다.<br />
												또한, 미래를 위한 투자를 지속하여 적극적으로 혁신과 디지털화를 통해 현재의 아이디어가 내일의 역량이 되도록 하고 있습니다.
										</p>
								</div>
						</div>
				</div>
		</div>

		<div class="section section-service-03">
				<div class="container doc-page pt-5 pb-5">
						<div class="column items-center">
								<div class="col mt-3">
										<h4 class="section-title">business services</h4>
								</div>
						</div>
						<div class="row mt-3 mb-5">
								<div class="col">
										<ul class="tab-nav">
												<li 
														:class="{ 'active' : currTab === 1}"
														@click="chageTab(1)">
														전자상거래</li>
												<li 
														:class="{ 'active' : currTab === 2}"
														@click="chageTab(2)">
														풀필먼트 서비스</li>
												<li 
														:class="{ 'active' : currTab === 3}"
														@click="chageTab(3)">
														항공/해상</li>
												<li 
														:class="{ 'active' : currTab === 4}"
														@click="chageTab(4)">
														창고보관</li>
										</ul>
								</div>
						</div>
						<div class="row tab-panel d-flex justify-content-center"
								v-if="currTab === 1">
								<div class="col-12 col-md-6">
										<img class="w-100 mb-4" :src="require(`@/assets/imgs/service/service-img-01.jpg`)">
								</div>
								<div class="col-12 col-md-6">
										<h3 class="mb-4">
												다양한 형태의 글로벌<br />
												E-COMMERCE 서비스를<br />
												보다 신속하고 정확하게
										</h3>
										<p class="text-justify">
												AONE LOGISTICS는 온라인 전자상거래에 특화된 전용 물류시스템 (H FOR) 를 통하여 전세계 네트워크와 이를 연결하는 전용 풀필먼트 센터, 특송물류 센터 운영을 통해 국내,해외 직구 및 역직구 B2B, B2C 호주내에 배송대행지 서비스 등 다양한 형태의 글로벌 E-COMMERCE 서비스를 보다 신속하고 정확하게 제공해 드릴 수 있어 귀사의 업무 효율을 증대시켜 드립니다.<br />
												특히, 본점(시드니)의 경우 일반 전자상거래 및 3PL 서비스 등 전세계를 대상으로 하는 전자상거래 업무도 수월하게 진행할 수 있습니다.
										</p>
								</div>
						</div>
						<div class="row tab-panel"
								v-if="currTab === 2">
								<div class="col-12 col-md-6 mb-3">
										<img class="w-100 mb-4" :src="require(`@/assets/imgs/service/service-img-02.jpg`)">
								</div>
								<div class="col-12 col-md-6 mb-3">
										<h3 class="mb-3">
												장소는 마땅하지 않고<br />
												호주에서 한국으로<br />
												물건은 팔고 싶고,
										</h3>
										<h3>
												저희 에이원 택배가<br />
												해결해 드립니다.<br />
												에이원에 문의해 주세요. 
										</h3>
								</div>
								<div class="col-12 mt-5 text-center">
										<img :src="require(`@/assets/imgs/service/service-img-02-1.png`)">
								</div>
						</div>
						<div class="row tab-panel"
								v-if="currTab === 3">
								<div class="col-12 col-md-6">
										<img class="w-100 mb-4" :src="require(`@/assets/imgs/service/service-img-03.jpg`)">
								</div>
								<div class="col-12 col-md-6">
										<h3 class="mb-4">
												목적지가 어디든 <br />
												당사의 혁신적인 솔루션은 <br /> 
												영업과 조달 시장을  <br />
												이어줍니다.
										</h3>
										<p class="text-justify">
												AONE LOGISTICS의 항공/해상 화물 운송 상품은 130여개 국에 퍼져있는 수많은 네트워크를 통해 고객들을 이어줍니다. 목적지가 어디든 당사의 혁신적인 솔루션은 영업과 조달 시장을 이어줍니다. 지속적이고 신뢰할 수 있는 운항은 귀사의 화물 운송이 항상 정시에 목적지까지 도착할 수 있도록 합니다.
										</p>
								</div>
						</div>
						<div class="row tab-panel"
								v-if="currTab === 4">
								<div class="col-12 col-md-6">
										<img class="w-100 mb-4" :src="require(`@/assets/imgs/service/service-img-04.jpg`)">
								</div>
								<div class="col-12 col-md-6">
										<h3 class="mb-4">
												AONE LOGISTICS는 <br/>
												전세계 주요거점의 <br/>
												물류센터를 기반으로 <br/>
												물류 네트워크를 <br/>
												구축하였습니다.
										</h3>
										<p class="text-justify">
												숙련된 인원과 현대화된 장비, RFID 는 물론 2D BARCODE 를 통한 재고관리를 통하여 귀사의 화물을 안전하게 관리, 보관하고 있습니다.<br/>
												AONE LOGISTICS는 고객의 다양한 요구를 충족시키는 한편 항상 최신화된 시스템으로 귀사에게 최고의 서비스를 제공할 수 있도록 최선을 다하겠습니다.
										</p>
								</div>
						</div>
						<div class="column items-center q-my-xl">
								<div class="col">
										<button class="btn btn-in-service">서비스 상담문의</button>
								</div>
						</div>
				</div>
		</div>

	</main>
</template>

<script>

export default {
  name: "About-us",
  components: {
  },
  data() {
    return {
        currTab : 1,
        navCurrent : 'service'
    }
  },
  methods: {
    chageTab : function(curr){
        this.currTab = curr
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
