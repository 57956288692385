<script>
/**
 * Type         : Modal component
 * Title        : 픽업지 추가 컴포넌트
 * Description  : 상품이 배송되거나 픽업을 진행할 거점 등록
 * 
 */

export default {
  name: "PickupPlaceRegistratioinForm",
  props: {
    showModal : Boolean
  },
  emits:[
    "close-modal",
    "add-item"
  ],
  data() {
    return {
      newPickupCode  : "",
      newPickupName  : "",
      country        : "",
      city           : "",
      region         : "",
      memo           : ""
    }
  },
  methods : {
    /**
     * Title        : 픽업지 추가하기
     * Status       : Actived
     * Description  : 픽업지 추가 emit 호출.
     */
    addItem(){
      const newData = {
        code       : this.newPickupCode,
        name       : this.newPickupName,
        country    : this.country,
        city       : this.city,
        region     : this.region,
        memo       : this.memo,
      }
      this.$emit("add-item", newData)
      this.$emit("close-modal")
    },

    /**
     * Title        : 픽업지 수정하기
     * Status       : Actived
     * Description  : 픽업지 수정
     */
    async setCityList() {
      this.$log.debug("선택된 국가 = " + this.country)
      await this.$getCodeList("CIT", this.country)
    },

    /**
     * Title        : 픽업지 수정하기
     * Status       : Actived
     * Description  : 픽업지 수정
     */
     async setRegionList() {
      this.$log.debug("선택된 도시 = " + this.city)
      await this.$getCodeList("REGION", this.city)
    }
  },
  async mounted() {
    await this.$getCodeList("NATION")
  },
}
</script>

<template>

  <q-layout 
    container
    class="bg-white">
    <q-header class="bg-primary">
      <q-toolbar>
        <q-toolbar-title>픽업지 추가</q-toolbar-title>
        <q-btn flat v-close-popup round dense icon="close" />
      </q-toolbar>
    </q-header>

    <q-footer class="bg-white text-black">
      <q-toolbar>
        <q-btn
          label="추가"
          class="full-width"
          color="primary"
          @click="addItem">
        </q-btn>
      </q-toolbar>
    </q-footer>

    <q-page-container>
      <q-page
        padding>
        <div class="row q-mb-sm q-gutter-sm">
          <div class="col">

            <q-input 
              v-model="newPickupCode"
              label="픽업지 코드"
              maxlength="8"
              placeholder="영문, 영문 + 숫자 8자리"/>    

          </div>
          <div class="col">

            <q-input 
              v-model="newPickupName"
              :maxlength="100"
              label="픽업지 이름" />    

          </div>
        </div>
        
        <div class="row q-gutter-sm">
          <div class="col">
            <q-select
              v-model="country"
              @update:model-value="setCityList"
              label="국가코드"
              :options="this.$store.getters.getNationCodeList"
              emit-value
              map-options
              behavior="menu"
            />
          </div>
          <div class="col">
            <q-select
              v-model="city"
              @update:model-value="setRegionList"
              label="픽업지 도시"
              :options="this.$store.getters.getCityCodeList"
              emit-value
              map-options
              behavior="menu"
            />
          </div>
          <div class="col">
            <q-select
              v-model="region"
              label="픽업지 지역"
              :options="this.$store.getters.getRegionCodeList"
              emit-value
              map-options
              behavior="menu"
            />
          </div>
        </div>
        <div class="row q-gutter-sm">
          <div class="col">
            <q-input 
              v-model="memo"
              type="textarea"
              label="메모"
              maxlength="400"
              placeholder="해당 픽업지에 대한 메모를 남겨주세요."/>
          </div>
        </div>

      </q-page>
    </q-page-container>
  </q-layout>
    
</template>


