<template>
  <q-page>
    <main id="site-container" style="height:100%;">
      <div id="signup-container" style="height:100%;">
        <div class="container doc-page" style="height:100%;">
          <div class="white-box">
  
            <div class="row justify-center q-mb-md">
              <div class="col text-center">
                <h3 class="q-mb-lg">
                  회원가입
                </h3>
                <p class="text-subtitle1 q-mb-xl">
                  회원가입을 하시면 보다 다양한 맞춤 물류 정보 서비스를 제공합니다.
                </p>
              </div>
            </div>
  
            <div 
              v-if="currStep > 0" 
              class="row justify-center q-mb-md">
              <div class="col-xs-12 col-sm-9 col-md-6">

                <q-input 
                  v-model="email"
                  ref="email"
                  @change="this.$validEmail(email)"
                  label="Email"
                  placeholder="이메일을 입력해주세요."
                  required />

              </div>
            </div>
            <div
              v-if="currStep > 1"
              class="row justify-center q-mb-md">
              <div class="col-xs-12 col-sm-9 col-md-3">
                <q-input 
                  v-model="password"
                  ref="password"
                  type="password"
                  label="비밀번호"
                  placeholder="비밀번호"
                  required />
              </div>

              <div class="col-xs-12 col-sm-9 col-md-3">
                <q-input 
                  v-model="passwordConfirm"
                  @blur="comparePassword"
                  ref="passwordConfirm"
                  type="password"
                  label="비밀번호 재입력"
                  placeholder="비밀번호를 재입력해 주세요."
                  required />
              </div>
            </div>
  
            <div 
              v-if="currStep > 2" 
              class="row justify-center q-mb-md">
              <div class="col-xs-12 col-sm-9 col-md-3">

                <q-input 
                  v-model="lastName"
                  ref="lastName"
                  label="성"
                  placeholder="성을 입력해주세요."
                  required />
  
              </div>
              <div class="col-xs-12 col-sm-9 col-md-3">
  
                <q-input 
                  v-model="firstName"
                  ref="firstName"
                  label="이름"
                  placeholder="이름을 입력해주세요."
                  required />
  
              </div>
            </div>

            <div v-if="currStep > 3" 
              class="row justify-center q-mb-md">
              <div class="col-xs-12 col-sm-9 col-md-6">
                
                <q-input 
                  v-model="mobile"
                  @change="this.mobile = this.$validMobile(mobile)"
                  ref="mobile"
                  type="tel"
                  label="휴대폰번호"
                  placeholder="휴대폰번호를 입력해주세요."
                  required />
  
              </div>
            </div>
            <div v-if="currStep > 4" 
              class="row justify-center q-mb-md">
              <div class="col-xs-12 col-sm-9 col-md-6">

                <q-select
                  v-model="country"
                  ref="country"
                  label="국가"
                  :options="countryList"
                  behavior="menu"
                  emit-value
                  map-options
                  required
                />
  
              </div>
            </div>
            <div class="row justify-center q-mb-md">
              <div class="col-xs-12 col-sm-9 col-md-6">

                <q-checkbox
                  name="이용약관 동의"
                  v-model="terms"
                  label="이용약관 동의"/>

              </div>
            </div>
            <div v-if="currStep > 5" 
              class="row justify-center">
              <div class="col-xs-12 col-sm-9 col-md-6">
                <q-btn 
                  color="primary"
                  label="회원가입"
                  class="full-width"
                  @click="signUp">
                </q-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </q-page>

  <Conditions
    :show-modal="terms"
    />
</template>

<script>
import Conditions from "@/components/customer/modal/Conditions.vue"

export default {
  name: "Customer-signUp",
  meta: {
    title: "회원가입"
  },
  components: {
    Conditions
  },
  data() {
    return {
      country: "",
      countryList: [
        { label: '호주', value: 'AUS' },
        { label: '대한민국', value: 'KOR' },
      ],
      conditionsModalShow: false,
      email: "",
      password: "",
      passwordConfirm: "",
      firstName: "",
      lastName: "",
      mobile: "",
      terms : false,
      currStep: 6,
      navCurrent : 'signUp',
    }
  },
  methods: {
    async signUp() {

      // Validation
      let checkTarget = {
        email     : this.email,
        password  : this.password,
        passwordConfirm: this.passwordConfirm,
        firstName : this.firstName,
        lastName  : this.lastName,
        mobile    : this.mobile,
        country   : this.country,
      }

      this.$log.debug("checkTarget = ", checkTarget)
      let result = await this.$checkEmptyVModelList(checkTarget)

      if (result.length > 0) {
        alert("전체항목을 입력하세요.")
        return false
      }

      if(!this.terms){
        alert("이용약관에 동의해 주세요.")
        return false
      }

      // 비밀번호와 이메일 확인
      if(!this.comparePassword() || !this.$validEmail(this.email)){
        return false
      }

      /*
      ** 1. 증복가입을 피하기 위한 조회
      */
      let userCheckPayload = {
        "email": this.email
      }
      this.$log.debug("payload = ", userCheckPayload)

      let userCheckConfig = {
        method: "post",
        url: "/api/cust/userCheck",
        data: userCheckPayload
      }

      let userCheck = await this.$http(userCheckConfig)

      this.$log.debug("userCheck => ", userCheck)

      if(!userCheck.data){
        alert("회원가입이 된 email 입니다.")
        return false
      }



      /*
      ** 2. 회원가입
      */
      let signUpPayload = {
        "email": this.email,
        "password": this.password,
        "firstName": this.firstName,
        "lastName": this.lastName,
        "mobile": this.mobile,
        "country": this.country,
      }

      this.$log.debug("payload = ", signUpPayload)

      let signUpConfig = {
        method: "post",
        url: "/api/cust/signUp",
        data: signUpPayload
      }

      let resultSignUp = await this.$http(signUpConfig)

      this.$log.debug("resultSignUp", resultSignUp)
      
      if(!resultSignUp.data){
        alert("회원 가입 중 오류가 발생했습니다.")
        return false
      }


      /*
      ** 3. 회원가입 후 로그인 실행 및 메인 페이지로 이동
      */
      // toast 로 변경 필요
      alert("회원가입에 성공했습니다.")

      let payload = {
        "email": this.email,
        "pwd": this.password,
      }

      let config = {
        method : "post",
        url : "/api/cust/signIn",
        data : payload
      }

      this.$log.debug("/cust/signIn 실행됨")

      this.$http(config)
        .then(res => {

          if(res.status === 200){

            this.$log.debug("/cust/signIn 성공", res)

            this.$store.dispatch('setCustomerInfo', res.data)

            this.$router.push('/')
          } else {
            alert(res.info.reason)
          }
        })
        .catch(res => {
          this.$log.debug("/customer/signIn 실패함 catch", res)
          alert("로그인 중 오류가 발생했습니다.")
        })
    },


    comparePassword(){
      // 비밀번호와 비밀번호 재입력이 같은지 확인
      if(this.password !== this.passwordConfirm){
        this.passwordConfirm = ""
        alert("비밀번호가 일치하지 않습니다.")
        return false
      } else {
        return true
      }
    }
  },
  mounted() {
    this.$log.debug("Notice list mounted")

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
