import log from '@/plugin/logger.ts'

/**
 * STATE
 */
const state = {
  localSession : {},
  isSignedIn : false
}

/**
 * GETTERS
 */
const getters = {
  getLocalSession(state) {
    return state.localSession
  },
  getSessionStatus(state) {
    return state.isSignedIn
  },
  getUserId(state) {
    return state.localSession.id
  },
  getBannerStatus(state) {
    return state.banner
  }
}

/**
 * MUTATIONS
 */
const mutations = {
  // parcel registration
  setCustomerInfo(state, value){
    // log.debug("setCustomerInfo excuted = > ", value)

    let tempSession = {
      id        : value.id,
      email     : value.email,
      fName     : value.fName,
      lName     : value.lName,
      mobile    : value.mobile,
      authLevel : value.authLevel
    }

    log.debug("setCustomerInfo  = ", tempSession)
    state.localSession = tempSession
    state.isSignedIn = true
  },

  destroyCustomerInfo(state){
    log.debug("destroyCustomerInfo excuted = > ")

    state.localSession = {}
    state.isSignedIn = false
  },

  // mutBannerStatus(state){
  //   state.banner = 
  // }
}

/**
 * ACTIONS
 */
const actions = {
  // 로그인 이후 조회된 고객의 정보를 vuex에 넣어 활용 가능하도록 한다.
  setCustomerInfo: ({ commit }, payload) => {
    // log.debug(payload)
    commit('setCustomerInfo', payload)
  },
  // 로그아웃 후 vuex에 저장 되어 있는 고객의 정보를 지운다.
  destroyCustomerInfo: ({ commit, state }) => {
    log.debug(state)
    commit('destroyCustomerInfo')
  },

  // 배너의 쿠키정보를 활용한다.
  bannerStatus: ({ commit, state }) => {
    commit('mutBannerStatus')
  }
}

// state, getters, mutations, actions, modules
export default {

  state: {
    ...state
  },
  getters,
  mutations,
  actions

}
