<script>
import { ref } from 'vue'


export default {
  name: "Bulk-list",
  components: {},
  computed: {
    parcelList() {
      return this.$store.getters.getBulkParcelList
    },
  },
  data() {
    return {
      parcelId: [],
      paging : 0,
      selectedList: ref([]),  // 선택된 parcel을 저장
      columns : [
        { name: 'bulkSeq', label: '묶음배송번호', align: 'center', field: 'bulkSeq' },
        { name: 'bulkCnt', label: '묶음배송갯수', align: 'center', field: 'bulkCnt' },
        { name: 'detail', label: '기능선택', align: 'center', field: 'detail' },
      ],
      initialPagination : {
        rowsPerPage: 10
      },
    }
  },
  emits: [
    'reload-bulk-list',  // TODO : 리스트 재조회
    'open-edit-modal'    // 묶음배송수정
  ],
  methods:{
     // 삭제 실행 메소드
    async dismiss (id) {

      this.$log.debug("delete 해제 ", id)

      let payload = { bulkSeq : id }

      let config = {
        method  : "post",
        url     : "/api/bulkParcel/dismiss",
        data    : payload,
        name    : "[ Axios / 묶음배송해제 ]"
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("해제했습니다.")
          
          this.$emit('reload-bulk-list')

          alert("묶음배송을 해제했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('삭제 실패!')
      })
    },

    async modify(id){
      this.$log.debug("[ Click / 묶음배송수정 ] 선택된 bulkSeq ", id)

      let payload = { bulkSeq : id }

      let config = {
        method  : "post",
        url     : "/api/bulkParcel/getSingleBulk",
        data    : payload,
        name    : "[ Axios / 묶음배송 1건 조회 ]"
      }

      await this.$http(config)
        .then((res) => {
          this.$log.debug("묶음배송 1건 조회했습니다 =>", res.data)

          this.$store.dispatch('addToBulkList', res.data)
          this.$store.dispatch('setSnapShot', res.data)

          this.$emit('open-edit-modal', id)
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('묶음배송 수정 - 1건 조회 실패!')
      })
    }
  }
}
</script>

<template>

<div class="row q-mb-sm">
    <div class="col">

      <q-table
        class="my-sticky-virtscroll-table"
        virtual-scroll
        v-model:selected="selectedList"
        :rows-per-page-options="[0]"
        :virtual-scroll-sticky-size-start="48"
        :rows="parcelList"
        :columns="columns"
        :pagination="initialPagination"
        row-key="bulkSeq">

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="bulkSeq" :props="props">
              {{ props.row.bulkSeq }}
            </q-td>
            <q-td key="bulkCnt" :props="props">
              {{ props.row.bulkCnt }}
            </q-td>
            <q-td key="customerName" :props="props">
              {{ props.row.customerName }}
            </q-td>
            <q-td key="regDate" :props="props">
              {{ props.row.regDate }}
            </q-td>
            <q-td key="detail" :props="props">

              <q-btn-dropdown 
                color="primary" 
                label="기능선택"
                size="sm">
                <q-list>
                  <q-item
                    clickable 
                    v-close-popup
                    @click="modify(props.row.bulkSeq)">
                    <q-item-section>
                      <q-item-label>수정</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-separator />
                  <q-item
                    clickable 
                    v-close-popup
                    @click="dismiss(props.row.bulkSeq)">
                    <q-item-section>
                      <q-item-label>삭제</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>

            </q-td>
          </q-tr>
        </template>

      </q-table>
    </div>
  </div>
  
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
