// import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import log from '@/plugin/logger.ts'

/**
 * 통신을 관리하는 메소드
 * @param {*} rawConfig 
 * @returns 
 */
const http = async (rawConfig) => {
  log.debug("rawConfig", rawConfig)

  let rePackedConfig = {
    url   : process.env.VUE_APP_API_URL + rawConfig.url,
    method: rawConfig.method,
    data  : rawConfig.data,
    name  : rawConfig?.name,
    withCredentials : true // send cookies when cross-domain requests
  }

  // log.debug("rePackedConfig = ", rePackedConfig)

  store.dispatch('showSpinner')

  return axios(rePackedConfig)
    .then((res) => {
      log.debug((rawConfig?.name != undefined ? rawConfig?.name : "Axios 이름이 없습니다.") + " => ", res)
      const status = res.status
      
      switch (status) {
        case  500 :
          alert("서버에 오류가 발생했습니다. ", status)
          break;
        case 200 :
          log.debug(`${rawConfig?.name} 실행됨`)
          break;
        default :
          log.debug(`${rawConfig?.name} 실행됨`)
      }
      // store.dispatch('killSpinner')
      return res
    })
    .catch((err) => {
      // TODO : Catch 단계의 오류 정리 필요

      log.debug(`${rawConfig?.name} 에서 오류가 발생되었습니다. ${err}`)
      // 오류 상태 코드
      const status = err.response.status

      // 오류 영문 코드
      const code = err.code
      if (status === 401) {
        // log.debug("로그인이 필요한 서비스 입니다.")
        alert(err.response.data)
      }

      if (code === "ERR_NETWORK") {
        log.debug("네트워크 오류가 있습니다.", code)
      }
      return err
    })
    .finally(() => {
      store.dispatch('killSpinner')
    })
}

// 세션 체크
const checkSession = async () => {
  let config = {
    method : "get",
    url : "/api/cust/getSession",
  }
  
  await http(config)
    .then(res => {
      log.debug("1. Result check session => ", res)
      // cookie 에서 passport 내용 추출
      const passport = res.data.passport

      // passport 가 없는 경우
      if(passport === undefined){
        
        log.debug('1.1 로그인 되어있지 않음')
        store.dispatch('destroyCustomerInfo')
        sessionStorage.clear()

      } else {
        
        log.debug('1.2 로그인 되어있음 isSignedIn = ', passport.user)
        
        // 로그인 성공 후 넘어온 고객의 정보를 Vue storage에 넣는다.
        store.dispatch('setCustomerInfo', passport.user)

      }

    }).catch(error => {
      log.debug("3.3 Result check session has error => ", error)
      store.dispatch('destroyCustomerInfo')
      sessionStorage.clear()
      alert('router 이동 시 세션 체크중 오류가 발생했습니다.')
      this.$router.push('/login')
    })
}


const validKor = (data) => {
    let regExp = /^[가-힣\s]+$/
    // log.debug("data = " + data.length)
    // log.debug("regExp result = " + regExp.test(data))
    if (data.length > 0) {
        return regExp.test(data)
    } else {
        return true
    }
}

const validMobile = (data) => {
    log.debug("validMobile executed => " + data)
    // 숫자를 제외한 문자제거
    return data.replace(/[^0-9]/g, '')
}

const validEmail = (data) => {
    // 이메일 형식 검증
    let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
    // log.debug("data = " + data)
    // log.debug("regExp result = " + regExp.test(data))
    let result = regExp.test(data)
    if (data.length > 0 && !result) {
        alert("잘못된 이메일 형식입니다.")
        return result
    } else {
        return true
    }
}

const checkEmpty = (data) => {
    // 전달받은 원본 데이터
    let resData = data
    // 전달 받은 데이터에서 Key를 추출한다
    let keyArr = Object.keys(data)
    log.debug("Extracted keyArr = ", keyArr)

    // required 속성을 가지고 있지만 값이 없는 항목의 key를 리턴하여 Array를 만든다
    let emptyList = keyArr.filter(key => {
        const thisKey = resData[key]
        log.debug("resData[key].value = " + thisKey.value)
        log.debug("resData[key].required = " + thisKey.required)
        if ((thisKey?.value === "" || thisKey?.value === undefined) && thisKey?.required === true || thisKey?.required === undefined) {
            return key
        }
    })
    log.debug("emptyList = ", emptyList)

    return emptyList
}

/**
 * 
 * 
 * @param { v model data } data 
 * @returns 
 */
const checkEmptyVModelList = (data) => {
    
  // 전달 받은 데이터에서 Key를 추출한다
  let keyArr = Object.keys(data)
  log.debug("Extracted keyArr = ", keyArr)

  // required 속성을 가지고 있지만 값이 없는 항목의 key를 리턴하여 Array를 만든다
  let emptyList = keyArr.filter(key => {
    // log.debug("key = " + key)

    const thisVal = data[key]
    // log.debug("data[key].value = " + thisVal)

    if (thisVal === "" || thisVal === undefined) {
        return key
    }
  })
  log.debug("emptyList are = ", emptyList)

  return emptyList
}

/**
 * Title        : 택배사 목록조회
 * Status       : Actived
 * Description  : 택배사 목록을 조회하여 Store에 위치시킨다.
 */
const getCourierList = () => {
  let isCourierLoaded = store.getters.getCourierList
  // log.debug("택배사 목록 조회", isCourierLoaded)
  
  if(isCourierLoaded.length > 0){
    log.debug("택배사 목록이 이미 조회되어 있습니다.")
  } else {
    let config = {
      method  : "get",
      url     : "/api/courier/getCourierList",
      name    : "[ AXIOS / 택배사 목록조회 ]" 
    }

    http(config)
      .then(res => {
      let tempRtn = res.data
      // log.debug("/admin/getCourierList 실행됨", tempRtn)
        store.dispatch('loadCourier', tempRtn)
      })
  }
}

/**
 * Title        : 배송상품 위치목록조회
 * Status       : Actived
 * Description  : 배송상품 위치목록을 조회하여 Store에 위치시킨다.
 */
const getParcelLocationList = () => {
    let isLoaded = store.getters.getParcelLocationList

    if(isLoaded.length > 0){
        log.debug("배송상품 위치 목록이 이미 조회되어 있습니다.")
    } else {
      let config = {
        method  : "get",
        url     : "/api/parcelLocation/getParcelLocationList",
        name    : "[ AXIOS / 배송상품 위치 목록 ]" 
      }
  
      http(config)
        .then(res => {
          let tempRtn = res.data
          log.debug("/admin/getParcelLocationList 실행됨", tempRtn)
          store.dispatch('loadParcelLocation', tempRtn)
        })
    }
}


// 픽업지 목록
const getPickupList = () => {
    let isLoaded = store.getters.getPickupLocationList

    if(isLoaded.length > 0){
        log.debug("픽업지 목록이 이미 조회되어 있습니다.")
    } else {
      let config = {
        method  : "get",
        url     : "/api/pickup/getPickupList",
        name    : "[ AXIOS / 픽업지 목록 ]" 
      }
  
      http(config)
        .then(res => {
          let tempRtn = res.data
          // log.debug("/admin/getPickupList 실행됨", tempRtn)
          store.dispatch('loadPickupLocation', tempRtn)
        })
    }
}


/**
 * Title        : 목적도시 조회
 * Status       : Actived
 * Description  : 목적지 - 도시를 조회하여 Store에 담는다.
 */
const getCityList = () => {
  let isLoaded = store.getters.getPickupLocationList

  if(isLoaded.length > 0){
      log.debug("목적지(도시) 목록이 이미 조회되어 있습니다.")
  } else {
    let config = {
      method  : "get",
      url     : "/api/code/getCityList",
      name    : "[ AXIOS / 목적지(도시) 목록 ]" 
    }

    http(config)
      .then(res => {
        let tempRtn = res.data
        // log.debug("/api/destination/getCityList 실행됨", tempRtn)
        store.dispatch('fetchCityList', tempRtn)
      })
  }
}


/**
 * Title        : 운송수단 조회
 * Status       : Actived
 * Description  : 운송수단를 조회하여 Store에 담는다.
 */
const getFreightList = () => {
  let isLoaded = store.getters.getFreightMethodList

  if(isLoaded.length > 0){
      log.debug("운송수단 목록이 이미 조회되어 있습니다.")
  } else {
    let config = {
      method  : "get",
      url     : "/api/code/getFreightMethodList",
      name    : "[ AXIOS / 운송수단 목록 ]" 
    }

    http(config)
      .then(res => {
        let tempRtn = res.data
        store.dispatch('fetchFreightList', tempRtn)
      })
  }
}


/**
 * Title        : 권한 레벨 목록 불러오기
 * Status       : Actived
 * Description  : 권한레벨 목록을 불러온다.
 */
const getMemberLevelList = () => {
  let isLoaded = store.getters.getPickupLocationList

  if(isLoaded.length > 0){
    log.debug("권한 레벨 목록이 이미 조회되어 있습니다.")
  } else {
    let config = {
      method  : "get",
      url     : "/api/code/getMemberLevelList",
      name    : "[ AXIOS / 멤버권한목록 ]"
    }

    http(config)
      .then(res => {
        let tempRtn = res.data
        // log.debug("/api/code/getMemberLevelList 실행됨", tempRtn)
        store.dispatch('fetchMemberAuthLevelList', tempRtn)
      })
  }
}



/**
 * Title        : 코드목록 불러오기
 * Status       : Actived
 * Description  : 코드목록을 불러온다.
 */
const getCodeList = async (code, parent) => {
  
  log.debug("불러올 부모코드가 있을 경우 = ", parent)
  log.debug("불러올 코드목록 = ", code)

  let payload = {
    parentCode : parent,
    code : code
  }

  let config = {
    method  : "post",
    url     : "/api/code/getCodeList",
    data    : payload,
    name    : `[ AXIOS / 코드목록 불러오기 / ${code} ]`,
  }

  await http(config)
    .then(res => {
      let tempRtn = res.data

      switch(code){
        case 'NATION':
          store.dispatch('actNationList', tempRtn)
          break;

        case 'CIT':
          store.dispatch('actCityList', tempRtn)
          break;

        case 'REGION':
          store.dispatch('actRegionList', tempRtn)
          break;
      }
    })
}

export default {
    install(App) {
        /**************
        // http
        ***************/
        App.config.globalProperties.$http = http

        /**************
        // session check
        ***************/
        App.config.globalProperties.$checkSession = checkSession

        /**************
        // validation
        ***************/
        // 빈값 체크
        App.config.globalProperties.$checkEmpty = checkEmpty
        // v-model 인 object list를 넘겼을 때 값이 빈 경우 대상을 Array로 반환
        App.config.globalProperties.$checkEmptyVModelList = checkEmptyVModelList
        // 한글만 입력가능
        App.config.globalProperties.$validKor = validKor
        // 전화번호
        App.config.globalProperties.$validMobile = validMobile
        // 이메일
        App.config.globalProperties.$validEmail = validEmail

        
        /**************
        // 자주 쓰이는 목록
        ***************/
        // 택배사 목록 조회
        App.config.globalProperties.$getCourierList = getCourierList
        // 배송상품 위치 목록 조회
        App.config.globalProperties.$getParcelLocationList = getParcelLocationList
        // 픽업지 위치 목록 조회
        App.config.globalProperties.$getPickupList = getPickupList
        // 목적지 위치 목록 조회
        App.config.globalProperties.$getCityList = getCityList
        // 운송수단 조회
        App.config.globalProperties.$getFreightList = getFreightList
        // 권한목록 조회
        App.config.globalProperties.$getMemberLevelList = getMemberLevelList
        // 코드목록 조회
        App.config.globalProperties.$getCodeList = getCodeList
        

    }
}

export const common = {
    http,
    checkSession,
    checkEmpty,
    checkEmptyVModelList,
    validKor,
    validMobile,
    validEmail,
    getCourierList,
    getParcelLocationList,
    getPickupList,
    getFreightList,
    getCityList,
    getMemberLevelList,
    getCodeList
}
