<template>
    <teleport to="body">
        <div class="spinner-backdrop">
            <div class="spinner"></div>
        </div>
    </teleport>
</template>
<script>
export default {
    name:"Utilites-Spinner",
}
</script>