<script>
/**
 * Type         : Page component
 * Title        : 픽업지 관리 페이지 컴포넌트
 * Description  : 상품이 배송되거나 픽업을 진행할 거점을 관리한다
 * 
 */

import {ref} from "vue"

import Title from "@/components/admin/common/TitleArea.vue"
import PickupRegForm from "@/components/admin/modal/PickupRegiFormUnit.vue"
import PickupList from "@/components/admin/PickupList.vue"

export default {
  name: "AdminPagePickupManagement",
  meta: {
    title: "어드민-픽업지관리"
  },
  components: {
    Title,
    PickupList,
    PickupRegForm
  },
  setup() {
    return {
      description   : {
        title       : '픽업지 관리'
      },
      showModalNow  : ref(false),
      mode          : 0,
    }
  },
  methods:{
    /**
     * Title        : 픽업지 추가 모달 열기
     * Status       : Actived
     * Description  : 픽업지 추가 모달을 호출한다.
     */
    addPickupForm(){
      this.$log.debug("픽업지 추가 클릭")
      this.showModalNow = true
      this.mode = 0
    },

    /**
     * Title        : 모달 닫기
     * Status       : Actived
     * Description  : 픽업지 등록 모달을 닫는다
     */
    closeModal(){
      this.$log.debug("픽업지 모달 닫기 클릭")
      this.showModalNow = false
    },

    /**
     * Title        : 픽업지 추가
     * Status       : Actived
     * Description  : 픽업지를 등록한다.
     */
    async regiPickup(payload){
      let config = {
        method  : "post",
        url     : "/api/admin/addPickup",
        data    : payload,
        name    : "[ AXIOS / 픽업지 추가 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug("[ Axios / 픽업지 추가 ] 실행됨 => ", res)
          this.$refs.pickupList.loadpickupList()
        }).catch(res => {
          this.$log.debug("[ Axios / 픽업지 추가 ] 실패 => ", res)
        })
    },
  },
}
</script>


<template>
  <q-page class="q-pa-lg">
    <div class="row">
      <div class="col">
        <Title
          :desc="description">

          <q-btn
            color="primary"
            size="md"
            label="픽업지 추가"
            @click="addPickupForm"/>
        </Title>  
      </div>
    </div>

    <div class="row q-mt-lg">
      <div class="col">
        <div class="comp-box">
          <PickupList 
            ref="pickupList" />
        </div>
      </div>
    </div>
  </q-page>

  <teleport to="body">

    <q-dialog 
      v-model="showModalNow">

      <PickupRegForm
        @add-item="regiPickup"
      />

    </q-dialog>
  </teleport>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
