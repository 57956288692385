<script>
// import axios from 'axios'
// import qs from 'qs'

import CustomerList from "@/components/admin/CustomerList.vue"

export default {
  name: "Admin-Customer-management",
  meta: {
    title: "어드민-고객정보조회"
  },
  components: {
    CustomerList,
  },
}
</script>

<template>

  <q-page class="q-pa-lg">
    <div class="row q-mb-lg">
      <div class="col">
        <div class="comp-box">
          <h5 class="q-my-sm">
            고객정보조회
          </h5>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="comp-box">
          <CustomerList />
        </div>
      </div>
    </div>
  </q-page>

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>