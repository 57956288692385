<script>
import Title from "@/components/admin/common/TitleArea.vue"
import FreightList from "@/components/admin/FreightList.vue"
import FreightRegiFormUnit from "@/components/admin/modal/FreightRegiFormUnit.vue"

export default {
  name: "Admin-freight-management",
  meta: {
    title: "어드민-freight 관리"
  },
  components: {
    Title,
    FreightList,
    FreightRegiFormUnit
  },
  data() {
    return {
      freightList   : [],
      showModalNow  : false,
      description   : {
        title       : '운송편 관리'
      },
    }
  },

  /*
  setup(){
  }
  */

  methods: {
    /**
     * 코드 추가 모달 열기
     */
    regiFreightForm(){
      this.showModalNow = !this.showModalNow
    },

    /**
     * 코드 등록 모달 닫기
     */
    closeModal(){
      this.showModalNow = false
    },

    /**
     * 
     * Title        : 운송편 등록하기
     * Status       : Actived
     * Description  : 운송편을 등록한다.
     * @param {*} payload 
     */
    async regiFreight(payload){

      this.$log.debug("등록할 운송편 = ", payload)

      let config = {
        method  : "post",
        url     : "/api/freight/regFreight",
        data    : payload,
        name    : "운송편 등록"
      }

      await this.$http(config)
        .then(res => {
          this.loadFreightList()
        })
    },

    /**
     * Title        : 운송편 목록 조회
     * Status       : Actived
     * Description  : 운송편 목록을 조회한다.
     */
    async loadFreightList() {

      let result = []

      let config = {
        method  : "get",
        url     : "/api/freight/getFreightList",
        name    : "운송편 목록 호출"
      }

      result = await this.$http(config)
        .then(res => {
          let tempRtn = res.data
          return tempRtn
        })

      this.freightList = result
    },
  },
  
  mounted() {
    this.$log.info("[ Page / 운송편관리 ]")
    // 운송편목록 불러오기
    this.loadFreightList()
  },
}
</script>


<template>
  
  <q-page class="q-pa-lg">
    <div class="row">
      <div class="col">
        <Title
          :desc="description">

          <q-btn 
            label="운송편추가"
            class="btn"
            size="md"
            color="primary"
            @click="regiFreightForm" />

        </Title>  
      </div>
    </div>

    <div class="row q-mt-lg">
      <div class="col">
        <div class="comp-box">
          <FreightList 
            :freight-list="freightList"
            @load-list="loadFreightList"/>
        </div>
      </div>
    </div>
  </q-page>
  
  
  <FreightRegiFormUnit
    :showModal="showModalNow"
    @close-modal="closeModal"
    @add-item="regiFreight"
  /> 


</template>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
