<script>
import ModalReqId from "@/components/customer/modal/PickUpReqId.vue"

export default {
  name: "temp-export-form",
  computed: {
    parcelLocationList(){
      return this.$store.getters.getParcelLocationListExpulo
    },
  },
  // 임시 export 
  data() {
    return {
      reqId               : 0,
      pickUpReqModal      : false,
      sender              : '',
      senderMobCountry    : '',
      senderMobNum        : '',
      senderAddress       : '',
      receiver            : '',
      receiverImId        : '',
      receiverMobCountry  : '',
      receiverMobNum      : '',
      receiverAddress     : '',
      // parcelStatus        : '',
      reqBranch           : '',
    }
  },
  components: {
    ModalReqId,
  },
  methods:{
    dataInit (){
      this.pickUpReqModal      = false
      this.reqId               = 0
      this.sender              = ''
      this.senderMobCountry    = ''
      this.senderMobNum        = ''
      this.senderAddress       = ''
      this.receiver            = ''
      this.receiverImId        = ''
      this.receiverMobCountry  = ''
      this.receiverMobNum      = ''
      this.receiverAddress     = ''
      // this.parcelStatus        = ''
      this.reqBranch           = ''
    },
    async reqPickUp() {
      let payload = {
        sender              : this.sender,
        senderMobCountry    : this.senderMobCountry,
        senderMobNum        : this.senderMobNum,
        senderAddress       : this.senderAddress,
        receiver            : this.receiver,
        receiverImId        : this.receiverImId,
        receiverMobCountry  : this.receiverMobCountry,
        receiverMobNum      : this.receiverMobNum,
        receiverAddress     : this.receiverAddress,
        // parcelStatus        : this.parcelStatus,
        reqBranch           : this.reqBranch
      }

      // 필수 입력항목 검증
      let result = this.$checkEmptyVModelList(payload)
      if(result.length > 0){
        alert("모든 항목을 입력해주세요.")
        return false
      }

      let config = {
        method  : "post",
        url     : "/api/parcel/reqPickUp",
        data    : payload,
        name    : "[ Axios / TempExportForm / 픽업 추가 ]"
      }

      // 실제 등록 실행
      this.$http(config)
      .then(res => {
        if(res.status == 200){
          // TODO : 
          this.$log.debug(res)
          this.reqId = res.data.insertId
          this.pickUpReqModal = true
        } else {
          alert("픽업요청에 실패했습니다.")
        } 
      }).catch(res => {
        this.$log.debug(res)
        alert("[ reqPickUp / catch ] 픽업요청에 실패했습니다.", res)
      })
    }
  },
  beforeMount() {
    this.$getParcelLocationList()
  }
}
</script>

<template>

  <div class="row q-mb-lg">
    <div class="col">
      <h6>보내는 사람 정보</h6>
    </div>
  </div>

  <div class="row q-mb-lg">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 q-pr-sm">
      <q-input 
        v-model="sender"
        label="보내는 사람" />    
    </div>
  </div>

  <div class="row q-mb-lg">
    <div class="col-xs-4 col-md-2 q-pr-sm">
      <q-select
        v-model="senderMobCountry"
        label="국가번호"
        :options="['+61', '+82']"
        behavior="menu"
        emit-value
        map-options/>
    </div>

    <div class="col-xs-8 col-md-4">
      <q-input 
        v-model="senderMobNum" 
        @blur="this.$validMobile(senderMobNum)"
        label="전화번호"
        placeholder="'-' 없이 적어주세요." />    
    </div>
  </div>

  <div class="row q-mb-lg">
    <div class="col-12">
      <q-input 
        v-model="senderAddress" 
        label="보내는 사람 주소"
        placeholder="'-' 없이 적어주세요." />    
    </div>
  </div>


  <div class="row q-mb-lg">
    <div class="col">
      <h6>받는 사람 정보</h6>
    </div>
  </div>

  <div class="row q-mb-lg">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 q-pr-sm">
      <q-input 
        v-model="receiver"
        label="받는 사람" />    
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
      <q-input 
        v-model="receiverImId"
        label="개인고유통관부호"
        maxlength="13"
        :rules="[ val => val.length <= 13 || '13자리를 넘길수 없습니다.']" />    
    </div>
  </div>

  <div class="row q-mb-lg">
    <div class="col-xs-4 col-md-2 q-pr-sm">
      <q-select
        v-model="receiverMobCountry"
        label="국가번호"
        :options="['+61', '+82']"
        behavior="menu"
        emit-value
        map-options/>
    </div>

    <div class="col-xs-8 col-md-4">
      <q-input 
        v-model="receiverMobNum" 
        @blur="this.$validMobile(receiverMobNum)"
        label="전화번호"
        placeholder="'-' 없이 적어주세요." />    
    </div>
  </div>

  <div class="row q-mb-lg">
    <div class="col-12">
      <q-input 
        v-model="receiverAddress" 
        label="받는 사람 주소"
        placeholder="받으실 분의 주소를 적어주세요." />    
    </div>
  </div>

  <div class="row q-mb-xl">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
      <q-select
        v-model="reqBranch"
        label="맡기실 곳"
        :options="parcelLocationList"
        behavior="menu"
        emit-value
        map-options />
    </div>
  </div>
  
  <div class="row">
    <div class="col text-center">
      <q-btn
        label="픽업신청하기"
        color="primary"
        size="xl"
        @click="reqPickUp"></q-btn>
    </div>
  </div>

  <ModalReqId
    :showModal="pickUpReqModal"
    :pickUpId="reqId"
    :close-modal="dataInit">
  </ModalReqId>
  
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h6 {
  margin-bottom:0;
}
</style>
