/**
 * store / index.js
 * 
 * vue js store 설정파일
 * 
 */

import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import Storage from './storage'
import Parcel from './parcel'
import Code from './code'
import User from './user'
import Utils from './utilities'

// import log from '@/plugin/logger.ts'

const store = new Vuex.Store({
  modules: {
    storage : Storage,
    user    : User,
    code    : Code,
    utils   : Utils,
    parcel  : Parcel 
  },
  plugins: [
    // 주목! : 여기에 쓴 모듈만 저장됩니다.
    // TODO : session expired 설정해야함
    createPersistedState({
      paths: ['user'],
      key:'vuexStore',
      storage : window.sessionStorage,
    })
  ]
})

export default store