<template>
  <NavBar :current="navCurrent" />
  <main id="site-container">
    <div class="section section-service-ocean-01">
      <div class="container doc-page">
        <div class="row items-center">
          <div class="col">
            <h2 class="page-title text-center text-white">OCEAN SERVICE</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-service-flight-02">
      <div class="container doc-page q-pt-xl q-pb-xl q-mt-xl q-mb-xl">
        <div class="row">
          <div class="col q-mt-md">
            <h3>
              전 세계 어디든지,<br />
              130개국의 파트너쉽 해상화물 네트워크
            </h3>
            <p class="q-mb-xl">
              AONE LOGISTICS의 해상 화물 운송 상품은 130여개 국에 퍼져있는
              수많은 네트워크를 통해 고객들을 이어줍니다. 목적지가 어디든 당사의
              혁신적인 솔루션은 영업과 조달 시장을 이어줍니다. 지속적이고 신뢰할
              수 있는 운항은 고객의 해상 화물 운송이 항상 정시에 목적지까지
              도착할 수 있도록 합니다.<br />
              당사는 FCL 및 LCL 운송 분야에서 장점을 가지고 있습니다. 또한,
              시간과 비용을 절약하기 위해 항공과 해상 운송 상품을 연계하여 비용
              대비 효과가 가장 뛰어난 솔루션도 제공합니다.<br />
              AONE LOGISTICS의 해상화물 상품은 해상 공급망 요구를 충족하기 위해
              다양한 서비스를 제공합니다. 각 상품은 최고 수준의 광범위한 도어 투
              도어 솔루션을 충족하도록 개발되었습니다.
            </p>
          </div>
        </div>
        <div class="column q-mt-xl items-center">
          <!--
					<div class="col-xs-12 col-sm-6">
							<button class="btn btn-box">
									<strong>SYD → KOR</strong>
									수출 해상 신청하기
							</button>
					</div>
						-->
          <div class="col-xs-12 col-sm-6">
            <button
              @click="this.$router.push('parcel-registration')"
              class="btn btn-box"
            >
              <!--brighter-->
              <strong>KOR → SYD</strong>
              수입 해상 신청하기
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "ServiceFlight",
  components: {},
  data() {
    return {
      navCurrent: "service",
    };
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
