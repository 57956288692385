<template>
  <div class="section section-02 pb-5">
      <div class="container doc-page q-pb-none">
        <div class="column items-center">
          <div class="col mt-3">
            <h4 class="section-title">services</h4>
          </div>
        </div>
        
        <div class="column items-center">
          <div class="col text-center">
            <div class="display-text-1">어디든 달려가는 AONE Logistic</div>
            <p class="display-text-2">호주글로벌 로지스틱스</p>
          </div>

        </div>
        <div class="row">
          <div 
            @click="moveTo('service-flight')" 
            class="col-12 col-sm-6">
            <div class="
              mb-3
              banner
              banner-flight
              text-white">
              <p class="text-center self-center">FLIGHT</p>
            </div>
          </div>

          <div 
            @click="moveTo('service-ocean')" 
            class="col-12 col-sm-6">
            <div class="
              mb-3
              banner 
              banner-ocean 
              text-white">
              <p class="text-center self-center">OCEAN</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-03">
      <div class="container doc-page">
        <div class="row">
          <div class="col">
            <div 
              @click="moveTo('service')"
              class="column banner">
              <div class="col self-end">
                <div class="banner-text-01 text-right">성공적인 비지니스 파트너 AONE</div>
                <div class="banner-text-02 text-right">전자상거래 / 풀필먼트 서비스 / 창고보관</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

export default({
  methods:{
    moveTo(destination){
      this.$router.push(destination)
    },
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/style/variations.scss";

.section {
  &.section-02 {
    background: rgb(226, 226, 226);

    .display-text-1 {
      font-family: 'IBMPlexSansKR-SemiBold';
      font-size: $display-text-1-size;
      line-height: 1.1;
      color: $primary-color;
    }

    .display-text-2 {
      font-size: 1.75rem;
      color: $primary-color;
    }

    .banner {
      display: flex;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-blend-mode: darken;

      font-size: 3rem;
      font-family: 'Titilliu-Web-light';

      min-height: 160px;
      height: 100%;
      max-height: 240px;

      &:hover {
        cursor: pointer;
      }

      & > * {
        width: 100%;
        margin: 0;
      }

      &.banner-flight {
        background-image: url("@/assets/imgs/main/banner-flight.jpg");
      }

      &.banner-ocean {
        background-image: url("@/assets/imgs/main/banner-ocean.jpg");
      }
    }
  }

  &.section-03 {
    background: rgb(226, 226, 226);
    background-size: cover;
    background-blend-mode: darken;
    background-image: url("@/assets/imgs/main/banner-hand-shake.jpg");

    .column {
      height: 170px;
    }

    .banner {
      display: flex;
      color: $primary-color;

      .banner-text-01, .banner-text-02 {
        font-family: 'IBMPlexSansKR-SemiBold';
      }

      .banner-text-01 {
        font-size: 2.5rem;
      }

      .banner-text-02 {
        font-size: 1.3rem;
      }
    }
  }
}

</style>
