<script>
import Title from "@/components/admin/common/TitleArea.vue"
import NoticeList from "@/components/admin/NoticeList.vue"
import ModalNoticeRegi from "@/components/admin/modal/RegiNoticeForm.vue"


export default {
  name: "Admin-customer-inquery-manage",
  meta: {
    title: "어드민-고객문의관리"
  },
  components: {
    Title,
    NoticeList,
    ModalNoticeRegi,
  },
  /*
  setup(){
  },
  */
  data() {
    return {
      noticeList :[],
      noticeModal : false,
      editorMode : 0,
      authLevel : 1,
      selectedNotice : {},
      description   : {
        title : '고객문의 관리'
      },
    }
  },
  methods: {
    /**
     * 모달 닫기
     */
    closeModal(){
      this.$log.debug("Notice board Close modal")
      this.noticeModal = false
      this.loadList(0)
    },

    /**
     * 공지사항목록 조회하기
     */
    async loadList(curr) {
      let payload = {
        "paging" : curr
      }

      let config = {
        method  : "post",
        url     : "/api/board/getNoticeList",
        data    : payload,
        name    : "[ Axios / 공지사항목록조회 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug(res.data)

          if(res.data.length === 0 || res.data.length === undefined ){
            alert('더이상 공지사항이 없습니다.')
          } else {
            let newNoticeList = res.data

            newNoticeList.map(item => {
              this.noticeList.push(item)
            })
          }
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('공지글 불러오기 실패!')
      })
    },

    /**
     * 공지사항 더 불러오기 - deprecated will be deleted
     * @param {*} res 
     */
    async loadMoreList(res) {
      this.$log.debug("공지글 더 불러오기 시작 page = ", res)

      let payload = {
        "paging" : res
      }

      this.$log.debug("payload = ", payload)

      let config = {
        method  : "post",
        url     : "/api/board/getNoticeList",
        data    : payload,
        name    : "[ Axios / 공지사항목록추가조회 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug(res.data)

          if(res.data.length === 0 || res.data.length === undefined ){
            alert('더이상 공지사항이 없습니다.')
          } else {
            let newNoticeList = res.data

            newNoticeList.map(item => {
              this.noticeList.push(item)
            })
          }
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })
    },

    /**
     * 공지사항 추가 모달 열기
     */
    addNotice(){
      this.noticeModal = true
      this.editorMode = 0
    },

    /** 
     * 공지사항 수정
     */
    editNotice(selected){
      this.noticeModal    = true
      this.editorMode     = 1
      this.selectedNotice = selected

      this.$log.debug("Edit notice executed")
    }
  },
  mounted() {
    this.$log.info("[ Page / 고객문의 관리 ]")
    this.loadList(0)
  },
}
</script>


<template>
  <q-page class="q-pa-lg">
    <div class="row">
      <div class="col">
        <Title
          :desc="description">

        </Title>  
      </div>
    </div>

    <div class="row q-mt-md q-mb-md">
      <div class="col">
        <div class="comp-box">

          <NoticeList
            :notice-list="noticeList"
            :auth-level="authLevel"
            @reload-parcel-list="loadList"
            @load-more-list="loadList"
            @edit-modal="editNotice"
          />
          
        </div>
      </div>
    </div>

  </q-page>

  <ModalNoticeRegi
    :showModal="noticeModal"
    :editor-mode="editorMode"
    :selected-notice="selectedNotice"
    @close-modal="closeModal" />
  
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
