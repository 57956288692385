<template>
  <LoginBox />
</template>

<script>
import LoginBox from '@/components/admin/LoginBox.vue'

export default {
  name: "AdminLoginPage",
  components: {
    LoginBox,
  },
  mounted(){
    // this.$gtag.event('login', { method: 'Google' })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
