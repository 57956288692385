import { createLogger } from 'vue-logger-plugin'

// create logger with options
/**
 * level : debug, info, warn, error, log
 * 아래 순으로 하위 레벨을 포함한 log들이 보임
 * debug > info > warn > error > log
 * Local, Development 일 경우 'debug'
 * Production 일 경우 'info'
 */
const log = createLogger({
  enabled: true,
  level: (process.env.VUE_APP_ENV_LEVEL !== 'PRODUCTION' ? 'debug' : 'info') ,
  callerInfo : true,
  // beforeHooks: [  ],
  // afterHooks: [  ]
})

export default log

