<script>
// import axios from 'axios'
import { ref } from 'vue';


export default {
  name: "Export-PickUp-list",
  components: {
  },
  computed: {
    parcelLocationList(){
      return this.$store.getters.getParcelLocationListExport
    },
  },
  props:{
    list : Array
  },
  data() {
    return {
      selectedList: ref([]),  // 선택된 parcel을 저장
      columns : [
        { 
          name: 'rownum', 
          label: 'ID', 
          align: 'center', 
          field: 'id', 
          format: val => `${val}`,
          sortable: true
        },
        { name: 'sender', label: '보낸 사람', align: 'center', field: 'sender' },
        { name: 'senderMobCountry', label: '보낸 사람 모바일 국가', align: 'center', field: 'senderMobCountry' },
        { name: 'senderMobNum', label: '보낸 사람 모바일 번호', align: 'left', field: 'senderMobNum' },
        { name: 'senderAddress', label: '보낸 사람 주소', align: 'left', field: 'senderAddress' },
        { name: 'receiver', label: '수취인', align: 'center', field: 'receiver' },
        { name: 'receiverImId', label: '고유통관번호', align: 'center', field: 'receiverImId' },
        { name: 'receiverMobCountry', label: '수취인 모바일 국가코드', align: 'center', field: 'receiverMobCountry' },
        { name: 'receiverMobNum', label: '수취인 모바일 번호', align: 'left', field: 'receiverMobNum' },
        { name: 'receiverAddress', label: '수취인 주소', align: 'center', field: 'receiverAddress', sortable: true },
        { name: 'parcelStatus', label: '상태', align: 'center', field: 'parcelStatus', sortable: true },
        { name: 'reqBranch', label: '맡기실 곳', align: 'center', field: 'reqBranch', sortable: true },
        { name: 'regDate', label: '등록일', align: 'center', field: 'regDate' },
        // { name: 'detail', label: '상세', align: 'center', field: 'detail' },
      ],
      initialPagination : {
        rowsPerPage: 10
        // rowsNumber: xx if getting data from a server
      },
      selectedParcelId: "",
      userType: 'admin'
    }
  },
  methods:{
    // 일괄위치변경
    async bulkChangeStatus(status){
      
      let idArr = this.selectedList.map(item => {
        /** 코드분리 */
        return item.id.substring(4, item.id.length)
      })

      if(idArr.length < 1){
        alert("선택한 상품이 없습니다.")
        return false
      }
      
      this.$log.debug(status)
      this.$log.debug("idArr = ", idArr)

      
      let payload = {
        "id" : idArr, 
        "parcelStatus" : status
      }

      let config = {
        method  : "post",
        url     : "/api/parcel/modifyExportStatus",
        data    : payload,
        name    : "[ Axios / Export 픽업 위치 일괄변경]"
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("상태정보를 변경했습니다.")

          this.$emit('reload-parcel-list')

          alert("상태정보를 변경했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })

    },
  }
}
</script>


<template>

  <div class="row q-mb-sm">
    <div class="col">
      <q-btn-dropdown 
        color="primary" 
        label="상태변경">
        <q-list>
          <q-item v-for="(loc, index) in parcelLocationList" v-bind:key="index"
            clickable v-close-popup
            @click="bulkChangeStatus(loc.code)">
            <q-item-section>
              <q-item-label>{{loc.name}}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </div>
  </div>

  <div class="row q-mb-sm">
    <div class="col">

      <q-table
        class="my-sticky-virtscroll-table"
        virtual-scroll
        v-model:selected="selectedList"
        selection="multiple"
        :selected-rows-label="getSelectedString"
        :rows-per-page-options="[0]"
        :virtual-scroll-sticky-size-start="48"
        :rows="list"
        :columns="columns"
        :pagination="initialPagination"
        row-key="id">

      </q-table>
    </div>
  </div>

  <!--
  <div class="row q-mb-sm">
    <div class="col">
      <q-btn
        color="primary"
        size="md"
        label="더 불러오기"
        @click="lazyLoad"/>
    </div>
  </div>
  -->
</template>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
