<script>
/**
 * Type   : Page component
 * Title  : 배송상품조회 화면
 * Description : 입력된 고객의 배송 상품 조회화면을 보여주는 화면으로 가장 기본이 되는 화면이다.
 */

import Title from "@/components/admin/common/TitleArea.vue"
import ParcelLookupForm from "@/components/admin/ParcelLookupForm.vue"
import ParcelList from "@/components/admin/ParcelList.vue"
import { event } from 'vue-gtag'


export default {
  name: "AdminParcelInfomation",
  meta: {
    title: "어드민-배송상품조회"
  },
  components: {
    Title,
    ParcelLookupForm,
    ParcelList,
  },
  data() {
    return {
      receiver          : "",
      mobileCountry     : "",
      mobileNum         : "",
      customerEmail     : "",
      courierCode       : "",
      courierNum        : "",
      customerId        : "",
      filteredLocation  : [],
      shippingMethod    : [],
      proxyDate         : {},
      isDel             : "0",     // 0 : 등록됨, 1 : 삭제됨
      description   : {
        title : '배송상품조회'
      },
      session     : this.$store.getters.getLocalSession,
    }
  },
  methods: {

    /**
     * Title        : 검색영역 동기화
     * Status       : Actived
     * Description  : ParcelLookupForm 컴포넌트 - 검색영역에서 받은 검색조건을 동기화한다.
     * Param (idx)  : 선택된 배송상품의 idx
     */
    lookupOptSync(opt){
      this.$log.debug("[ Parcel info / 검색조건 ]= " , opt)
      this.receiver         = opt.receiver
      this.mobileCountry    = opt.mobileCountry,
      this.mobileNum        = opt.mobileNum,
      this.customerEmail    = opt.customerEmail,
      this.courierCode      = opt.courierCode,
      this.courierNum       = opt.courierNum,
      this.customerId       = opt.customerId
      this.filteredLocation = opt.filteredLocation
      this.shippingMethod   = opt.shippingMethod
      this.proxyDate        = opt.proxyDate
    },

    /**
     * Title        : 검색 조건에 따른 화물검색을 실행
     * Status       : Actived
     * Description  : 검색 조건에 따른 화물검색 기능 실행
     */
    lookupParcel(){
      
      if(!this.validation()){
        return false
      }

      this.loadParcelList()
    },

    /**
     * Title        : 화물조회 실행
     * Status       : Actived
     * Description  : -
     */
    async loadParcelList() {
      let payload = {
        "receiver"        : this.receiver,
        "mobile"          : this.mobileCountry + this.mobileNum,
        "email"           : this.customerEmail,
        "courierCode"     : this.courierCode,
        "courierNum"      : this.courierNum,
        "customerNum"     : this.customerId,
        "parcelLocation"  : this.filteredLocation,
        "freightMethod"   : this.shippingMethod,
        "regDate"         : this.proxyDate,
        "isDel"           : this.isDel,   // 삭제상태 아닌 것만 조회
        "paging"          : "0",
      }

      // this.$log.debug("payload = ", payload)

      let config = {
        method  : "post",
        url     : "/api/parcel/parcel",
        data    : payload,
        name    : "[ Axios / 화물목록조회 ]"
      }

      await this.$http(config)
        .then(res => {
          // this.$log.debug("불러오기 성공")
          // this.$log.debug(res.data)

          // Response data 를 data 에 넣는다.
          this.$store.dispatch('loadParcel', res.data)

          if(res.data.length === 0) alert("조회된 상품이 없습니다.")
        })
        .catch((err) => {
          this.$log.debug("/api/parcel/parcel", err)
      })
    },
    
    /**
     * Title        : 배송 상품 추가 조회
     * Status       : Actived
     * Description  : 배송상품을 추가로 불러온다.
     * Param (res)  : paging number
     */
    async loadMoreList(res) {
      // this.$log.debug("배송상품조회 시작 page = ", res)

      let payload = {
        "receiver"        : this.receiver,
        "mobile"          : this.mobileCountry + this.mobileNum,
        "email"           : this.customerEmail,
        "courierCode"     : this.courierCode,
        "courierNum"      : this.courierNum,
        "customerId"      : this.customerId,
        "parcelLocation"  : this.filteredLocation,
        "paging"          : res,
        "isDel"           : 0   // 삭제상태 아닌 것만 조회
      }

      // this.$log.debug("payload = ", payload)

      let config = {
        method  : "post",
        url     : "/api/parcel/parcel",
        data    : payload,
        name    : "[ Axios / 배송상품조회 더 불러오기 ]"
      }

      await this.$http(config)
        .then(res => {
          // this.$log.debug(res.data)

          if(res.data.length === 0 || res.data.length === undefined ){
            alert('더이상 불러올 상품이 없습니다.')
          } else {
            // this.$log.debug("불러오기 성공")
            // Response data 를 data 에 넣는다.
            this.$store.dispatch('loadMoreParcel', res.data)
          }
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })
    },

    /**
     * Title        : 입력값 검증
     * Status       : Actived
     * Description  : 배송상품 입력 시 값을 검증하는 로직
     */
    validation(){
      let a = this.mobileCountry.length
      let b = this.mobileNum.length

      let c = this.courierCode.length
      let d = this.courierNum.length
      this.$log.debug("[ Validation 정보 ]" ,a,b,c,d)

      if(a > 0 || b > 0){
        (a === 0)? alert("국가번호를 선택해주세요.") : ((b === 0) ? alert("모바일 번호를 입력해주세요.") : this.$log.debug("모바일 입력성공"))
      }

      if(c > 0 || d > 0){
        (c === 0)? alert("배송업체를 선택해주세요.") : ((d === 0) ? alert("송장번호를 입력해주세요.") : this.$log.debug("배송상품정보 입력성공"))
      }

      return true
    },
    modifyParcelInfo(parcelId){
      this.modifyModal = true
      this.selectedParcelId = parcelId
    },

    /**
     * Title        : 화물조회 모드 변경
     * Status       : Actived
     * Description  : ParcelLookupForm 컴포넌트 - 검색영역에서 받은 검색조건을 동기화한다.
     * Param (mode)  : 실행된 모드
     */
    changeMode(){
      
      // 배송상품조회
      // this.isDel = (this.isDel == "0" ? "1" : "0")
      
      this.description.title = (this.isDel == "0" ? "배송상품조회" :  "배송상품조회 - 삭제상품 관리")
      
      this.loadParcelList()

      this.$log.debug("[ 화물조회 모드 변경 ]", this.isDel)
    },

    /**
     * Title        : 페이지 초기화
     * Status       : Actived
     * Description  : 페이지 초기화 기능으로 등록된 화물/삭제된 삭제 모드를 변경시킨다.
     * Param (mode)  : 실행된 모드
     
    init(){
      
    }
    */

  },

  mounted() {
    this.$log.log("[ Parcel info ] mounted")

    event('배송조회', { method: 'admin' })
    
    // 배송상품조회
    // this.loadParcelList()
    
    // 화물업체 목록조회
    this.$getCourierList()

    // 화물 위치 목록조회
    this.$getParcelLocationList()
  },
}
</script>


<template>
  <q-page class="q-pa-lg">
    <div class="row q-mb-lg">
      <div class="col">
        <Title
          :desc="description">

          <q-toggle
            v-if="session.authLevel == '99'"
            v-model="isDel"
            true-value="1"
            false-value="0"
            @update:model-value="changeMode()"
            checked-icon="check"
            color="red"
            :label="(isDel == '0' ? '삭제된 상품 관리' : '삭제된 상품 관리 해제')"
            left-label
            unchecked-icon="clear"
          />

        </Title>

      </div>
    </div>

    <div class="row q-mb-sm">
      <div class="col-12 col-md-12 col-lg-10 q-mb-md">
        <ParcelLookupForm
          @lookup-opt-sync="lookupOptSync" 
          @lookupParcel="lookupParcel"/>
      </div>
      
      <div class="col-12 col-md-12 col-lg-2 q-mb-md">
        <q-btn
          color="primary"
          size="lg"
          label="배송상품검색"
          class="full-width"
          style="height:100%"
          @click="lookupParcel"/>
      </div>
    </div>
    <div class="row">
      <div class="col">
        
        <div class="comp-box">
          <ParcelList
            :is-del="isDel"
            @reload-parcel-list="loadParcelList"
            @load-more-list="loadMoreList"
          />
        </div>

      </div>
    </div>
  </q-page>

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
