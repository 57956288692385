<script>
/**
 * Type   : Component
 * Title  : 묶음배송만들기
 * Description : 신규묶음배송을 생성할 경우 사용
 * 
 */


import RegiParcelComp from "@/components/admin/RegiParcelComp.vue"
import ParcelLookupForm from "@/components/admin/ParcelLookupForm.vue"
import ParcelList from "@/components/admin/BulkableParcelList.vue"
import ParcelUnit from "@/components/common/ParcelBulkRegiForm.vue"

const _this = this

export default {
  name: "Bulk-parcel-bulider-form",
  props: {
    modiSeq : Number,
    formMode : String
  },
  components:{
    RegiParcelComp,
    ParcelLookupForm,
    ParcelList,
    ParcelUnit,
  },
  watch:{
  },
  emits:[
    "back-to-list",
    "complete-bulk"
  ],
  data() {
    return {
      receiver          : "",
      mobileCountry     : "",
      mobileNum         : "",
      customerEmail     : "",
      courierCode       : "",
      courierNum        : "",
      customerId        : "",
      filteredLocation  : [],
      parcelList        : [],
    }
  },
  methods : {

    /**
     * 묶음배송 목록으로 돌아가기
     */
    backToList(){
      this.parcelList = []
      this.$store.dispatch('clearTempBulkList')
      this.$emit('back-to-list')
    },
    
    // 검색조회 emit
    lookupOptSync(opt){
      this.$log.debug("opt = " , opt)
      this.receiver         = opt.receiver
      this.mobileCountry    = opt.mobileCountry,
      this.mobileNum        = opt.mobileNum,
      this.customerEmail    = opt.customerEmail,
      this.courierCode      = opt.courierCode,
      this.courierNum       = opt.courierNum,
      this.customerId       = opt.customerId
      this.filteredLocation = opt.filteredLocation
    },


    /** 
     * 배송상품 검색 
     */
    lookupParcel(){
      
      if(!this.validation()){
        return false
      }

      this.loadParcelList()
    },


    /**
     * 묶음 가능 배송 상품 조회
     */
    async loadParcelList() {
      let payload = {
        "receiver"        : this.receiver,
        "mobile"          : this.mobileCountry + this.mobileNum,
        "email"           : this.customerEmail,
        "courierCode"     : this.courierCode,
        "courierNum"      : this.courierNum,
        "customerNum"     : this.customerId,
        "parcelLocation"  : this.filteredLocation,
        "paging"          : 0,
      }

      // this.$log.debug("payload = ", payload)

      let config = {
        method  : "post",
        url     : "/api/parcel/parcel",
        data    : payload,
        name    : "[ Axios / 묶음가능 배송상품조회 ]"
      }

      await this.$http(config)
        .then(res => {
          // this.$log.debug("불러오기 성공")
          // this.$log.debug(res.data)

          // Response data 를 data 에 넣는다.
          // this.parcelList = res.data
          this.$store.dispatch('loadParcel', res.data)

          if(res.data.length === 0) alert("조회된 상품이 없습니다.")
        })
        .catch((err) => {
          this.$log.debug("/api/parcel/parcel", err)
      })
    },

    validation(){
      let a = this.mobileCountry.length
      let b = this.mobileNum.length

      let c = this.courierCode.length
      let d = this.courierNum.length

      if(a > 0 || b > 0){
        (a === 0)? alert("국가번호를 선택해주세요.") : ((b === 0) ? alert("모바일 번호를 입력해주세요.") : this.$log.debug("모바일 입력성공"))
      }

      if(c > 0 || d > 0){
        (c === 0)? alert("배송업체를 선택해주세요.") : ((d === 0) ? alert("송장번호를 입력해주세요.") : this.$log.debug("배송상품정보 입력성공"))
      }

      return true
    },

    /**
     * 묶음배송 신규번호 얻기
     * @param {*} _this : this 가 선언되지 않아 인자로 넘긴 값
     */    
    getNewBulkSeq: async (_this) => {

      _this.$log.debug("[ 신규 묵음배송 번호 취득 getNewBulkSeq ]")

      let configBulk = {
        method : "get",
        url : "/api/parcel/getBlukSeq",
        name : "[ Axios / 묶음배송번호 취득 ]"
      }
  
      let bulkSeq = await _this.$http(configBulk)
        .then(res => {
          let tempRtn = res.data[0].seq
          _this.$log.debug("/parcel/getBlukSeq 실행됨", tempRtn)
          return res.data[0].seq
        }).catch(res => {
          _this.$log.debug("/parcel/getBlukSeq 실패", res)
        })
  
        _this.$log.debug("bulkSeq = " + bulkSeq)
      
      return bulkSeq
    },
    
    /**
     * 묶음배송을 확정하기 위한 함수
     * 
     * 
     * 
     */
    async confirmBulk(){

      let seq

      this.$log.debug("[ ParcelBulkRegi / method / confirmBulk ] this.formMode = ", this.formMode)


      if(this.formMode == 'reg'){
        /**
         * 신규 묶음
         */
        seq = await this.getNewBulkSeq(this)

      } else if(this.formMode == 'modify'){
        /**
         * 수정
         * 1. 최초 목록과 최종 목록 비교
         * 2. 새로 추가된 상품은 getTempBulkList 에 추가 되어있으니 별개 분리 없음
         * 3. 제거된 상품은 dismiss 시킨다
         * 4. 확정시킬 묶음배송목록에 Seq 로 묶는다.
         */
        this.$log.debug("[ ParcelBulkRegi / method / confirmBulk ] this.modiSeq = ", this.modiSeq)

        // seq set
        seq = this.modiSeq

        let removeList = []
        let snapShot = this.$store.getters.getSnapShot
        let tempList = this.$store.getters.getTempBulkList

        snapShot.map(ss => {
          let cnt = 0
          tempList.map(ti => {
            this.$log.debug("[ ParcelBulkRegi / method / confirmBulk ] ti, ss = ", ti.id, ss.id)
            // 같은 걸 찾지 못한 경우
            if(ti.id == ss.id){cnt++}

          })

          if(cnt == 0){
            removeList.push(ss.id)
          }
        })

        this.$log.debug("[ ParcelBulkRegi / method / confirmBulk ] removeList = ", removeList)
        
        // dismiss list 
        let dismissPayload = {
          "id"        : removeList
        }

        let dismissConfig = {
          method  : "post",
          url     : "/api/bulkParcel/dismissById",
          data    : dismissPayload,
          name    : "[ Axios / 묶음배송상품해제 id로 in 구절 ]"
        }

        await this.$http(dismissConfig)
          .then(res => {
            this.$log.debug(res.data)
          })
          .catch((err) => {
            this.$log.debug("/api/bulkParcel/dismissById", err)
        })

      }


      let list = this.$store.getters.getTempBulkList

      let ids = list.map(item => {
        return item.id
      })

      let payload = {
        "id"        : ids,
        "bulkSeq"   : seq
      }

      let config = {
        method  : "post",
        url     : "/api/bulkParcel/setSingleBulk",
        data    : payload,
        name    : "[ Axios / 묶음배송 확정 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug(res.data)
          this.$emit('complete-bulk')
        })
        .catch((err) => {
          this.$log.debug("/api/bulkParcel/setSingleBulk", err)
      })

      
    },
  },
  mounted(){
  }
}
</script>


<template>

  <q-toolbar>
    <q-btn flat round dense icon="arrow_back_ios" @click="backToList" />
    <q-toolbar-title>
      <div class="text-h6">{{ this.formMode == 'reg' ? '신규묶음배송' : '묶음배송 ' + this.modiSeq + '번 수정' }}</div>
    </q-toolbar-title>
  </q-toolbar>

  <!-- 묶음배송으로 만들 목록 -->

  <div class="row">
    <div class="col-12 col-md-12 col-lg-10 q-mb-sm">
      <ParcelLookupForm
        @lookup-opt-sync="lookupOptSync"
        @lookupParcel="lookupParcel"/>
    </div>
    <div class="col-12 col-md-12 col-lg-2 q-mb-lg">
      <q-btn
        color="primary"
        size="lg"
        label="배송상품검색"
        class="full-width"
        style="height:100%"
        @click="lookupParcel"/>
    </div>
  </div>


  <div class="row">
    <div class="col">
      <div class="comp-box">
        <ParcelList />
      </div>
    </div>
    <div class="col">
      <div class="comp-box">

        <div id="newParcelForm" class="row items-start"> 
          
          <div class="col">
            <h6 class="q-mt-xs q-mb-md">묶음목록</h6>
          </div>

          <div class="col text-right">
            <q-btn
              color="secondary"
              size="md"
              label="묶음배송확정"
              class="q-mb-md"
              @click="confirmBulk"/>
          </div>

        </div>
        <div class="row">
          <ParcelUnit />
        </div>

      </div>
    </div>
  </div>

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>