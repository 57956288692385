<script>
/**
 * Type         : Modal
 * Title        : 배송상품등록하기
 * Description  : 신규 배송 상품 등록을 위한 모달
 *                Right to left slide
 * 
 */


import RegiParcelComp from "@/components/admin/RegiParcelComp.vue"

export default {
  name: "Modal-parcel-registration-form",
  props: {
    newParcel : Boolean,
  },
  components:{
    RegiParcelComp,
  },
  watch:{
  },
  emits:[
    "close-modal",
  ],
  data() {
    return {
    }
  },
  methods : {
    /**
     * Title      : 현재 모달 닫기
     */
    closeModalThis(){
      this.$emit('close-modal')
    }
  },
  mounted(){
  }
}
</script>


<template>
  <teleport to="body">

    <q-dialog 
      v-model="newParcel"
      id="globalModalRegiParcel"
      full-height
      :position="'right'">

      <q-card class="card-size">
        <q-toolbar>
          <q-toolbar-title>
            <div class="text-h6">배송등록</div>
          </q-toolbar-title>
          <q-btn flat v-close-popup round dense icon="close" @click="closeModalThis" />
        </q-toolbar>
        
        <!-- 
          Component 
          배송상품 등록 모달 : 
        -->
        <RegiParcelComp />
        
      </q-card>
    
    </q-dialog>

  </teleport>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-size {
  width: 700px; 
  max-width: 80vw;
  min-width: 320px;
}

.q-toolbar {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 100;
  box-shadow:0 2px 4px 2px rgb(230, 230, 230);
}
</style>