<script>
/**
 * Type : component
 * Calendar - 스케쥴러 영역에 Aone 의 일정을 나타내는 달력
 * 
 */
import {
  QCalendarMonth,
  addToDate,
  parseDate,
  parseTimestamp,
  isBetweenDates
  // today
} from '@quasar/quasar-ui-qcalendar/src/index.js'
import '@quasar/quasar-ui-qcalendar/src/QCalendarVariables.sass'
import '@quasar/quasar-ui-qcalendar/src/QCalendarTransitions.sass'
import '@quasar/quasar-ui-qcalendar/src/QCalendarMonth.sass'

// The function below is used to set up our demo data
const CURRENT_DAY = new Date()

export default ({
  components: {
    QCalendarMonth
  },
  data(){
    return {
      thisMonth : new Date().getMonth() + 1,
      calDate   : new Date(),
      currYear  : '',
      currMonth : '',
      events    : []
    }
  },
  computed: {
    eventsMap () {
      const map = {}
      if (this.events.length > 0) {
        this.events.forEach(event => {
          (map[ event.date ] = (map[ event.date ] || [])).push(event)
          if (event.days !== undefined) {
            let timestamp = parseTimestamp(event.date)
            let days = event.days
            // add a new event for each day
            // skip 1st one which would have been done above
            do {
              timestamp = addToDate(timestamp, { day: 1 })
              if (!map[ timestamp.date ]) {
                map[ timestamp.date ] = []
              }
              map[ timestamp.date ].push(event)
              // already accounted for 1st day
            } while (--days > 1)
          }
        })
      }
      this.$log.debug(map)
      return map
    }
  },
  methods:{
    /**
     * 일정조회
     * 고객을 위한 일정 조회 기능
     * 
     */
     async loadSchedule(){

      // 1일 ~ 말일 설정
      let firstDay = new Date(this.calDate.getFullYear(), this.calDate.getMonth(), 2)
      let lastDay = new Date(this.calDate.getFullYear(), this.calDate.getMonth() + 1, 1)

      let payload = {
        startDate : firstDay,
        endDate   : lastDay
      }

      let config = {
        method  : "post",
        url     : "/api/schedule/loadScheduleCustomer",
        data    : payload,
        name    : "[ Axios / 스케쥴 조회 ]"
      }

      await this.$http(config)
        .then((res) => {
          this.$log.debug("스케쥴 조회했습니다", res.data)

          // 조회된 스케쥴을 달력에 설정한다
          this.setEvents(res.data)
        })
        .catch((err) => {
          this.$log.debug(err)
          // alert('스케쥴 조회에 실패하였습니다.')
      })
    },


    /**
     * 조회된 스케쥴을 달력에 설정
     */
     async setEvents(res){
      this.$log.debug("[ Methods / setEvents ] Start ", res)

      if(res.length > 0){
        // 조회된 일정
        let temp = res.map(item => {
  
          let result = {
            id      : item.id,
            title   : item.title,
            details : item.details,
            date    : this.getCurrentDay(new Date(item.eventDate).getDate()),
            eventType : item.eventType,
            // time: '19:00',
            // duration: 180,
            days    : item.days,
            bgcolor : 'teal',
            icon    : (item.eventType == 1 ? 'directions_boat' : 'flight' )
          }
          return result
        })
  
        this.events = temp
      }
    },


    /**
     * 조회된 이벤트를 지도에 뿌릴 수 있는 형식으로 만든다
     * @param {*} dt 
     */
     getEvents (dt) {
      // this.$log.debug("[ Methods / getEvents ] Start ")

      const currentDate = parseTimestamp(dt)
      const events = []
      for (let i = 0; i < this.events.length; ++i) {
        let added = false
        if (this.events[i].date === dt) {
          if (this.events[i].time) {
            if (events.length > 0) {
              // check for overlapping times
              const startTime = parseTimestamp(this.events[i].date + ' ' + this.events[i].time)
              const endTime = addToDate(startTime, { minute: this.events[i].duration })
              for (let j = 0; j < events.length; ++j) {
                if (events[j].time) {
                  const startTime2 = parseTimestamp(events[j].date + ' ' + events[j].time)
                  const endTime2 = addToDate(startTime2, { minute: events[j].duration })
                  if (isBetweenDates(startTime, startTime2, endTime2) || isBetweenDates(endTime, startTime2, endTime2)) {
                    events[j].side = 'left'
                    this.events[i].side = 'right'
                    events.push(this.events[i])
                    added = true
                    break
                  }
                }
              }
            }
          }
          if (!added) {
            this.events[i].side = undefined
            events.push(this.events[i])
          }
        }
        else if (this.events[i].days) {
          // check for overlapping dates
          const startDate = parseTimestamp(this.events[i].date)
          const endDate = addToDate(startDate, { day: this.events[i].days })
          if (isBetweenDates(currentDate, startDate, endDate)) {
            events.push(this.events[i])
            added = true
          }
        }
      }
      return events
    },

    getCurrentDay (day) {
      const newDay = new Date(this.calDate)
      newDay.setDate(day)
      const tm = parseDate(newDay)
      return tm.date
    },



    isCssColor (color) {
      return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/)
    },

    badgeClasses (event, type) {
      const cssColor = this.isCssColor(event.bgcolor)
      const isHeader = type === 'header'
      return {
        [`text-white bg-${event.bgcolor}`]: !cssColor,
        'full-width': !isHeader && (!event.side || event.side === 'full'),
        'left-side': !isHeader && event.side === 'left',
        'right-side': !isHeader && event.side === 'right'
      }
    },

    badgeStyles (event, type, timeStartPos, timeDurationHeight) {
      const s = {}
      if (this.isCssColor(event.bgcolor)) {
        s['background-color'] = event.bgcolor
        s.color = luminosity(event.bgcolor) > 0.5 ? 'black' : 'white'
      }
      if (timeStartPos) {
        s.top = timeStartPos(event.time) + 'px'
      }
      if (timeDurationHeight) {
        s.height = timeDurationHeight(event.duration) + 'px'
      }
      s['align-items'] = 'flex-start'
      return s
    }
  },
  mounted(){
    this.loadSchedule()
  }
})
</script>

<template>
  <div class="section section-04">
    <div class="container doc-page q-pt-xl q-pb-xl">
      <div class="column items-center">
        <div class="col mt-3">
          <h4 class="section-title">schedule</h4>
        </div>
      </div>
      <div class="column">
        <div class="col col-12 text-center q-mt-md q-mb-xl">
          <div class="display-text-1">{{ thisMonth }}월 항공&해상 스케줄표</div>
          <p class="display-text-2">* 모든 스케줄은 항공사 및 운송사 사정에 따라 변동될 수 있습니다.</p>
        </div>
        <div class="col col-12 col-sm-10 col-md-8 mt-3 mb-4">
          <QCalendarMonth
            ref="calendar"
            view="month"
            locale="ko-kr"
            :day-height="100">

            <template #day="{ scope: { timestamp } }">
              <template v-for="(event, index) in getEvents(timestamp.date)">
                <q-badge
                  style="width: 100%; cursor: pointer; height: 18px; max-height: 18px"
                  :class="badgeClasses(event, 'day')"
                  :style="badgeStyles(event, 'day')"
                  @click="selectSchedule(event)"
                >
                  <q-icon v-if="event.icon" 
                    :name="event.icon" 
                    class="q-mr-xs">
                  </q-icon>
                  <span class="ellipsis">
                    {{ event.title }}
                  </span>
                  <q-tooltip>{{ event.details }}</q-tooltip>
                </q-badge>
              </template>
            </template>
          </QCalendarMonth>
          <!--
          <img class="w-100" :src="require(`@/assets/imgs/main/schedule-202209.jpg`)">
          -->
        </div>
      </div>
      <!--
      <div class="row justify-content-center">
        <div class="col-12 col-sm-7 col-md-5">
          <div class="row">
            <div class="col-12 col-md-6 mb-2">
              <img :src="require(`@/assets/imgs/main/icon-schedule-flight-k-to-s.png`)">
              <span class="ms-2">KOR -> SYD 항공</span>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <img :src="require(`@/assets/imgs/main/icon-schedule-flight-s-to-k.png`)">
              <span class="ms-2">SYD -> KOR 항공</span>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <img :src="require(`@/assets/imgs/main/icon-schedule-ocean-k-to-s.png`)">
              <span class="ms-2">KOR -> SYD 해상</span>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <img :src="require(`@/assets/imgs/main/icon-schedule-ocean-s-to-k.png`)">
              <span class="ms-2">SYD -> KOR 해상</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-5 col-md-3">
          <div class="row">
            <div class="col d-flex justify-content-center">
              <button class="btn btn-in-main">운임정보 확인하기</button>
            </div>
          </div>
        </div>
      </div>
      -->
    </div>
  </div>
</template>



<style lang="scss" scoped>
@import "@/assets/style/variations.scss";
.section {

  &.section-04 {
    background: rgb(255, 255, 255);
    padding-bottom: 3rem;

    .display-text-1 {
      font-family: 'IBMPlexSansKR-SemiBold';
      font-weight: 700;
      font-size: $display-text-1-size;
      color: $primary-color;
    }

    .display-text-2 {
      font-size: 1.75rem;
      color: $primary-color;
    }
  }
}
</style>