<script>
import { ref } from 'vue'

export default {
  name: "Modal-schedule-registration-form",
  props: {
    showModal : Boolean,
    selected  : Object,
    mode      : String
  },
  watch:{
  },
  emits:[
    "close-modal",
    "reload-schedule"
  ],
  components:{},
  data() {
    return {
      title       : this.selected.title,
      description : this.selected.details,
      selectedEventType : this.selected.eventType,
      eventType   : this.selected.eventType,
      eventTypeOption   : [
        {label : '해상' , value : 1},
        {label : '항공' , value : 2}
      ],
      eventDate   : ref(this.selected?.date?.replaceAll('-','/')),
      eventTime   : '',
      duration    : 0,
      days        : this.selected.days,
    }
  },
  methods : {
    /** 일정추가 */
    async controlUnit(){

      let urlSet = (this.mode == 'add' ? "/api/schedule/reg" : "/api/schedule/modify")

      let modeTxt = (this.mode == 'add' ? "등록" : "수정")

      let payload = {
        title       : this.selected.title,
        description : this.selected.description,
        eventType   : this.selected.selectedEventType,
        eventDate   : this.selected.eventDate,
        eventTime   : '',
        duration    : 0,
        days        : 0
      }
      
      let config = {
        method  : "post",
        url     : urlSet,
        data    : payload,
        name    : "[ Axios / 스케쥴 " + modeTxt + " ]"
      }
  
      await this.$http(config)
        .then(() => {
          this.$log.debug("스케쥴을 " + modeTxt + "했습니다.")
  
          this.$emit('reload-schedule')
          this.$emit('close-modal')
          
          alert("스케쥴을 " + modeTxt + "했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert("스케쥴을 " + modeTxt + "에 실패하였습니다.")
      })
    },

    /**
     * Title        : 스케쥴 삭제
     * Status       : Actived
     * Description  : 선택된 스케쥴 삭제
     */
    async deleteUnit(){
      this.$log.debug("스케쥴을 삭제실행")

      let payload = {
        id : this.selected.id
      }
      
      let config = {
        method  : "post",
        url     : "/api/schedule/delete",
        data    : payload,
        name    : "[ Axios / 스케쥴 삭제 ]"
      }
  
      await this.$http(config)
        .then(() => {
          this.$log.debug("스케쥴을 삭제했습니다.")
  
          this.$emit('reload-schedule')
          this.$emit('close-modal')
          
          alert("스케쥴을 삭제했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert("스케쥴을 삭제에 실패하였습니다.")
      })
    },

    closeModal(){
      this.$emit('close-modal')
    }
  },
  mounted() {
    
  },
}
</script>

<template>
  <teleport to="body">

    <q-dialog 
      v-model="showModal">

      <q-layout 
        container
        class="bg-white"
        view="lHh lpr lFf">

        <q-header 
          class="bg-primary"
          elevated>
          <q-toolbar>
            <q-toolbar-title>일정 {{ (mode == 'add' ? "등록" : "수정") }}</q-toolbar-title>
            <q-btn 
              flat v-close-popup round dense icon="close" 
              @click="closeModal" />
          </q-toolbar>
        </q-header>

        <q-page-container>
          <q-page padding>

            <div class="row">
              <div class="col q-mb-md">
                <q-input 
                  v-model="selected.title" 
                  label="제목" 
                  placeholder="달력에 노출되는 제목입니다. 최대한 짧게 써주세요"/>
              </div>
            </div>
            
            <div class="row">
              <div class="col">
                <q-input 
                  v-model="selected.details" 
                  label="설명" 
                  type="textarea"
                  placeholder="일정에 mouse over 했을 시 볼 수 있는 일정의 설명입니다"/>
              </div>
            </div>

            <div class="row q-mb-md">
              <div class="col">
                <q-select 
                  v-model="selected.eventType" 
                  :options="eventTypeOption"
                  behavior="menu"
                  label="일정타입"
                  emit-value
                  map-options />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <q-date v-model="eventDate" />
              </div>
            </div>

          </q-page>
        </q-page-container>

        <q-footer 
          class="bg-white text-black">
          <q-toolbar>

            <q-btn 
              v-if="this.mode == 'modify'"
              color="red"
              class="btn full-width"
              label="삭제"
              @click="deleteUnit" />
            
            <q-btn 
              color="primary"
              class="btn full-width"
              :label="(this.mode == 'add' ? '추가' : '수정')"
              @click="controlUnit" />

          </q-toolbar>
        </q-footer>
      </q-layout>
    </q-dialog>
  </teleport>
</template>