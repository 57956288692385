<script>
// import CodeRegForm from "@/components/admin/modal/CodeRegiFormUnit.vue"

export default {
  name: "code-list",
  components:{
    // CodeRegForm,
  },
  props:{
    codeList: Array,
  },
  emits:[
    "load-list",
  ],
  data() {
    return {
      // codeList: [],
      showModalNow : false,
      mode : 0,
      initialPagination : {
        rowsPerPage: 30
        // rowsNumber: xx if getting data from a server
      },
      columns : [
        { name: 'code', label: '코드', align: 'center', field: 'code' },
        { name: 'parentCode', label: '부모코드', align: 'center', field: 'parentCode' },
        { name: 'description', label: '설명', align: 'center', field: 'description' },
        { name: 'codeLabel', label: '라벨', align: 'center', field: 'codeLabel' },
        { name: 'value', label: '키값', align: 'center', field: 'value' },
        { name: 'status', label: '상태', align: 'center', field: 'status' },
        { name: 'detail', label: '추가기능', align: 'center', field: 'detail' },
      ],
    }
  },
  methods:{
    singleDelete (codeId) {
      this.$log.debug("개별삭제 실행")
      this.$log.debug("선택된 택배 Id", codeId)

      let payload = {
        "id" : codeId
      }

      this.$log.debug("payload = ", payload)

      this.delete(payload)
    },

    async delete (payload) {

      let config = {
        method  : "post",
        url     : "/api/code/delCode",
        data    : payload,
        name    : "코드 삭제"
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("삭제했습니다.")

          this.$emit("load-list")

          alert("삭제했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('삭제에 실패했습니다')
      })
    },


    /**
     * Title        : 코드 상태 변경
     * Status       : Actived
     * Description  : 솔루션 코드 상태를 변경한다.
     */
    async updateStatus (id, status) {

      let payload = {
        id      : id,
        status  : status
      }

      let config = {
        method  : "post",
        url     : "/api/code/updateCodeStatus",
        data    : payload,
        name    : "코드 상태 변경"
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("상태변경에 성공했습니다.")

          this.$emit("load-list")

          alert("상태를 변경했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('상태변경에 실패했습니다')
      })
    },
  },
  mounted() {
    this.$log.debug("Code list component mounted")
    // this.loadCodeList()
  },
}
</script>

<template>

  <q-table
    :rows="codeList"
    :columns="columns"
    :rows-per-page-options="[0]"
    :virtual-scroll-sticky-size-start="48"
    :pagination="initialPagination"
    row-key="name">

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="code" :props="props">
          {{ props.row.code }}
        </q-td>
        <q-td key="parentCode" :props="props">
          {{ props.row.parentCode == null ? '-' : props.row.parentCode }}
        </q-td>
        <q-td key="description" :props="props">
          {{ props.row.description }}
        </q-td>
        <q-td key="codeLabel" :props="props">
          {{ props.row.codeLabel }}
        </q-td>
        <q-td key="value" :props="props">
          {{ props.row.value }}
        </q-td>
        <q-td key="status" :props="props">
          <q-toggle
            v-model="props.row.status"
            :true-value="0"
            :false-value="1"
            @update:model-value="updateStatus(props.row.id, props.row.status)"
            checked-icon="check"
            color="green"
            unchecked-icon="clear"
          />
        </q-td>
        <q-td key="detail" :props="props">
          <q-btn-dropdown 
            color="primary" 
            label="기능선택"
            size="sm">
            <q-list>
              <q-item
                clickable v-close-popup
                @click="singleDelete(props.row.id)">
                <q-item-section>
                  <q-item-label>삭제</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
