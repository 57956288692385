<script>
/**
 * Type         : Page component
 * Title        : 대쉬보드
 * Description  : 화면
 * 
 */

// import Title from "@/components/admin/common/TitleArea.vue"

export default {
  name: 'Page-dashboard',
  meta: {
    title: "어드민 - 대쉬보드"
  },
  components: {
    // Title,
  },
  data() {
    return {
      description   : {
        title : '대쉬보드'
      },
      dbData : {
        parcelCnt       : 0,
        pickupReqCnt    : 0,
        newCustomerCnt  : 0
      }
    }
  },

  methods:{
    /**
     * Title        : 대쉬보드 데이터 조회
     * Status       : Actived
     * Description  : -
     */
     async loadDashboard() {

      let payload = {
        authLv : "99",
      }

      // this.$log.debug("payload = ", payload)

      let config = {
        method  : "post",
        url     : "/api/dashboard/",
        data    : payload,
        name    : "[ Axios / 대쉬보드 / 기본 설정 호출 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug("불러오기 성공")
          this.$log.debug(res.data)

          // Response data 를 data 에 넣는다.
          this.dbData = res.data

        })
        .catch((err) => {
          this.$log.debug("/api/dashboard/", err)
      })
    },
  },
  mounted() {
    this.$log.log("[ Dashboard ] mounted")
    
    // 배송상품조회
    this.loadDashboard()
    
  },
}
</script>

<template>
  <q-page class="q-pa-lg">

    <!--
    <div class="row q-mb-lg">
      <div class="col">

        <Title
          :desc="description">
        </Title>
        
      </div>
    </div>
    -->
    <div class="row">
      <div class="col">
        <div class="dashboard-area-1">
          <div class="comp-box">
            <h3 class="title">오늘 등록된 화물</h3>
            <div class="count">
              {{ dbData.parcelCnt }}
            </div>
          </div>
          <div class="comp-box">
            <h3 class="title">오늘 픽업 등록 요청된 화물</h3>
            <div class="count">
              {{ dbData.pickupReqCnt }}
            </div>
          </div>
          <div class="comp-box">
            <h3 class="title">오늘 신규등록 고객</h3>
            <div class="count">
              {{ dbData.newCustomerCnt }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-page>

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dashboard-area-1 {
  display       : flex;
  width         : 100%;
  flex-wrap     : wrap;
  flex-direction: row;

  .comp-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 1.2rem;
      margin-top: 0;
    }

    .count {
      font-size: 2rem;
    }
  }
}

</style>
