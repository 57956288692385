<script>
import { ref } from 'vue'
import ModalParcelModiForm from "@/components/common/modal/ParcelInfoForm.vue"
import PdfPreviewer from '@/components/admin/modal/PdfPreview.vue'

export default {
  name: "Parcel-list",
  components: {
    ModalParcelModiForm,
    PdfPreviewer
  },
  computed: {
    parcelList() {
      return this.$store.getters.getParcelList
    },
  },
  emits: [],
  data() {
    return {
      modifyModalShow : false,
      pdfModalShow : false,
      paging : 0,
      selectedList: ref([]),  // 선택된 parcel을 저장
      columns : [
        { 
          name: 'rownum', 
          label: 'ID', 
          align: 'center', 
          field: 'id', 
          format: val => `${val}`,
          sortable: true
        },
        { name: 'customerName', label: '회원명', align: 'center', field: 'customerName' },
        { name: 'bulkSeq', label: '묶음배송번호', align: 'center', field: 'bulkSeq' },
        { name: 'bulkCnt', label: '묶음배송갯수', align: 'center', field: 'bulkCnt' },
        { name: 'courierName', label: '택배사', align: 'center', field: 'courierName' },
        { name: 'courierNum', label: '송장번호', align: 'center', field: 'courierNum' },
        { name: 'weight', label: '무게', align: 'center', field: 'weight' },
        { name: 'receiver', label: '수취인', align: 'center', field: 'receiver' },
        { name: 'mobileCountry', label: '수취인 국가코드', align: 'center', field: 'mobileCountry' },
        { name: 'mobileNum', label: '수취인 전화번호', align: 'center', field: 'mobileNum' },
        { name: 'pickupLocation', label: '픽업지', align: 'center', field: 'pickUpLocationName', sortable: true },
        { name: 'parcelLocationName', label: '위치', align: 'center', field: 'parcelLocationName', sortable: true },
        { name: 'regDate', label: '등록일', align: 'center', field: 'regDate' },
      ],
      initialPagination : {
        rowsPerPage: 30
        // rowsNumber: xx if getting data from a server
      },
      userType: 'admin'
    }
  },
  methods:{
    addToTempBulk() {
      this.$log.debug('[ 임시목록에 추가 ] ')
      this.$store.dispatch('addToBulkList', this.selectedList)
    }
  }
}
</script>


<template>

  <div class="row q-mb-sm">
    <div class="col text-right">
      <!-- 버튼 - Bulk 목록에 추가 -->
      <q-btn
        color="secondary"
        label="임시묶음에 추가"
        class="q-ml-sm"
        icon-right="arrow_right_alt"
        @click="addToTempBulk"
        :disable="selectedList.length < 1">
      </q-btn>
    </div>
  </div>

  <div class="row q-mb-sm">
    <div class="col">

      <q-table
        class="my-sticky-virtscroll-table"
        virtual-scroll
        v-model:selected="selectedList"
        selection="multiple"
        :rows-per-page-options="[0]"
        :virtual-scroll-sticky-size-start="48"
        :rows="parcelList"
        :columns="columns"
        :pagination="initialPagination"
        row-key="id">

      </q-table>
    </div>
  </div>

</template>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
