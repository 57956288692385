<script>
// import axios from 'axios'
// import qs from 'qs'

export default {
  name: "Modal-view-notice",
  props: {
    showModal : Boolean,
    selectedNotice : Object,
    // viewerMode : String,
  },
  watch: {
    showModal(newVal){
      this.title    = this.$props.selectedNotice.title
      this.contents = this.$props.selectedNotice.contents
    }
  },
  emits:[
    "close-modal",
    "add-item",
    "modify-item"
  ],
  components:{

  },
  data() {
    return {
      title         : "",
      contents      : "",
      delayedActive : false
    }
  },
  methods : {
    closeModal(){
      this.$emit("close-modal")
    },
  }
}
</script>


<template>
  <teleport to="body">
    <q-dialog 
      v-model="showModal">

      <q-layout 
        container
        class="bg-white"
        view="lHh lpr lFf">
        <q-header 
          class="bg-primary"
          elevated>
          <q-toolbar>
            <q-toolbar-title>공지사항</q-toolbar-title>
            <q-btn flat v-close-popup round dense icon="close" @click="closeModal" />
          </q-toolbar>
        </q-header>

        <q-page-container>
          <q-page padding>
            <div class="row">
              <div class="col">
                <q-input 
                  v-model="title"
                  label="제목"
                  stack-label 
                  readonly/>
              </div>
            </div>
            <div class="row q-mt-lg">
              <div class="col">
                <div v-html="contents"></div>
              </div>
            </div>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>
    
  </teleport>
</template>