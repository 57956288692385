<script>

export default {
  name: "Modal-pickup-form",
  props: {
    showModal : Boolean,
    mode : Number,
  },
  watch:{
    showModal:{
      handler(newVal) {
        // 상품 추가 모드/상품 수정 모드 여부 판단
        const mode = this.mode
        const idx = this.idx

        this.$log.debug("Created parcel id = " + idx )
        if(mode === 0){
          // 상품추가모드
          this.$log.debug("상품추가모드")
          this.code  = ""
          this.name  = ""
          this.country        = ""
          this.region         = ""
          this.memo           = ""
        } else if(mode === 1){
          // 상품수정모드
          this.$log.debug("상품수정모드")
          this.setDataInput(idx)
        }
      },
      immediate: true,
      deep: true,
    }
  },
  emits:[
    "close-modal",
    "add-item",
    "modify-item"
  ],
  data() {
    return {
      class   : "",
      code    : "",
      name    : "",
      country : "",
      region  : "",
      memo    : "",
    }
  },
  methods : {
    addItem(){
      const newData = {
        class      : this.class,
        code       : this.code,
        name       : this.name,
        country    : this.country,
        region     : this.region,
        memo       : this.memo,
      }
      this.$emit("add-item", newData)
      this.$emit("close-modal")
    },
    modifyItem(){
      this.$log.debug("modifyItem executed")
      const modifyData = {
        class      : this.class,
        code       : this.code,
        name       : this.name,
        country    : this.country,
        region     : this.region,
        memo       : this.memo,
      }
      this.$log.debug("modifyItem data = ", modifyData)
      this.$log.debug("modifyItem idx = ", this.idx)
      this.$emit("modify-item", {data : modifyData, idx: this.idx})
      this.$emit("close-modal")
    },
    setDataInput(idx){
      let target = this.$store.state.newParcelList[idx]
      this.$log.debug("idx = ", idx)
      this.$log.debug("target = ", target)
      this.class      = target.target
      this.code = target.code
      this.name = target.name
      this.country    = target.country
      this.region     = target.region
      this.memo       = target.memo
    }
  }
}
</script>

<template>
  <teleport to="body">

    <q-dialog 
      v-model="showModal">

      <q-layout 
        container
        class="bg-white">
        <q-header class="bg-primary">
          <q-toolbar>
            <q-toolbar-title>배송상품위치 추가</q-toolbar-title>
            <q-btn flat v-close-popup round dense icon="close" />
          </q-toolbar>
        </q-header>

        <q-footer class="bg-white text-black">
          <q-toolbar>
            <q-btn
              v-if="mode === 0"
              label="추가"
              class="full-width"
              color="primary"
              @click="addItem">
            </q-btn>
          </q-toolbar>
        </q-footer>

        <q-page-container>
          <q-page
            padding>
            <div class="row q-mb-sm">
              <div class="col q-pr-sm">

                <q-input 
                  v-model="class"
                  label="배송상품 위치 분류코드"
                  maxlength="8"
                  placeholder="영문, 영문 + 숫자 8자리"/>    

              </div>
              <div class="col q-pr-sm">
                <q-input 
                  v-model="country"
                  label="국가코드"
                  maxlength="3"
                  placeholder="ex)'KOR', 'AUS'"/>
              </div>

              <div class="col">
                <q-input 
                  v-model="region"
                  label="지역"
                  maxlength="3"
                  placeholder="ex)'BNE, SYD'"/>
              </div>
            </div>
            <div class="row">
              <div class="col q-pr-sm">
  
                <q-input 
                  v-model="code"
                  label="배송상품위치 코드"
                  maxlength="8"
                  placeholder="영문, 영문 + 숫자 8자리"/>    
  
              </div>
              <div class="col">
  
                <q-input 
                  v-model="name"
                  maxlength="100"
                  label="배송상품위치 이름" />    
  
              </div>
            </div>
            <div class="row">
              <div class="col">
                <q-input 
                  v-model="memo"
                  label="메모"
                  maxlength="400"
                  placeholder="해당 배송상품위치에 대한 메모를 남겨주세요."/>
              </div>
            </div>

          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>

  </teleport>
</template>
