<script>
// import axios from 'axios'
// import qs from 'qs'
import Title from "@/components/admin/common/TitleArea.vue"

export default {
  name: "Admin-update-report",
  meta: {
    title: "어드민-업데이트 리포트"
  },
  components: {
    Title,
  },
  data() {
    return {
      description   : {
        title : '업데이트 리포트'
      },
      updateReport : [
        { 
          ver : '0.7.3',
          reports : [
            '픽업지 추가시 폼 코드영역 셀렉트 리스트로 변경',
            '배송등록 폼 디자인 보완'
          ]
        },
        { 
          ver : '0.7.2',
          reports : [
            '코드관리에 부모코드 컬럼 생성'
          ]
        },
        { 
          ver : '0.7.1',
          reports : [
            '브라우져 탭에 페이지 명 각 페이지별 적용',
            '페이지 이동시 스크롤을 항상 페이지 상단으로 이동'
          ]
        },
        { 
          ver : '0.7.0',
          reports : [
            '코드관리 기능 추가'
          ]
        },
        { 
          ver : '0.6.0',
          reports : [
            '업데이트 리포트 메뉴 생성'
          ]
        },
        { 
          ver : '0.5.9',
          reports : [
            '배송상품조회 최초 진입시 날짜설정 오류 수정',
            '소통관리 공지사항 기능 강화'
          ]
        }
      ]
    }
  },
  /*
  setup(){
  },
  */

  methods: {
    
  },
  
  mounted() {
    this.$log.info("[ Page / 업데이트 리포트 ]")
  },
}
</script>


<template>
  
  <q-page class="q-pa-lg">
    <div class="row">
      <div class="col">
        <Title
          :desc="description">
        </Title>
      </div>
    </div>

    <div class="row q-mt-lg">
      <div class="col">
        <div class="comp-box">
          
          <!-- S : 업데이트 리스트 -->
          <div class="row q-mb-sm">
            <div class="col">
              <h4></h4>
              <q-markup-table
                flat
                bordered>
                <thead>
                  <tr>
                    <th>Version</th>
                    <th>업데이트 내용</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in updateReport">
                    <td>{{item.ver}}</td>
                    <td>
                      <ul>
                        <li v-for="report in item.reports">{{ report }}</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </q-markup-table>
            </div>
          </div>
          <!-- E : 업데이트 리스트 -->

        </div>
      </div>
    </div>
  </q-page>

</template>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.update-head {
  margin-top: 1rem;
  margin-bottom: .15rem;
}
thead {
  th {
    font-size: 1.25rem;
  }
}
tbody {
  td {
    margin-top: .25rem;
    margin-bottom: .25rem;

    &:first-child {
      font-size: 1.5rem;
      text-align: center;
      width: 150px;
    }
  }
}
</style>
