<script>
// import axios from 'axios'
// import qs from 'qs'

export default {
  name: "Pickup-request-id",
  props: {
    showModal : Boolean,
    pickUpId  : Number,
  },
  emits:[
    "close-modal"
  ],
  components:{
  },
  data() {
  },
  methods : {
    closeModal(){
      this.$emit("close-modal")
    },
  }
}
</script>


<template>
  <teleport to="body">
    <q-dialog 
      v-model="showModal"
      persistent>

      <q-card style="width: 400px; max-width: 80vw;">

        <q-card-section class="q-pa-lg">
          <div class="row">
            <div class="col">
              <h5 class="text-center">픽업요청번호는</h5>
              <h2 class="text-center">[ RPAK{{ pickUpId }} ]</h2>
              <h5 class="text-center">입니다.</h5>
            </div>
          </div>
        </q-card-section>

        <q-card-actions 
          align="right" 
          class="bg-white text-teal"
          style="margin-top:20px;">
          <q-btn 
            label="확인"
            color="primary"
            class="full-width"
            @click="closeModal()" 
            v-close-popup 
            />

        </q-card-actions>
      </q-card>

    </q-dialog>
    
  </teleport>
</template>