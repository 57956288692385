<script>
import qs from 'qs'
import { quillEditor } from 'vue3-quill'

export default {
  name: "Modal-notice-registration-form",
  props: {
    showModal : Boolean,
    editorMode : Number,
    noticeData : Object,
  },
  emits:[
    "close-modal",
    "update-list"
  ],
  components:{
    quillEditor
  },
  data() {
    return {
      quillData : {
        contents      : "",
        _contents     : "",
        editorOption  : {
          placeholder: '공지사항을 작성해주세요',
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              // [{ direction: 'rtl' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              // [{ color: [] }, { background: [] }],
              // [{ font: [] }],
              // [{ align: [] }],
              ['clean'],
              [
                'link',
                'image',
                // 'video'
              ]
            ]
            // other moudle options here
            // otherMoudle: {}
          },
          // more options
        },
        disabled: false
      },
    }
  },
  methods : {
    /**
     * 공지사항 추가
     */
    async addNotice(){
      let payload = {
        title    : this.noticeData.title,
        writer   : this.noticeData.writer,
        contents : this.noticeData.contents,
        isFixed  : this.noticeData.isFixed,
        isShown  : this.noticeData.isShown,
      }

      // 필수 입력항목 검증
      let result = this.$checkEmptyVModelList(payload)
      if(result.length > 0){
        alert("모든 항목을 입력해주세요.")
        return false
      }

      this.$log.debug("payload = ", payload)

      let config = {
        method : "post",
        url    : "/api/board/addNotice",
        data   : qs.stringify(payload),
        name   : "[ Axios / 공지사항 추가 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug("등록 성공")
          this.$log.debug(res.data)
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('등록 실패!')
      })
      this.initInput()
      this.$emit("close-modal")
    },

    /**
     * 공지사항 수정
     */
    async editNotice(){

      let payload = {
        id        : this.noticeData.id,
        title     : this.noticeData.title,
        writer    : this.noticeData.writer,
        contents  : this.noticeData.contents,
        isFixed   : (this.noticeData.isFixed) ? 1 : 0,
        isShown   : (this.noticeData.isShown) ? 1 : 0,
      }

      this.$log.debug("payload = ", payload)

      let config = {
        method : "post",
        url    : "/api/board/editNotice",
        data   : qs.stringify(payload),
        name   : "[ Axios / 공지사항 수정 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$emit("update-list", payload)
          this.$log.debug("수정 성공 / 수정된 내용 => ", payload)
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('수정 실패!')
        })

      this.initInput()
      this.$emit("close-modal")
    },

    /**
     * inputs 초기화
     */
    initInput(){
      this.title    = ""
      this.contents = ""
      this.isFixed  = 1
      this.isShown  = 0
    },

    /**
     * 모달 닫기
     */
    closeModal(){
      this.initInput()
      this.$emit("close-modal")
    },
  },
  mounted() {
    this.$log.debug("Notice registration modal mounted")
    
    // vuex store 에서 로그인상태 조회 후 배송상품 조회
    const sess = JSON.parse(sessionStorage.getItem('vuexStore'))
    this.$log.debug("checkSession sess = ", sess)
    if(sess !== undefined && sess !== null){
      this.writer = sess.user.localSession.id
    }
  },
}
</script>

<template>
  <teleport to="body">

    <q-dialog 
      v-model="showModal">

      <q-layout 
        container
        class="bg-white"
        view="lHh lpr lFf">
        <q-header 
          class="bg-primary"
          elevated>
          <q-toolbar>
            <q-toolbar-title>공지사항등록/수정</q-toolbar-title>
            <q-btn flat v-close-popup round dense icon="close" @click="closeModal" />
          </q-toolbar>
        </q-header>

        <q-page-container>
          <q-page padding>
            <div class="row">
              <div class="col-12 q-mb-md">
                <q-checkbox 
                  v-model="noticeData.isShown" 
                  label="노출여부" 
                  :true-value="0"
                  :false-value="1"/>
                <q-checkbox 
                  v-model="noticeData.isFixed" 
                  label="고정글"
                  :true-value="0"
                  :false-value="1"/>
              </div>
            </div>
            <div class="row">
              <div class="col q-mb-md">
                <q-input 
                  v-model="noticeData.title" 
                  label="제목" 
                  stack-label
                  :disalbe="(editorMode == 0 ? true : false)"
                  />
              </div>
            </div>
            <div class="row">
              <div class="col" style="height:400px;">
                <quillEditor 
                  v-model:value="noticeData.contents"
                  :options="quillData.editorOption"
                  contentType="html" />
              </div>
            </div>
          </q-page>
        </q-page-container>

        <q-footer 
          class="bg-white text-black">
          <q-toolbar>
            <q-btn 
              v-if="editorMode === 1"
              color="secondary"
              class="btn full-width"
              label="수정"
              @click="editNotice" />
            <q-btn 
              v-if="editorMode === 0"
              color="primary"
              class="btn full-width"
              label="추가"
              @click="addNotice" />
          </q-toolbar>
        </q-footer>
      </q-layout>
    </q-dialog>
  </teleport>
</template>