<script>
// import axios from 'axios'

export default {
  name: "AdminSideBar",
  props:{
    drawer : {
      type : Boolean,
      default : false
    }
  },
  data(){
    return {
      session     : this.$store.getters.getLocalSession,
      isSignedIn  : this.$store.getters.getSessionStatus,
    }
  },
  
  watch:{
    "$store.getters.getSessionStatus"(val){
      this.$log.debug("watch this.session = ", val)

      if(val){
        this.$log.debug("로그인 상태 this.session = ", this.session)
        this.$log.debug("로그인 상태 this.$store.getters.getLocalSession = ", this.$store.getters.getLocalSession)
        let authLv = this.session.authLevel

        if(authLv == "00"){
          alert("관리자가 아닙니다.")
          this.$router.push("/")
        }
      } else {
        this.$log.debug("로그인 상태 아님")
      }
    },
  },
  methods:{
    /**
     * Title        : 사인아웃
     * Status       : Actived
     * Description  : -
     */
    async signOut(){

      let config = {
        method : "post",
        url : "/api/admin/signOut",
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug("/admin/signout 실행됨", res)
          if(res.status === 200){

            this.$store.dispatch('destroyCustomerInfo')
            sessionStorage.clear()

            this.$router.push("/admin")

          } else {
            alert(res.info.reason)
          }
      }).catch(res => {
        this.$log.debug("/admin/signOut 실패함 catch", res)
        alert("로그아웃 중 오류가 발생했습니다.")
      })
    }
  },
  mounted(){
    this.$log.debug("Admin Nav mounted!!!!!!")

    // TODO : Session storage 사용법
    // 로그인 권한 체크
    // 관리자 페이지 이므로 관리자가 아닌 경우 일반사이트로 이동을 시킨다.
    const sess = JSON.parse(sessionStorage.getItem('vuexStore'))
    
    let sessionData = sess.user
    let authLv = sessionData.localSession.authLevel
    

    // 관리자가 아닐 경우. 일반 사이트 페이지로 튕겨낸다.
    if(authLv == '00'){
      alert("관리자가 아닙니다.")
      this.$router.push("/")
    } else {
    this.$log.debug("관리자입니다.")
    }
  }
}
</script>

<template>

  <q-drawer
    v-model="drawer"
    show-if-above
    :width="200"
    :breakpoint="400"
    >
    <q-scroll-area 
      style="height: calc(100% - 150px); margin-top: 150px; border-right: 1px solid #ddd;">

      <q-list class="list-background">

        <!--
          배송상품관리 메뉴 그룹
        -->
        <q-item v-ripple class="class-divider">
          <q-item-section avatar>
            <q-icon name="inbox" />
          </q-item-section>

          <q-item-section>
            배송상품관리
          </q-item-section>
        </q-item>
        
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/parcel-info')" >
          <q-item-section>
            배송상품조회
          </q-item-section>
        </q-item>
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/parcel-bulk')">
          <q-item-section>
            묶음배송관리 
          </q-item-section>
        </q-item>
        <!--
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/parcel-regi')">
          <q-item-section>
            배송상품 EXCEL 업로드
          </q-item-section>
        </q-item>
        -->
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/pickup-request-list')">
          <q-item-section>
            Export 픽업요청목록
          </q-item-section>
        </q-item>

        <q-separator />

        <!--
          화물운송편관리 메뉴 그룹
        -->
        <div v-if="session.authLevel === '99'">
          <q-item v-ripple class="class-divider">
            <q-item-section avatar>
              <q-icon name="flight" />
            </q-item-section>

            <q-item-section>
              화물운송편관리
            </q-item-section>
          </q-item>
          
          <q-item 
            clickable v-ripple
            @click="this.$router.push('/admin/freight-manage')" >
            <q-item-section>
              운송편 관리
            </q-item-section>
          </q-item>
          
          <q-separator />
        </div>
        <!--
          스케쥴관리 메뉴 그룹
        -->
        <q-item v-ripple class="class-divider">
          <q-item-section avatar>
            <q-icon name="date_range" />
          </q-item-section>

          <q-item-section>
            스케쥴러
          </q-item-section>
        </q-item>
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/manage-schedule')" >
          <q-item-section>
            일정관리
          </q-item-section>
        </q-item>

        <q-separator />
        
        <!--
          배송설정관리 메뉴 그룹
        -->
        <q-item v-ripple class="class-divider">
          <q-item-section avatar>
            <q-icon name="local_shipping" />
          </q-item-section>

          <q-item-section>
            배송관리
          </q-item-section>
        </q-item>
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/courier-manage')" 
          >
          <q-item-section>
            택배사관리
          </q-item-section>
        </q-item>
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/pickup-manage')" 
          >
          <q-item-section>
            픽업관리
          </q-item-section>
        </q-item>
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/location-manage')" 
          >
          <q-item-section>
            배송상품위치관리
          </q-item-section>
        </q-item>

        <q-separator />

        <!--
          고객관리 메뉴 그룹
        -->
        <q-item v-ripple class="class-divider">
          <q-item-section avatar>
            <q-icon name="manage_accounts" />
          </q-item-section>
          <q-item-section>
            고객관리
          </q-item-section>
        </q-item>
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/customer-manage')" 
          >
          <q-item-section>
            고객정보조회
          </q-item-section>
        </q-item>

        <q-separator />

        <!--
          게시판 메뉴 그룹
        -->
        <q-item v-ripple class="class-divider">
          <q-item-section avatar>
            <q-icon name="list" />
          </q-item-section>
          <q-item-section>
            소통관리
          </q-item-section>
        </q-item>
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/notice')" 
          >
          <q-item-section>
            공지사항
          </q-item-section>
        </q-item>

        <q-separator />

        <!--
          시스템 관리
        -->
        <q-item v-ripple class="class-divider">
          <q-item-section avatar>
            <q-icon name="settings" />
          </q-item-section>
          <q-item-section>
            시스템 관리
          </q-item-section>
        </q-item>
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/code-management')" 
          >
          <q-item-section>
            코드관리
          </q-item-section>
        </q-item>
        <q-item 
          clickable v-ripple
          @click="this.$router.push('/admin/update-report')" 
          >
          <q-item-section>
            Update report
          </q-item-section>
        </q-item>

        <q-separator />



        <!------------------------
        --------------------------  
        최고 관리자만 확인 가능한 메뉴
        --------------------------
        ------------------------->
        <div
          v-if="session.authLevel === '99'">
          
          <q-item v-ripple class="class-divider">
            <q-item-section avatar>
              <q-icon name="build" />
            </q-item-section>
            <q-item-section>
              Beta test
            </q-item-section>
          </q-item>

          <q-item 
            clickable v-ripple
            @click="this.$router.push('/admin/inquery')" >
            <q-item-section>
              고객문의
            </q-item-section>
          </q-item>

          <q-item 
            v-ripple
            clickable
            @click="this.$router.push('/admin/dashboard')" >

            <q-item-section avatar>
              <q-icon name="dashboard" />
            </q-item-section>

            <q-item-section>
              대쉬보드
            </q-item-section>
          </q-item>
  
          <q-item 
            clickable v-ripple
            @click="this.$router.push('/admin/lab')" >
            <q-item-section>
              임시 기능 테스트
            </q-item-section>
          </q-item>
        </div>



        <q-separator />

        <q-item 
          clickable v-ripple
          @click="signOut" 
          >
          <q-item-section>
            Sign out
          </q-item-section>
        </q-item>

      </q-list>
    </q-scroll-area>

    
    <q-img class="absolute-top" src="https://cdn.quasar.dev/img/material.png" style="height: 150px">
      <div class="absolute-bottom bg-transparent">
        <!--
        <q-avatar size="56px" class="q-mb-sm">
          <img src="https://cdn.quasar.dev/img/boy-avatar.png">
        </q-avatar>
        -->
        <div class="text-weight-bold">
          {{session.lName + session.fName}} 님<br />
          안녕하세요.
        </div>
        <div>{{ session.email }}</div>
      </div>
    </q-img>
  
  </q-drawer>

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.class-divider {
  background-color: $blue-grey-3;
  color: #000;
}
</style>
