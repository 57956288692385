<script>
import Title from "@/components/admin/common/TitleArea.vue"
import ParcelLocationList from "@/components/admin/ParcelLocationList.vue"
import ParcelLocationRegiFormUnit from "@/components/admin/modal/ParcelLocationRegiFormUnit.vue"

export default {
  name: "Admin-parcel-location-management",
  meta: {
    title: "어드민-배송상품위치관리"
  },
  components: {
    Title,
    ParcelLocationList,
    ParcelLocationRegiFormUnit,
  },
  data() {
    return {
      description   : {
        title : '배송상품 위치 관리'
      },
      showModalNow : false,
      mode : 0,
    }
  },
  methods:{
    // Modal show for more parcel
    addParcelLocationForm(){
      this.$log.debug("픽업지 추가 클릭")
      this.showModalNow = !this.showModalNow
      // Modal mode for add parcel
      this.mode = 0
    },
    // 모달 닫기
    closeModal(){
      this.showModalNow = false
    },
    async regiPickup(payload){
      let config = {
        method : "post",
        url : "/api/parcelLocation/addParcelLocation",
        data : payload,
        name : "[ Axios / ParcelLocationList / 상품위치정보 추가 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug("/parcelLocation/addParcelLocation 실행됨", res)
          this.$refs.parcelLocationList.loadParcelLocationList()
        }).catch(res => {
          this.$log.debug("/parcelLocation/addParcelLocation 실패", res)
        })
    },
  }
}
</script>

<template>

  <q-page class="q-pa-lg">
    <div class="row">
      <div class="col">

        <Title
          :desc="description">

          <q-btn
            color="primary"
            size="md"
            label="배송상품위치 추가"
            @click="addParcelLocationForm"/>
        </Title>  

      </div>
    </div>

    <div class="row q-mt-lg">
      <div class="col">
        <div class="comp-box">
          <ParcelLocationList 
            ref="parcelLocationList"/>
        </div>
      </div>
    </div>
  </q-page>

  <ParcelLocationRegiFormUnit
    :showModal="showModalNow"
    v-bind:mode="mode"
    @close-modal="closeModal"
    @add-item="regiPickup"
  />

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
