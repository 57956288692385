<script>

export default {
  name: "freight-list",
  components:{
  },
  props:{
    freightList: Array,
  },
  emits:[
    "load-list",
  ],
  data() {
    return {
      // codeList: [],
      showModalNow : false,
      mode : 0,
      initialPagination : {
        rowsPerPage: 30
        // rowsNumber: xx if getting data from a server
      },
      columns : [
        { name: 'method', label: '운송수단', align: 'center', field: 'method' },
        { name: 'freightNo', label: '운송편번호', align: 'center', field: 'freightNo' },
        { name: 'nation', label: '국가', align: 'center', field: 'nation' },
        { name: 'note', label: '설명', align: 'center', field: 'note' },
        { name: 'departure', label: '출발지', align: 'center', field: 'departure' },
        { name: 'arrival', label: '도착지', align: 'center', field: 'arrival' },
        { name: 'status', label: '사용여부', align: 'center', field: 'status' },
      ],
    }
  },
  methods:{
    singleDelete (codeId) {
      this.$log.debug("개별삭제 실행")
      this.$log.debug("선택된 택배 Id", codeId)

      let payload = {
        "id" : codeId
      }

      this.$log.debug("payload = ", payload)

      this.delete(payload)
    },

    async delete (payload) {

      let config = {
        method  : "post",
        url     : "/api/code/delCode",
        data    : payload,
        name    : "코드 삭제"
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("삭제했습니다.")

          this.$emit("load-list")

          alert("삭제했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('삭제에 실패했습니다')
      })
    },


    /**
     * Title        : 운송편 상태 변경
     * Status       : Actived
     * Description  : 운송편 상태를 변경한다.
     */
    async updateStatus (id, status) {

      let payload = {
        id      : id,
        status  : status
      }

      let config = {
        method  : "post",
        url     : "/api/freight/updateFreightStatus",
        data    : payload,
        name    : "운송편 사용여부 변경"
      }

      await this.$http(config)
        .then(() => {
          this.$emit("load-list")

          alert("상태를 변경했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('상태변경에 실패했습니다')
      })
    },
  },
  mounted() {
    this.$log.debug("Code list component mounted")
  },
}
</script>

<template>

  <q-table
    :rows="freightList"
    :columns="columns"
    :rows-per-page-options="[0]"
    :virtual-scroll-sticky-size-start="48"
    :pagination="initialPagination"
    row-key="name">

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="method" :props="props">
          {{ props.row.method }}
        </q-td>
        <q-td key="freightNo" :props="props">
          {{ props.row.freightNo }}
        </q-td>
        <q-td key="nation" :props="props">
          {{ props.row.nation }}
        </q-td>
        <q-td key="departure" :props="props">
          {{ props.row.departure }}
        </q-td>
        <q-td key="arrival" :props="props">
          {{ props.row.arrival }}
        </q-td>
        <q-td key="note" :props="props" class="text-left">
          {{ props.row.note }}
        </q-td>
        <q-td key="status" :props="props">
          <q-toggle
            v-model="props.row.status"
            :true-value="'0'"
            :false-value="'1'"
            @update:model-value="updateStatus(props.row.id, props.row.status)"
            checked-icon="check"
            color="green"
            unchecked-icon="clear"
          />
        </q-td>
        <q-td key="detail" :props="props">
          <q-btn-dropdown 
            color="primary" 
            label="기능선택"
            size="sm">
            <q-list>
              <q-item
                clickable v-close-popup
                @click="singleDelete(props.row.id)">
                <q-item-section>
                  <q-item-label>삭제</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
