<template>
  <router-view />

  <UtilSpinner
    v-if="spinnerToggle"/>
</template>

<script>

import UtilSpinner from '@/components/common/Spinner.vue'
import { useQuasar } from 'quasar'

export default {
  name: 'App',
  components: {
    UtilSpinner,
  },
  computed: {
    isSpinnerToggle : function (){
      return this.$store.getters.spinnerState
    },
  },
  setup () {

    // TODO : Dark mode 개발준비 및 적용
    const $q = useQuasar()

    // get status
    // console.log($q.dark.isActive) // true, false
    
    // get configured status
    // console.log($q.dark.mode) // "auto", true, false

    // set status
    // $q.dark.set(true) // or false or "auto"

    // toggle
    // $q.dark.toggle()
  },
  watch:{
    "$store.getters.spinnerState": function(val){
      // this.$log.debug("동작지점체크 2")

      const body = document.querySelector("body")
      val ? body.classList.add("modal-open") : body.classList.remove("modal-open")
      
      this.spinnerToggle = val
    }
  },
  data(){
    return {
      spinnerToggle : false,
    }
  },
  mounted(){
    this.$log.info("App version is " + `${process.env.VUE_APP_VERSION}`)

    // this.$log.apply({ level : 'debug' })
    // this.$log.debug('log level is debug')
    // this.$log.info('log level is info')
    // this.$log.warn('log level is warn')
    // this.$log.error('log level is error')
    // this.$log.log('log level is log')
  }
}
</script>

<style lang="scss">
@import '@/assets/style/common.scss';
</style>
