<script>
import ParcelLocationRegiFormUnit from "@/components/admin/modal/ParcelLocationRegiFormUnit.vue"

export default {
  name: "Pickup-list",
  components:{
    ParcelLocationRegiFormUnit,
  },
  data() {
    return {
      pickupList: [],
      showModalNow : false,
      mode : 0,
      initialPagination : {
        rowsPerPage: 10
        // rowsNumber: xx if getting data from a server
      },
      columns : [
        { name: 'class', label: '분류코드', align: 'center', field: 'class' },
        { name: 'code', label: '위치코드', align: 'center', field: 'code' },
        { name: 'name', label: '위치명', align: 'center', field: 'name' },
        { name: 'country', label: '국가코드', align: 'center', field: 'country' },
        { name: 'regDate', label: '등록일', align: 'center', field: 'regDate' },
        { name: 'detail', label: '기능선택', align: 'center', field: 'detail' },
      ],
    }
  },
  methods:{
    // Modal show for more parcel
    addParcelLocationForm(){
      this.$log.debug("픽업지 추가 클릭")
      this.showModalNow = !this.showModalNow
      // Modal mode for add parcel
      this.mode = 0
    },
    // 모달 닫기
    closeModal(){
      this.showModalNow = false
    },
    async regiPickup(payload){
      let config = {
        method : "post",
        url : "/api/parcelLocation/addParcelLocation",
        data : payload,
        name : "[ Axios / ParcelLocationList / 상품위치정보 추가 ]"
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug("/parcelLocation/addParcelLocation 실행됨", res)
          this.loadParcelLocationList()
        }).catch(res => {
          this.$log.debug("/parcelLocation/addParcelLocation 실패", res)
        })
    },
    singleDelete (pickupId) {
      this.$log.debug("개별삭제 실행")
      this.$log.debug("선택된 택배 Id", pickupId)

      let payload = {
        "code" : pickupId
      }

      this.$log.debug("payload = ", payload)

      this.delete(payload)
    },
    async delete (payload) {

      let config = {
        method : "post",
        url : "/api/admin/delParcelLocation",
        data : payload,
        name : "[ Axios / ParcelLocationList / 상품위치정보삭제 ]"
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("삭제했습니다.")

          this.loadParcelLocationList()

          alert("삭제했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })
    },
    async loadParcelLocationList() {

      let result = []

      let config = {
        method : "get",
        url : "/api/parcelLocation/getParcelLocationList",
        name : "[ Axios / ParcelLocationList / 상품위치정보조회 ]"
      }

      result = await this.$http(config)
        .then(res => {
          let tempRtn = res.data
          this.$log.debug("[ Axios / ParcelLocationList / 상품위치정보조회 ] 실행됨", tempRtn)
          return tempRtn
        }).catch(res => {
          this.$log.debug("[ Axios / ParcelLocationList / 상품위치정보조회 ] 실패", res)
        })

      this.pickupList = result
    },
  },
  mounted() {
    this.$log.debug("Parcel Location list mounted")
    this.loadParcelLocationList()
  },
}
</script>

<template>

  <q-table
    :rows="pickupList"
    :columns="columns"
    :rows-per-page-options="[0]"
    :virtual-scroll-sticky-size-start="48"
    :pagination="initialPagination"
    row-key="name">

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="class" :props="props">
          {{ props.row.class }}
        </q-td>
        <q-td key="code" :props="props">
          {{ props.row.code }}
        </q-td>
        <q-td key="name" :props="props">
          {{ props.row.name }}
        </q-td>
        <q-td key="country" :props="props">
          {{ props.row.country }}
        </q-td>
        <q-td key="regDate" :props="props">
          {{ props.row.regDate }}
        </q-td>
        <q-td key="detail" :props="props">
          <q-btn-dropdown 
            color="primary" 
            label="기능선택"
            size="sm">
            <q-list>
              <q-item
                clickable v-close-popup
                @click="singleDelete(props.row.code)">
                <q-item-section>
                  <q-item-label>삭제</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </q-td>
      </q-tr>
    </template>
  </q-table>

  <div class="row q-mt-sm">
    <div class="col">
      <q-btn
        color="primary"
        size="md"
        label="배송상품위치 추가"
        @click="addParcelLocationForm"/>
    </div>
  </div>

  <ParcelLocationRegiFormUnit
    :showModal="showModalNow"
    v-bind:mode="mode"
    @close-modal="closeModal"
    @add-item="regiPickup"
  />

</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
