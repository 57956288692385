<script>

export default {
  name: "Modal-code-form",
  emits:[
    "add-item",
  ],
  data() {
    return {
      id      : "",
      code    : "",
      parentCode  : "",
      description : "",
      codeLabel   : "",
      value   : "",
      status  : 0
    }
  },
  methods : {
    addItem(){
      const newData = {
        code        : this.code,
        parentCode  : this.parentCode,
        description : this.description,
        codeLabel   : this.codeLabel,
        value       : this.value,
        status      : this.status,
      }
      this.$emit("add-item", newData)
      this.$emit("close-modal")
    },
  }
}
</script>

<template>

  <q-layout 
    container
    class="bg-white">
    
    <q-header class="bg-primary">
      <q-toolbar>
        <q-toolbar-title>코드 추가</q-toolbar-title>
        <q-btn flat v-close-popup round icon="close" />
      </q-toolbar>
    </q-header>

    <q-footer class="bg-white text-black">
      <q-toolbar>
        <q-btn
          label="추가"
          class="full-width"
          color="primary"
          @click="addItem">
        </q-btn>
      </q-toolbar>
    </q-footer>

    <q-page-container>
      <q-page
        padding>

        <div class="row q-mb-sm q-gutter-sm">
          
          <div class="col">

            <q-input 
              v-model="code"
              :maxlength="6"
              label="코드" />    
          
          </div>

          <div class="col">

            <q-input 
              v-model="parentCode"
              :maxlength="6"
              label="부모코드" />

          </div>
        </div>
        <div class="row q-mb-sm q-gutter-sm">
          <div class="col">
          
            <q-input 
              v-model="codeLabel"
              :maxlength="100"
              label="코드라벨" />

          </div>
          <div class="col">

            <q-input 
              v-model="value"
              :maxlength="11"
              label="키값" />    

          </div>
        </div>
        <div class="row">
          <div class="col">

            <q-input 
              v-model="description"
              type="textarea"
              :maxlength="1000"
              label="설명" />    

          </div>
        </div>

      </q-page>
    </q-page-container>
  </q-layout>
  
</template>