<script>
export default {
  name: "ServiceFlight",
  data() {
    return {
      navCurrent: "service",
    };
  },
  methods: {},
};
</script>

<template>
  <q-page id="site-container">
    <div class="section section-service-flight-01">
      <div class="container doc-page">
        <div class="row items-center">
          <div class="col">
            <h2 class="page-title text-center text-white">FLIGHT SERVICE</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-service-flight-02">
      <div class="container doc-page q-pt-xl q-pb-xl q-mt-xl q-mb-xl">
        <div class="row">
          <div class="col q-mt-md">
            <h3>
              전 세계 어디든지,<br />
              130개국의 파트너쉽 항공화물 네트워크
            </h3>
            <p class="q-mb-xl">
              당사는 글로벌 네트워크와 고급 물류 솔루션을 활용하여 항공 화물
              서비스를 통해 항공 화물 니즈에 맞는 광범위한 솔루션을 제공합니다.
              당사는 물품 목적지가 어디든, 시간이 어떻게 되든 고객이 비즈니스를
              원활하게 수행할 수 있도록 도와드립니다.<br />
              당사의 최신 통합 운송 솔루션은 화물이 전 세계 목적지로 문제없이
              배송되도록 지원하고 불편함이 없도록 최선을 다합니다.<br />
              AONE LOGISTICS의 고객들은 뛰어난 운송 연계 및 지속적인 배송 추적
              시스템을 갖춘 퍼스트클래스 항공사를 선택하는 혜택을 누릴 수
              있습니다. 신속한 배송이 필요하거나 대형 화물을 배송해야 할 경우,
              당사는 일정과 예산에 맞는 항공 화물 상품 서비스를 제공해 드립니다.
              니즈와 요구 사항에 상관없이, 당사는 다양한 서비스를 갖추고
              있습니다.
            </p>

            <h3>최고의 유연성과 효율성, 신속한 서비스</h3>
            <p class="q-mb-xl">
              한가지 솔루션에 절대로 만족하지 마십시오. AONE LOGISTICS를
              이용하시면 모든 항공 화물 니즈에 맞는 최고의 유연성과 효율성을
              누릴 수 있는 운송 상품을 선택할 수 있습니다.<br />
              호주 최고의 항공 화물 운송 업체 중 하나인 AONE LOGISTICS를
              이용하시면 안심할 수 있습니다. 퍼스트클래스 항공사의 광범위한
              서비스, 신속한 배송 그리고 저렴한 항공 비용 선택은 고객님의 화물
              운송을 보다 손쉽게 진행 해 드립니다.
            </p>
          </div>
        </div>
        <div class="column q-mt-xl items-center">
          <!--
					<div class="col">
							<button class="btn btn-box">
									<strong>SYD → KOR</strong>
									수출 항공 신청하기
							</button>
					</div>
						-->
          <div class="col-xs-12 col-sm-6">
            <button
              @click="this.$router.push('parcel-registration')"
              class="btn btn-box">
              <!--brighter-->
              <strong>KOR → SYD</strong>
              수입 항공 신청하기
            </button>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
