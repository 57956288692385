<script>
import html2pdf from 'html2pdf.js';
import BarcodeGenerator from '@/components/admin/BarcodeGenerator.vue';

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 1;
const date = today.getDate();
const time = today.getTime();

export default {
	name: "Pdf-preview",
	props: {
		showModal   : Boolean,
		parcelList  : Array,
	},
	emits: [
		"close-modal"
	],
  components: {
    BarcodeGenerator,
  },
	data() {
		return {
      pdfKey: 0,
      barcodeDate : `${year}${month >= 10 ? month : '0' + month}${date >= 10 ? date : '0' + date}`
		}
	},
	methods: {
		// 모달닫기
		closeModal() {
			this.$emit('close-modal')
		},
    /** 파일명 생성 */
    getFileName() {
      const fileName = `${year}${month >= 10 ? month : '0' + month}${date >= 10 ? date : '0' + date}${time}`
      return navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1 ? `${fileName}.pdf` : fileName
    },
    htmlToPdf: (location, fileName) => {
      html2pdf()
        .set({
          margin: [0, 0, 0, 0],
          // filename에서 IE11은 .pdf를 자동으로 넣어주지 않아 .pdf를 파일 명에 넣어줘야 한다.
          filename: fileName,
          pagebreak: { 
            mode: 'css' 
          },
          image: { 
            type: 'jpeg', 
            quality: 1
          },
          html2canvas: {
            useCORS: true,
            width: 384,   // 4inches at 96dpi
            x:0,
            scrollY: 0,
            scale: 2,               // 선명도에 영향을 미친다
            dpi: 192,
            letterRendering: true,
            allowTaint: false,      //useCORS를 true로 설정 시 반드시 allowTaint를 false처리 해주어야함
          },
          jsPDF: { // 4 x 6   inches
            orientation: 'portrait',
            unit: 'in',
            format: [4, 6] 
          },
        })
        .from(location)
        .save();
    },
	}
}
</script>
<template>
	<teleport to="body">
		<q-dialog 
      v-model="showModal"
      persistent>

      <q-layout 
        container
        class="bg-white"
        view="lHh lpr lFf">
        <q-header 
          class="bg-primary"
          elevated>
          <q-toolbar>
            <q-toolbar-title>운송장 출력 미리보기</q-toolbar-title>
            <q-btn flat v-close-popup round dense icon="close" @click="closeModal" />
          </q-toolbar>
        </q-header>

        <q-footer 
          class="bg-white text-black"
          elevated>
          <q-toolbar>
            <q-btn
              label="출력하기"
              class="full-width"
              color="primary"
              @click="htmlToPdf($refs.pdfArea, this.getFileName());">
            </q-btn>
          </q-toolbar>
        </q-footer>

        <q-page-container>
          <q-page
            padding>
            <div class="row">
              <div class="col">
                <div 
                  class="pdfArea" 
                  ref="pdfArea" 
                  :key="pdfKey">
                  <q-card
                    v-for="(item, index) in parcelList"
                    class="waybill"
                    cellpadding="0"
                    cellmargin="0"
                    :key="index">
                    <q-card-section>
                      <h5 class="q-mt-md q-mb-md">
                        AONE LOGISTICS
                      </h5>
                    </q-card-section>
                    <q-separator />
                    <q-card-section>
                      <ul class="waybill-list">
                        <li>
                          받는 사람 : {{ item.receiver }}
                        </li>
                        <li>
                          고객번호 : {{ item.customerNum }}
                        </li>
                        <li>
                          연락처 : {{ item.mobileCountry + item.mobileNum }}
                        </li>
                        <li>
                          주소 : {{ item.deliveryAddress }}
                        </li>
                      </ul>
                    </q-card-section>
                    <q-separator />
                    <q-card-section>
                      <ul class="waybill-list">
                        <li>
                          무게 : {{ item.weight }}
                        </li>
                        <li>
                          박스수량 : {{ item.customerNum }}
                        </li>
                        <li>
                          배송메모 : {{ item.memo }}
                        </li>
                      </ul>
                    </q-card-section>
                    <q-separator />
                    <q-card-section
                      style="display:flex; justify-content: center; align-content: flex-end;">
                      <BarcodeGenerator
                        :value="'AONE' + this.barcodeDate + item.id"
                        :format="auto"
                        :lineColor="'#000'"
                        :width="1.6"
                        :height="60"
                        :elementTag="'img'"/>
                    </q-card-section>
                  </q-card>
                </div>
              </div>
            </div>
					</q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>

	</teleport>
</template>
<style scoped lang="scss">
.waybill{
  width : 4in;
  height: 5.99in;
  border: 3px inset rgb(50,50,50);
  box-shadow: none;
  border-radius: 0;
  background:white;
  margin: 0;
  padding:0;
  page-break-after:always;
}

.waybill-list {
  margin:0;
  padding:0;
  
  li {
    list-style:none;
  }
}

.pdfArea {
  width: 4in;
  margin:0 auto;
  padding: 0;
  background:white;
}  

@media print {
  .waybill{
    page-break-after:always;
    margin-bottom: 0;
  }

  .pdfArea {
    margin:0 auto;
    padding: 0;
  }  
}

@media screen {
  .waybill{
    margin-bottom: 0;
    page-break-after:always;

    &:last-child{
      margin-bottom: 0;
    }
  }
  
  .pdfArea {
    margin:0 auto;
  }
}
</style>