<template>
  <q-page>
    <div 
      class="container doc-page"
      id="login-page">
  
      <div 
        class="row"
        style="height:100%;">
        <div class="col self-center flex-wrap">
          <form class="form-signin-wrapper">
            <div class="form-signin">
              <h5 class="q-mb-lg">관리자 로그인</h5>

              <q-input 
                v-model="email" 
                label="Email"
                placeholder="example@company.com"/>
              
              <q-input
                v-model="password"
                type="password"
                class="q-mb-lg"
                @keyup.enter="signIn"
                label="Password"/>

              <q-btn
                color="secondary"
                @click="signIn">
                로그인
              </q-btn>
    
            </div>
          </form>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
// import axios from 'axios'
// import qs from 'qs'

export default {
  name: "AdminLoginBox",
  data (){
    return {
      email:"",
      password:""
    }
  },
  components: {

  },
  methods: {
    async signIn (){

      let payload = {
        "email" : this.email,      // 고객번호
        "pwd" : this.password,     // 비밀번호
      }

      let config = {
        method : "post",
        url : "/api/admin/signIn",
        data : payload
      }

      await this.$http(config)
        .then(res => {
          this.$log.debug("/admin/signIn 실행됨", res)

          if(res.status === 200){

            let userData = res.data
            this.$log.debug("/cust/signIn 성공", res)
            this.$store.dispatch('setCustomerInfo', userData)
            this.$router.push('/admin/parcel-info')
            
          } else if (res.response.status == 401){
            this.$log.debug("[ Admin / LoginBox / signIn ] 로그인 실패", res)
            // alert(res.response.status)
          }
          
        }).catch(res => {
          this.$log.debug("/admin/signIn 실패함 catch", res)
          alert("로그인 중 오류가 발생했습니다.")
        })
        
      }
  },
  mounted(){
    if(this.$store.getters.getSessionStatus) this.$router.push('/admin/parcel-info')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
