<script>
import Title from "@/components/admin/common/TitleArea.vue"
import ExportPickUpList from "@/components/admin/ExportPickUpList.vue"


export default {
  name: "Export-Pickup-Request-List",
  meta: {
    title: "어드민-Export 픽업요청목록"
  },
  components: {
    Title,
    ExportPickUpList,
  },
  data() {
    return {
      exportPickUpList : [],
      description   : {
        title : 'Export 픽업요청목록'
      },
      lookupCondition : {
        reqId         : '',
        receiver      : '',
      }
    }
  },
  methods: {
    async loadParcelList() {
      
      let payload = {
        "paging"      : 0
      }
      
      let config = {
        method  : "post",
        url     : "/api/parcel/reqPickUpInq",
        data    : payload,
        name    : "[ Axios / Export 픽업요청목록 조회 ]"
      }

      await this.$http(config)
        .then(res => {
          // this.$log.debug("불러오기 성공")
          this.$log.debug(res.data)
          this.exportPickUpList = res.data
          if(res.data.length === 0) alert("조회된 상품이 없습니다.")
        })
        .catch((err) => {
          this.$log.debug("/api/parcel/reqPickUpInq", err)
      })
    },

    async lookupList() {
      
      let payload = {
        id        : this.lookupCondition.reqId,
        receiver  : this.lookupCondition.receiver,
        paging    : 0
      }
      
      let config = {
        method  : "post",
        url     : "/api/parcel/reqPickUpInq",
        data    : payload,
        name    : "[ Axios / Export 픽업요청검색 ]"
      }

      await this.$http(config)
        .then(res => {
          // this.$log.debug("불러오기 성공")
          this.$log.debug(res.data)
          this.exportPickUpList = res.data
          if(res.data.length === 0) alert("조회된 상품이 없습니다.")
        })
        .catch((err) => {
          this.$log.debug("/api/parcel/reqPickUpInq", err)
      })
    },
  },
  mounted() {
    this.$log.debug("reqPickUpList mounted")
    this.loadParcelList()
    this.$getCourierList()
    this.$getParcelLocationList()
  },
}
</script>


<template>
  <q-page class="q-pa-lg">
    <div class="row q-mb-lg">
      <div class="col">
        <Title
          :desc="description">

        </Title>  
      </div>
    </div>

    <div class="row q-mb-lg">
      <div class="col-12 col-md-12 col-lg-10 q-mb-md">
        <div class="comp-box">
          <div class="q-gutter-md row items-start">
            <q-input 
              v-model="lookupCondition.reqId"
              label="요청번호">
            </q-input>
            
            <q-input 
              v-model="lookupCondition.receiver"
              label="수취인명">
            </q-input>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-2 q-mb-md">
        <q-btn
          color="primary"
          size="lg"
          label="검색"
          class="full-width"
          style="height:100%"
          @click="lookupList"/>
      </div>
    </div>

    <div class="row">
      <div class="col">
        
        <div class="comp-box">
          <ExportPickUpList
            :list="exportPickUpList"
            @reloadParcelList="loadParcelList"
          />
        </div>

      </div>
    </div>
  </q-page>

</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
