<script>
// import PickupRegForm from "@/components/admin/modal/PickupRegiFormUnit.vue"

export default {
  name: "Pickup-list",
  components:{
    // PickupRegForm,
  },
  data() {
    return {
      pickupList: [],
      // showModalNow : false,
      // mode : 0,
      columns : [
        { name: 'code', label: '픽업지코드', align: 'center', field: 'code' },
        { name: 'name', label: '픽업지명', align: 'center', field: 'name' },
        { name: 'country', label: '국가코드', align: 'center', field: 'country' },
        { name: 'city', label: '도시코드', align: 'center', field: 'city' },
        { name: 'region', label: '지역코드', align: 'center', field: 'region' },
        { name: 'regDate', label: '등록일', align: 'center', field: 'regDate' },
        { name: 'detail', label: '기능선택', align: 'center', field: 'detail' },
        
      ],
      initialPagination : {
        rowsPerPage: 10
        // rowsNumber: xx if getting data from a server
      },
    }
  },
  methods:{
    /**
     * Title        : 개별삭제
     * Status       : Actived
     * Description  : 픽업지 1개씩 삭제한다
     */
    singleDelete (pickupId) {
      this.$log.debug("개별삭제 실행")
      this.$log.debug("선택된 택배 Id", pickupId)

      let payload = {
        "pickupCode" : pickupId
      }

      this.$log.debug("payload = ", payload)

      this.delete(payload)
    },

    /**
     * Title        : 삭제
     * Status       : Actived
     * Description  : 픽업지를 삭제한다
     */
    async delete (payload) {

      let config = {
        method : "post",
        url : "/api/pickup/delpickup",
        data : payload,
        name : "[ Axios / 픽업지 삭제 ]"
      }

      await this.$http(config)
        .then(() => {
          this.$log.debug("삭제했습니다.")

          this.loadPickupList()

          alert("삭제했습니다.")
        })
        .catch((err) => {
          this.$log.debug(err)
          alert('불러오기 실패!')
      })
    },

    /**
     * Title        : 픽업지 조회
     * Status       : Actived
     * Description  : 픽업지 목록을 불러온다
     */
    async loadPickupList() {

      let result = []

      let config = {
        method : "get",
        url : "/api/pickup/getPickupList",
        name : "[ Axios / 픽업지 조회 ]"
      }

      result = await this.$http(config)
        .then(res => {
          let tempRtn = res.data
          this.$log.debug("[ Axios / 픽업지 조회 ] 성공 => ", tempRtn)
          return tempRtn
        }).catch(res => {
          this.$log.debug("[ Axios / 픽업지 조회 ] 실패 =>", res)
        })

      this.pickupList = result
    },
  },
  mounted() {
    this.$log.debug("Pickup list mounted")
    this.loadPickupList()
  },
}
</script>

<template>
  <q-table
    :rows="pickupList"
    :columns="columns"
    :rows-per-page-options="[0]"
    :virtual-scroll-sticky-size-start="48"
    :pagination="initialPagination"
    row-key="name">

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="code" :props="props">
          {{ props.row.code }}
        </q-td>
        <q-td key="name" :props="props">
          {{ props.row.name }}
        </q-td>
        <q-td key="country" :props="props">
          {{ props.row.country }}
        </q-td>
        <q-td key="city" :props="props">
          {{ props.row.city }}
        </q-td>
        <q-td key="region" :props="props">
          {{ props.row.region }}
        </q-td>
        <q-td key="regDate" :props="props">
          {{ props.row.regDate }}
        </q-td>
        <q-td key="detail" :props="props">
          <q-btn-dropdown 
            color="primary" 
            label="기능선택"
            size="sm">
            <q-list>
              <q-item
                clickable v-close-popup
                @click="singleDelete(props.row.code)">
                <q-item-section>
                  <q-item-label>삭제</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </q-td>
      </q-tr>
    </template>
  </q-table>

  <!--
  <div class="row q-mt-sm">
    <div class="col">
      <q-btn
        color="primary"
        size="md"
        label="픽업지 추가"
        @click="addPickupForm"/>
    </div>
  </div>
  

  <PickupRegForm
    :showModal="showModalNow"
    v-bind:mode="mode"
    @close-modal="closeModal"
    @add-item="regiPickup"
  />
  -->
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
