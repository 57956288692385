<template>
  <div class="section section-05">
    <div class="container doc-page pt-5 pb-5">
      <div class="column items-center">
        <div class="col-12 d-flex justify-content-center mt-3">
          <h4 class="section-title text-center">calculator</h4>
        </div>

        <div class="col-12 text-center mt-3 mb-5">
          <div class="display-text-1">항공&해상 부피 무게를 측정해보세요.</div>
        </div>
      </div>

      <!-- Calculator -->
      <div class="column items-center">
        <div class="col col-12 col-md-10 col-lg-8">
          <Calculator />
        </div>
      </div>
    </div>
  </div>
  <!--
  <div class="section section-06">
    <div class="container pt-5 pb-5">
      <div class="row">

        <div class="col-12 d-flex justify-content-center mt-3">
          <h4 class="section-title text-center">공지사항 & 배대지</h4>
        </div>
      </div>

      공지사항 & 배대지 -->
      <!-- // TODO : 배대지 추가 필요 --> 
      <!--
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 mt-5 mb-5">
          <NoticeList
            :notice-list="noticeList"
            :auth-level="authLevel"
            :is-home="isHome"
          />
        </div>
        
        <div class="col-12 col-sm-12 col-md-6 mt-5 mb-5">
          NSW
          K10 / 161 ARTHUR ST HOMEBUSH WEST NSW 2140

          KOREA
          서울시 강서구 양천로 400-12 더리브골드타워 1002호
        </div>
        
      </div>
      
    </div>
  </div>
  -->
</template>
<script>
import Calculator from "@/components/customer/Calculator.vue"

export default ({
  components: {
    Calculator,
  },
  data(){
    return {}
  },
  methods:{
    moveTo(destination){
      this.$router.push(destination)
    },
  }
})
</script>

<style lang="scss" scoped>
@import "@/assets/style/variations.scss";
.section {
  &.section-05 {
    background: $grey-background;

    .display-text-1 {
      font-family: 'IBMPlexSansKR-SemiBold';
      font-size: $display-text-1-size;
      color: $primary-color;
    }
  }
}
</style>
