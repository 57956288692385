<script>
/**
 * Type         : Biz component
 * Title        : 화물의 목적지 정보 입력 컴포넌트
 * Description  : 화물의 목적지 정보를 입력을 받는 컴포넌트
 * 
 */

export default {
  name: "Destination-registration-form",
  components: {},
  emits:[ "destination-info" ],
  data() {
    return {
      destiInfo : {
        city            : "",
        freightMethod   : "",
        receiver        : "",
        mobileCountry   : "",
        mobileNum       : "",
        email           : "",
        pickupLocation  : "",
        deliveryAddress : "none",
      }
    }
  },
  methods:{
    /**
     * Title        : 목적지 정보 Emit to parent
     * Status       : Actived
     * Description  : 
     */
    sendDestiInfo(){
      if(this.destiInfo.deliveryAddress === ''){
        this.destiInfo.deliveryAddress = 'none'
      }

      this.$log.debug("[ DestinationRegiForm / sendDestiInfo ] 목적지 정보를 부모 컴포넌트로 전송 ", this.destiInfo )
      this.$emit('destination-info', this.destiInfo)
    },
  },
  mounted() {
    this.$log.debug("픽업장소, 목적지(도시) list mounted")
    // 픽업장소 조회
    this.$getPickupList()
    // 목적지(도시) 조회
    this.$getCityList()
    // 운송수단 조회
    this.$getFreightList()
  },
}
</script>

<template>

  <section>

    <h4 class="q-my-lg">도착지정보</h4>

    <q-separator></q-separator>

    <div class="q-mt-md q-mb-xl">
  
      <q-select 
        v-model="destiInfo.freightMethod" 
        class="form-size-sm"
        :options="this.$store.getters.getFreightMethodList"
        @change="sendDestiInfo"
        label="운송수단" 
        emit-value
        map-options
        behavior="menu"/>

    </div>

    <h6 class="q-mt-xl">받는 사람 정보</h6>

    <div class="row q-gutter-md q-mb-xl">
  
      <q-input 
        v-model="destiInfo.receiver"
        class="receiver form-size-sm"
        @change="sendDestiInfo"
        label="받는 사람" /> 

      <q-input 
        v-model="destiInfo.email"
        class="email form-size-sm"
        @change="sendDestiInfo"
        @blur="this.$validEmail(destiInfo.email)"
        label="이메일" 
        placeholder="example@company.com"/>  
      
  
    </div>

    <div class="row q-gutter-md">
      <label for="mobile" class="form-label">전화번호</label>
    </div>

    <div class="row q-gutter-md q-mb-xl">
      <q-select
          v-model="destiInfo.mobileCountry"
          class="mobile-country-select"
          @change="sendDestiInfo"
          label="국가번호"
          :options="['+61', '+82']"
          behavior="menu"
          emit-value
          map-options/>

        <q-input 
          v-model="destiInfo.mobileNum" 
          class="mobile-number form-size-md"
          @change="sendDestiInfo"
          @blur="this.$validMobile(destiInfo.mobileNum)"
          label="전화번호"
          placeholder="'-' 없이 적어주세요." />
        
    </div>


    <h6 class="q-mt-xl">배송지정보</h6>
  
    <div class="row q-gutter-md q-mb-xl">  
      <q-select
        class="city-select"
        v-model="destiInfo.city"
        @change="sendDestiInfo"
        label="도시"
        :options="this.$store.getters.getCityListNew"
        behavior="menu"
        emit-value
        map-options />

      <q-select
        class="pickup-location-select"
        v-model="destiInfo.pickupLocation"
        @change="sendDestiInfo"
        label="픽업장소"
        :options="this.$store.getters.getPickupLocationListNew"
        behavior="menu"
        emit-value
        map-options />
    </div>
  
    <div class="row" v-if="destiInfo.pickupLocation === 'AONEPI03'">
      <div class="col-12">
        <q-input
          label="배송요청주소"
          v-model="destiInfo.deliveryAddress"
          required
          type="textarea" />
      </div>
    </div>

  </section>

  



  
</template>

<style scoped lang="scss">

h6 {
  margin-top: 30px;
  margin-bottom: 22px;
}

.receiver {
  max-width:160px;
  width: 50%;
  min-width:120px;
}

.email {
  max-width: 350px;
  width: 50%;
  min-width: 250px;
} 

.mobile-country-select {
  max-width:160px;
  width: 50%;
  min-width:120px;
}

.mobile-number {
  max-width: 350px;
  width: 50%;
  min-width: 250px;
}


.city-select {
  max-width:200px;
  width: 50%;
  min-width:160px;
}

.pickup-location-select {
  max-width:600px;
  width: 50%;
  min-width:240px;
}

.form-size-xs {
  max-width:100px;
  min-width:80px;
}

.form-size-sm {
  max-width:200px;
  min-width:100px;
}

.form-size-md {
  max-width:350px;
  min-width:250px;
}
</style>