import log from '@/plugin/logger.ts'

const state = {  
  isBulk: false,                    // 벌크구성
  parcelList: [],                   // 조회된 배송상품목록
  newParcelList: [],                // 새로 등록할 배송상품목록
  
  
  bulkParcelList: [],               // 조회된 배송상품목록
  tempBulkList: [],                 // 묶음배송으로 만들 배송상품 임시목록
  snapShotBulkList: [],             // 묶음배송목록 오리지널 목록
}

const getters = {
  /** 
   * 배송상품목록 가져오기
   * 
   */

  getParcelList(state) {
    // log.debug("[ Store / getParcelList ]  실행됨")
    let pl = state.parcelList
    /** 비회원 표시처리 */
    pl.map((u,i) => {
      u.customerName = (u.customerName == null ? '비회원' : u.customerName)
    })

    return pl
  },

  getNewList(state) {
    return state.newParcelList
  },

  getNewListCnt(state) {
    return state.newParcelList.length
  },

  /** 
   * 
   * 묶음배송
   * 
   */
  getBulkParcelList(state){
    log.debug("[ Store / getBulkParcelList ] 실행됨")
    let pl = state.bulkParcelList
    return pl 
  },

  getTempBulkList(state){
    log.debug("[ Store / getTempBulkList ] 실행됨")
    let pl = state.tempBulkList
    return pl 
  },

  getSnapShot(state){
    log.debug("[ Store / getSnapShot ] 실행됨")
    let pl = state.snapShotBulkList
    return pl 
  }
}





/**
 * 
 * 변형 - mutation
 * 
 */
const mutations = {

  /**
   * 일반 배송상품 목록
   * 
   * @param {*} state 
   * @param {*} value 
   */
  loadParcelList(state, value){
    // log.debug("loadParcelList excuted = > ", value)
    state.parcelList = value
  },
  // load more parcel list
  loadMoreParcelList(state, value){
    // log.debug("loadMoreParcelList excuted parcelList = > ", state.parcelList)
    // log.debug("loadMoreParcelList excuted value = > ", value )

    const oldArr = state.parcelList
    const moreArr = value

    oldArr.push( ...moreArr )

    state.parcelList = oldArr
  },

  /**
   * 배송상품등록
   * @param {*} state 
   * @param {*} value 
   */
  setNewParcelList(state, value){
    log.debug("setNewParcelList excuted")
    state.newParcelList.push(value)
  },

  clearNewParcelList(state){
    log.debug("clearNewParcelList")
    state.newParcelList = []
  },

  

  removeParcelFromList(state, value){
    log.debug("removeParcelFromList excuted = >" + value)
    state.newParcelList.splice(value, 1)
  },

  initNewParcelList(state, value){
    log.debug("initNewParcelList excuted = >" + value)
    state.newParcelList = []
  },

  modifyParcelInfoFromList(state, value){

    log.debug("modifyParcelInfoFromList excuted = >", value)

    let tempArr = state.newParcelList
    let idx = value.idx
    let modifiedData = value.data

    const newArr = tempArr.map((item, i) => {
      // log.debug("item = ", item)
      // log.debug("modifiedData = ", modifiedData)
      // log.debug("return = ", { ...item, ...modifiedData })
      if(idx === i){
        log.debug("found array")
        return { ...item, ...modifiedData }
      }
      return item
    })

    state.newParcelList = newArr
  },

  bulkToggle(state, value){
    // log.debug("is bulk ? = > " + value)
    state.isBulk = value
  },


  /** 묶음배송 ******************************************************/

  /**
   * 묶음 배송상품 목록
   * @param {} state 
   * @param {*} value 
   */

  /** 묶음배송목록 불러오기 */
  patchLoadBulkParcel(state, value){
    log.debug("[ Store / parcel / mutation ] patchLoadParcel excuted = > ", value)
    state.bulkParcelList = value
  },

  /** 묶음배송목록 더 불러오기 */
  patchMoreBulkParcel(state, value){
    log.debug("[ Store / parcel / mutation ] patchMoreBulkParcel excuted = > ", value)
    const oldArr = state.bulkParcelList
    const moreArr = value

    oldArr.push( ...moreArr )

    state.bulkParcelList = oldArr
  },

  /** 임시묶음배송 목록에 추가 */
  patchTempBulkList(state, value){
    
    let oldArr = state.tempBulkList
    log.debug("[ Store / parcel / mutation ] 임시목록에 있는 상품들 = > ", oldArr)
    
    let moreArr = []
    moreArr.push( ...value ) 

    let result = moreArr.filter(x => {

      let temp = oldArr.filter(y => {
        log.debug("[ Store / parcel / mutation ] x 와 y 비교 = > " + x.id + " & " +  y.id)
        if(x.id == y.id){return y}
      })

      if(temp.length == 0) {return x}
    })

    log.debug("[ Store / parcel / mutation ] 신규로 등록할 상품들 = > ", result)

    oldArr.push( ...result )

    state.tempBulkList = oldArr
  },

  /** 묶음배송목록 스냅샷 */
  patchSetSnapShot(state, value){
    log.debug("[ Store / parcel / mutation ] patchSetSnapShot excuted = > ", value)

    state.snapShotBulkList = value
  },

  clearTempBulkList(state){
    state.tempBulkList = []
  },


  /** 임시묶음 배송에서 제외됨 */
  removeFromTempBulkList(state, value){
    log.debug("[ Store / parcel / mutation ] removeFromTempBulkList excuted = > ", value)

    // 임시저장되어있는 묶음배송 대상 목록
    let oldArr = state.tempBulkList


    for(let i = 0; i < oldArr.length; i++){
      let old = oldArr[i]

      if(old.id == value){
        oldArr.splice(i, 1)
        break
      }
    }
  },
}

const actions = {
  /**
   * 일반 배송목록
   * @param {*} param0 
   * @param {*} payload 
   */
  loadParcel: ({ commit }, payload) => {
    // log.debug(state)
    commit('loadParcelList', payload)
  },
  loadMoreParcel: ({ commit }, payload) => {
    // log.debug(state)
    commit('loadMoreParcelList', payload)
  },


  /*****************************************
   * 신규등록 배송상품
   * @param {*} param0 
   * @param {*} payload 
   *****************************************/
  fetchNewParcel: ({ commit }, payload) => {
    // log.debug(state)
    commit('setNewParcelList', payload)
  },

  clearNewParcelList: ({ commit }) => {
    // log.debug(state)
    commit('clearNewParcelList')
  },

  removeNewParcel: ({ commit }, payload) => {
    // log.debug(state)
    commit('removeParcelFromList', payload)
  },
  initNewParcel: ({ commit }, payload) => {
    // log.debug(state)
    commit('initNewParcelList', payload)
  },
  modifyParcelInfo: ({ commit }, payload) => {
    // log.debug(state)
    commit('modifyParcelInfoFromList', payload)
  },
  isBulkToggle: ({ commit }, payload) => {
    // log.debug(state)
    commit('bulkToggle', payload)
  },



  /*****************************************
   * 묶음 배송목록
   * @param {*} param0 
   * @param {*} payload 
   *****************************************/
  loadBulkParcel: ({ commit }, payload) => {
    // log.debug("[ Store / parcel / actions ] loadBulkParcel = > ", payload)
    commit('patchLoadBulkParcel', payload)
  },

  loadMoreBulkParcel: ({ commit }, payload) => {
    // log.debug("[ Store / parcel / actions ] loadBulkParcel = > ", payload)
    commit('patchMoreBulkParcel', payload)
  },

  addToBulkList: ({ commit }, payload) => {
    // log.debug("[ Store / parcel / actions ] addToBulkList = > ", payload)
    commit('patchTempBulkList', payload)
  },

  setSnapShot: ({ commit }, payload) => {
    // log.debug("[ Store / parcel / actions ] addToBulkList = > ", payload)
    commit('patchSetSnapShot', payload)
  },

  removeFromBulkList: ({ commit }, payload) => {
    // log.debug("[ Store / parcel / actions ] removeFromBulkList = > ", payload)
    commit('removeFromTempBulkList', payload)
  },
  clearTempBulkList: ({ commit }) => {
    // log.debug("[ Store / parcel / actions ] clearTempBulkList = > ", payload)
    commit('clearTempBulkList')
  },
  
}


// state, getters, mutations, actions, modules
export default {

  state: {
    ...state
  },
  getters,
  mutations,
  actions

}
