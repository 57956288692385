<template>
	<div class="section section-01">
		<div class="container doc-page">
			<div class="row">
				<div class="col col-sm-12 col-md-9 col-lg-8 self-center flex-wrap">
					<div class="graphic-text-box">
						<h2 class="main-slogan">Express Trend Leader</h2>
						<h1 class="main-company-name">AONE LOGISTICS</h1>
						<a 
							@click="this.$router.push('parcel-info')" 
							href="#" 
							class="btn btn-in-main q-mr-md">배송조회</a>
						<a 
							@click="this.$router.push('parcel-registration')" 
							href="#" 
							class="btn btn-in-main">배송신청</a>
					</div>

					<!-- // TODO 배너기능을 어드민과 연동하여 추가해야함
						
					<div class="banner-container">
						<div class="banner"
							v-if="!banner1">
							<img :src="require(`@/assets/imgs/main/banner_001.png`)">
							<div class="banner-bottom">
								<q-checkbox v-model="banner1" label="1주일간 보지 않기" />
							</div>
						</div>
						<div class="banner"
							v-if="!banner2">
							<img :src="require(`@/assets/imgs/main/banner_002.png`)">
							<div class="banner-bottom">
								<q-checkbox v-model="banner2" label="1주일간 보지 않기" />
							</div>
						</div>
					</div>
					-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default({
	name: "Main visual area",
	data(){
    return {
      banner1: false,
      banner2: false
    }
  },
  methods:{

	}
})
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.section {
	&.section-01 {
		background-image: url("@/assets/imgs/background/main01.jpg");
		background-size: cover;
		height: 70vw;
		max-height: 800px;
	
		.container {
			height: 100%;
	
			.row {
				height: 100%;
			}
	
			.graphic-text-box {
				font-family: 'Titilliu-Web-bold';
				color: rgb(255, 255, 255);
	
				.main-slogan {
					font-size: 3rem;
					line-height: 3rem;
					margin-block-end: 0.3rem;
					text-shadow: 0 0 5px rgba(0, 0, 0, 02);
				}
	
				.main-company-name {
					font-size: 5rem;
					line-height: 5rem;
					margin-block-start: 0.3rem;
          margin-block-end: 2rem;
					text-shadow: 0 0 5px rgba(0, 0, 0, 02);
				}
			}

			.banner-container {
				position: absolute;
				right: 10%; top: 10%;
				display: block;

				.banner {
					display: inline-block;
					margin-right: 5px;
					width: 300px;

					.banner-bottom {
						width: 100%;
						background: #fff;
						margin-top: -6px;
					}

					img {
						width: 100%;
					}
				}
			}
		}

		a.btn {
			text-decoration: none;
		}
	}
}

@media (max-width: 766.98px) {
	.section {
		&.section-01 {
      height: 60vw;

      .container {
        height: 100%;

        .row {
          height: 100%;

					.banner-container {
						.banner {
							width:200px;
						}
					}
        }

        .graphic-text-box {
          .main-slogan {
            font-size: 2.2rem;
						line-height: 2.2rem;
          }

          .main-company-name {
            font-size: 3.5rem;
						line-height: 3.5rem;
						margin-block-start: 0.25rem;
            margin-block-end: 2rem;
          }
        }
      }
    }
	}
}
@media (max-width: 575.98px) {
	.section {
		&.section-01 {
      height: 40vh;
      background-position: center;

      .container {
				.row {
					.banner-container {
						.banner {
							width:150px;
						}
					}
				}
        .graphic-text-box {
          .main-slogan {
            font-size: 1.6rem;
						line-height: 1.6rem;
          }

          .main-company-name {
            font-size: 2.5rem;
						line-height: 2.5rem;
						margin-block-start: 0.25rem;
            margin-block-end: 2rem;
          }
        }
      }
    }
	}
}
</style>