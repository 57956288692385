import { createWebHistory, createRouter } from 'vue-router'
import log from '@/plugin/logger.ts'

import CustomerLayout from './layouts/Customer.vue'
import AdminLayout from './layouts/Admin.vue'


import HomePage from './pages/Home'
import Login from './pages/Login'
import DashBoard from './pages/admin/DashBoard'


/* Admin page */
import AdminLogin from './pages/admin/Login'

import AdminParcelInfo from './pages/admin/ParcelInfo'
import AdminParcelRegi from './pages/admin/ParcelRegistration'
import AdminParcelBulk from './pages/admin/ParcelBulkManage'
import AdminPickupRequest from './pages/admin/ExportPickupRequest'

import FreightManage from './pages/admin/FreightManage'

import CourierManage from './pages/admin/CourierManage'
import PickupManage from './pages/admin/PickupManage'
import LocationManage from './pages/admin/LocationManage'

import CustomerManage from './pages/admin/CustomerManage'

import NoticeBoardManage from './pages/admin/NoticeBoard'
import CustomerInqueryManage from './pages/admin/CustomerInquery'

import ManageSchedule from './pages/admin/ManageSchedule'
import CodeManagement from './pages/admin/CodeManage'

import UpdateReport from './pages/admin/UpdateReport'

import Lab from './pages/admin/Lab'


/* Customer page */
import AboutUs from './pages/customer/AboutUs'

import Service from './pages/customer/Service'
import ServiceFlight from './pages/customer/ServiceFlight'
import ServiceOcean from './pages/customer/ServiceOcean'

import NoticeBoard from './pages/customer/NoticeBoard'
import ParcelInfoCust from './pages/customer/ParcelInfo'
import RegiParcel from './pages/customer/ParcelRegistration'
import ParcelRegiAusToKor from './pages/customer/ParcelRegiAusToKor'

import ShippingInfo from './pages/customer/ShippingInfo'

import SignUp from './pages/customer/SignUp'

/* Homepage defaults */
// import Error404 from './pages/etc/404'

const customerTitle = "Aone Logistics | "
const adminTitle = "Aone 관리자 | "

// Vue 라우터 인스턴스 생성
const router = new createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "HomePage",
      component: CustomerLayout,
      children: [
        { path: '/',
          name : '홈페이지', 
          component: HomePage,
          meta : {
            title: customerTitle + "홈페이지",
            description: "어디든 달려가는 호주글로벌 로지스틱스"
          },
        },
        { path: '/service',
          name : '서비스 설명',
          component: Service,
          meta : {
            title: customerTitle + "서비스 설명"
          },
        },
        { 
          path: '/service-flight',
          name : '서비스 - 항공',
          component: ServiceFlight,
          meta : {
            title: customerTitle + "서비스 - 항공"
          },
        },
        { 
          path: '/service-ocean',
          name : '서비스 - 해상',
          component: ServiceOcean,
          meta : {
            title: customerTitle + "서비스 - 해상"
          },
        },
        { 
          path: '/notice',
          name : '공지사항',
          component: NoticeBoard,
          meta : {
            title: customerTitle + "공지사항"
          },
        },
        { 
          path: '/parcel-info',
          name : '배송조회',
          component: ParcelInfoCust,
          meta : {
            title: customerTitle + "배송조회"
          },
        },
        { 
          path: '/parcel-registration',
          name : '배송등록 한국 -> 호주',
          component: RegiParcel,
          meta : {
            title: customerTitle + "배송등록 한국 -> 호주"
          },
        },
        { 
          path: '/parcel-reg-aus-to-kor',
          name : '배송등록 호주 -> 한국',
          component: ParcelRegiAusToKor,
          meta : {
            title: customerTitle + "배송등록 호주 -> 한국"
          },
        },
        { 
          path: '/shipping-infomation',
          name : '운송정보',
          component: ShippingInfo,
          meta : {
            title: customerTitle + "운송정보"
          },
        },
        { 
          path: '/signUp',
          name : '회원가입',
          component: SignUp,
          meta : {
            title: customerTitle + "회원가입"
          },
        },
        { 
          path: '/Login',
          name : '로그인',
          component: Login,
          meta : {
            title: customerTitle + "로그인"
          },
        },
        { 
          path: '/aboutUs', // 현재 미사용
          name : 'About us', 
          component: AboutUs,
          meta : {
            title: customerTitle + "About us"
          },
        },
        { 
          path: '/admin',
          name : '관리자 로그인',
          component: AdminLogin,
          meta : {
            title: customerTitle + "관리자 로그인"
          },
        },
      ],
      meta: {
        authRequired : false
      },
    },

    //******** Admin page *******//
    // After
    {
      path: "/admin",
      name: "Admin",
			component: AdminLayout,
      children: [
				{
					path: "/admin/dashboard",
					name: "DashBoard",
					component: DashBoard,
          meta : {
            title: adminTitle + "대쉬보드"
          },
				},
				{
					path: "/admin/parcel-info",
					name: "Admin-parcel-infomation",
					component: AdminParcelInfo,
          meta : {
            title: adminTitle + "배송상품조회"
          },
				},
        {
					path: "/admin/parcel-regi",
					name: "Admin-parcel-registration",
					component: AdminParcelRegi,
          meta : {
            title: adminTitle + "배송상품등록"
          },
				},
        { 
          path: '/admin/freight-manage',
          name : 'Admin-Freight-Management',
          component: FreightManage,
          meta : {
            title: adminTitle + "운송편관리"
          },
        },
				{
					path: "/admin/parcel-bulk",
					name: "Admin-parcel-bulk-manage",
					component: AdminParcelBulk,
          meta : {
            title: adminTitle + "배송상품 엑셀업로드"
          },
				},
        {
					path: "/admin/pickup-request-list",
					name: "Admin-Export-Pickup-Request-List",
					component: AdminPickupRequest,
          meta : {
            title: adminTitle + "픽업요청"
          },
				},
				{ // 택배사 관리
					path: "/admin/courier-manage",
					name: "Admin-courier-manage",
					component: CourierManage,
          meta : {
            title: adminTitle + "택배사관리"
          },
				},
				{ // 픽업지 관리
					path: "/admin/pickup-manage",
					name: "Admin-pickup-manage",
					component: PickupManage,
          meta : {
            title: adminTitle + "픽업지관리"
          },
				},
				{ // 배송위치 관리
					path: "/admin/location-manage",
					name: "Admin-location-manage",
					component: LocationManage,
          meta : {
            title: adminTitle + "배송위치관리"
          },
				},
				{ // 고객관리
					path: "/admin/customer-manage",
					name: "Admin-customer-manage",
					component: CustomerManage,
          meta : {
            title: adminTitle + "고객관리"
          },
				},
				{ // 공지사항 관리
					path: "/admin/notice",
					name: "Admin-notice-manage",
					component: NoticeBoardManage,
          meta : {
            title: adminTitle + "공지사항관리"
          },
				},
        { // 고객문의관리
					path: "/admin/inquery",
					name: "Admin-customer-inquery-manage",
					component: CustomerInqueryManage,
          meta : {
            title: adminTitle + "고객문의관리"
          },
				},
        { // 일정 관리
					path: "/admin/manage-schedule",
					name: "Admin-manage-schedule",
					component: ManageSchedule,
          meta : {
            title: adminTitle + "일정관리"
          },
				},
        { // 코드관리
          path: "/admin/code-management",
          name: "Admin-code-management",
          component: CodeManagement,
          meta : {
            title: adminTitle + "코드관리"
          },
        },
        { // 업데이트 리포트
					path: "/admin/update-report",
					name: "Admin-udpate-report",
					component: UpdateReport,
          meta : {
            title: adminTitle + "업데이트 리포트"
          },
				},
        { // 실험실
					path: "/admin/lab",
					name: "Admin-lab",
          component: Lab,
          meta : {
            title: adminTitle + "실험실"
          },
				},
			],
      meta : {
        authRequired : true,
				authLv : "admin"
      },
    },
  ]
})


// Hook 페이지 이동 전
router.beforeEach( (to, from, next) => {
  log.debug("beforeEach - 1. beforeEach 실행됨")
  // log.debug("beforeEach to = ", to)
  // log.debug("from = ", from)

  // 타이틀 설정
  const title = to.meta.title
  if (title) {
    document.title = title
  }

  // 설명
  const description = to.meta.description
  if (description) {
    document.head.description = description
  }

  // 로그인이 필요한 페이지 인지 판단.
  let auth = to.matched.some(function(routeInfo) {
    return routeInfo.meta.authRequired
  })

  if(auth){
    log.debug("beforeEach - 2.1 로그인 필요 페이지")
    if (checkSession()){
      log.debug("beforeEach - 2.1.1.1 로그인 되어 있음")
      log.debug("beforeEach - routing success : '" + to.path + "'")
      next()
    } else {
      log.debug("beforeEach - 2.1.1.2 로그인 되어 있지 않음")
      alert("로그인이 필요한 페이지 입니다.")
      next('/')
    }
  } else {
    log.debug("beforeEach - 2.2 로그인 미필요 페이지")
    log.debug("beforeEach - routing success : '" + to.path + "'")
    next()
  }
})

// Hook 페이지 이동 후
router.afterEach((to, from) => {
  log.debug("afterEach - 1. afterEach 실행됨")
  window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
})


const checkSession = () => {
  // vuex에서 정보를 가져온다.
  const sess = JSON.parse(sessionStorage.getItem('vuexStore'))
  log.debug("beforeEach - 2.1.1 checkSession 실행됨")
  // log.debug("checkSession sess = ", sess)

  if(sess !== undefined && sess !== null){
    let isSignedIn = sess.user.isSignedIn

    return isSignedIn
  }

  return false
}


export default router
